import React, { Component } from "react";
import {
	StyleSheet,
	Keyboard,
	Text,
	Image,
	TouchableOpacity,
	SafeAreaView,
	ScrollView,
	Button,
	Dimensions,
	Modal,
	Alert,
	LayoutAnimation,
	View,
	TextInput,
	ActivityIndicator,
} from "react-native";

import AppTermsAndConditions from "./AppTermsAndConditions";

import {
	APP_COLOURS,
	screenHeight,
	screenWidth,
	CustomLayoutSpring,
	Avatars,
} from "../../APP_VARS";
import CellNumber from "./CustomerForm/CellNumber";
import POPIAConcent from "./CustomerForm/PopiaConcent";
import EthnicitySelector from "./CustomerForm/EthnicitySelector";
import Gender from "./CustomerForm/Gender";
import KeyboardResponsiveView from "./KeyboardResponsiveView";
import {
	crudCreate,
	crudCreateCustomer,
	crudUpdate,
	crudUpdateCustomer,
} from "../../CRUDHELPER";
import NationalityPicker from "./Nationality";
import EmailInput from "./EmailInput";
import AgeRangePicker from "./AgeRangePicker";
// import CellInput from './CellInput';
import moment from "moment";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default class CustomerProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userid: "NULL",
			inter_id: "",
			customerData: {
				last_name: "",
				first_name: "",
				user_contact: "",
				user_address: "",
				agerange: "",
				retailer: "",
				mall: "",
				hair_colour: "",
				cell: { number: "" },
				pmoc: "",
				email: {
					address: "",
				},
			},

			loading: true,

			emoji: require("./../assets/images/new_interaction.png"),

			hairbackimage: "",

			showTandCs: false,
		};
	}

	componentDidMount() {
		if (this.props.isnewCustomer) {
			this.setState({
				customerData: [],
				loading: false,
			});
		} else {
			if (typeof this.props.customerData !== "undefined") {
				this.setState({
					customerData: this.props.customerData,
					loading: this.props.customerData !== "" ? false : true,
				});
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.customerData !== this.props.customerData) {
			this.setState({
				customerData: this.props.customerData,
				loading: this.props.isnewCustomer
					? false
					: this.props.customerData !== ""
					? false
					: true,
			});
			console.log(this.props.customerData);
		}
	}

	createCustomer = (obj) => {
		this.setState({
			loading: true,
		});

		crudCreateCustomer(obj, (s, response) => {
			this.setState({
				customerData: response,
				loading: false,
			});
			this.props.updateCustomer(response);
		});
	};

	updateCustomerData = (obj) => {
		this.setState({
			customerData: obj,
			loading: false,
		});

		// console.log(obj.id);
		// return;

		obj.lastUpdated = moment().toISOString();

		this.props.updateCustomer(obj);

		crudUpdateCustomer(obj, (s, response) => {
			this.setState({
				customerData: response,
				loading: false,
			});
			// this.props.updateCustomer(response);
		});
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					width: "100%",
					backgroundColor: APP_COLOURS.BG,
				}}>
				{this.state.showTandCs ? (
					this.tandcModal()
				) : (
					<View
						style={{
							flex: 1,
							// backgroundColor: 'rgba(52, 52, 52, 0.8)',
							// width: screenWidth,
							// height: screenHeight,
							width: "100%",
						}}>
						<ScrollView showsVerticalScrollIndicator={false}>
							<View
								style={{
									borderRadius: 20,
									padding: 10,
									// paddingTop: 0,
								}}>
								<Text
									style={{
										fontSize: 19,
										fontWeight: "bold",
										marginBottom: 10,
										color: "#FFF",
										// opacity: 0.7,
									}}>
									{this.props.isnewCustomer
										? "New "
										: "Update "}
									Customer
								</Text>

								<View
									style={{ width: "100%", marginBottom: 5 }}>
									<View
										style={{
											width: "100%",
											padding: 10,
											borderWidth: 0.3,
											borderColor: APP_COLOURS.BLACK,
											borderRadius: 10,
											// backgroundColor: APP_COLOURS.BG3,
										}}>
										<View
											style={{
												flexDirection: "row",
												justifyContent: "space-between",
												marginBottom: 5,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 13,
													color: "#FFF",
													opacity: 0.7,
												}}>
												First name
											</Text>
											<View
												style={{
													width: 20,
													height: 20,
													borderRadius: 7,
													justifyContent: "center",
													alignItems: "center",
													backgroundColor:
														this.state.customerData
															?.first_name !== ""
															? APP_COLOURS.GREEN
															: "transparent",
												}}>
												<Image
													source={require("../assets/images/check.png")}
													style={{
														width: 11,
														height: 11,
														resizeMode: "contain",
													}}
												/>
											</View>
										</View>

										<View
											style={{
												backgroundColor:
													APP_COLOURS.BG3,
												// backgroundColor: "#FFF",

												width: "100%",
												borderRadius: 13,
												borderWidth: 0.3,
												borderColor: APP_COLOURS.BLACK,
												borderRadius: 10,
												// borderColor: this.props.backgroundColor,
												padding: 8,
											}}>
											<TextInput
												allowFontScaling={false}
												style={{
													padding: 10,
													fontSize: 18,
													fontWeight: "600",

													fontWeight:
														this.state.customerData
															?.first_name !== ""
															? "600"
															: "300",
													color: "#FFF",
												}}
												placeholder='First name'
												maxLength={200}
												numberOfLines={1}
												clearButtonMode='always'
												placeholderTextColor='#E8E8E8'
												onChangeText={(text) => {
													this.setState(
														(prevState) => ({
															customerData: {
																...prevState.customerData,
																first_name:
																	text,
															},
														}),
													);
												}}
												defaultValue={
													this.state.customerData
														?.first_name
												}
												onFocus={async () => {}}
												onBlur={async () => {}}
												keyboardType='ascii-capable'
											/>
										</View>
									</View>
									<View
										style={{
											width: "100%",
											padding: 10,
											borderWidth: 0.3,
											borderColor: APP_COLOURS.BLACK,
											borderRadius: 10,
											// backgroundColor: APP_COLOURS.BG3,
										}}>
										<View
											style={{
												flexDirection: "row",
												justifyContent: "space-between",
												marginBottom: 5,
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 13,
													color: "#FFF",
													opacity: 0.7,
												}}>
												Last name
											</Text>
											<View
												style={{
													width: 20,
													height: 20,
													borderRadius: 7,
													justifyContent: "center",
													alignItems: "center",
													backgroundColor:
														this.state.customerData
															?.last_name !== ""
															? APP_COLOURS.GREEN
															: "transparent",
												}}>
												<Image
													source={require("../assets/images/check.png")}
													style={{
														width: 11,
														height: 11,
														resizeMode: "contain",
													}}
												/>
											</View>
										</View>
										<View
											style={{
												backgroundColor:
													APP_COLOURS.BG3,
												width: "100%",
												borderWidth: 0.3,
												borderColor: APP_COLOURS.BLACK,
												borderRadius: 10,
												padding: 8,
											}}>
											<TextInput
												allowFontScaling={false}
												style={{
													padding: 10,
													fontSize: 18,
													fontWeight:
														this.state.customerData
															?.last_name !== ""
															? "600"
															: "300",
													color: "#FFF",
													// opacity: 0.7,
												}}
												placeholder=' Last name'
												maxLength={200}
												numberOfLines={1}
												clearButtonMode='always'
												placeholderTextColor='#E8E8E8'
												onChangeText={(text) => {
													this.setState(
														(prevState) => ({
															customerData: {
																...prevState.customerData,
																last_name: text,
															},
														}),
													);
												}}
												defaultValue={
													this.state.customerData
														?.last_name
												}
												onFocus={async () => {}}
												onBlur={async () => {}}
												keyboardType='ascii-capable'
											/>
										</View>
									</View>
								</View>

								<View
									style={{ width: "100%", marginBottom: 5 }}>
									{/* <Text
                    style={{
                      color: APP_COLOURS.BLACK,

                      marginTop: 4,
                      fontSize: 16,
                    }}>
                    Country of origin
                  </Text> */}
									{/* <View
                    style={{
                      // backgroundColor: APP_COLOURS.BLACK,
                      backgroundColor: '#FFF',
                      width: '100%',
                      borderRadius: 13,
                      borderWidth: 0.3,
                      borderColor: APP_COLOURS.BLACK,
                      borderRadius: 10,
                      // borderColor: this.props.backgroundColor,
                      padding: 8,
                    }}>
                    <TextInput
                      allowFontScaling={false}
                      style={{
                        padding: 10,
                        fontSize: 17,
                        fontWeight: '600',
                        color: APP_COLOURS.BLACK,
                      }}
                      placeholder=" Country of origin"
                      maxLength={200}
                      numberOfLines={1}
                      clearButtonMode="always"
                      placeholderTextColor="#47494C"
                      onChangeText={text => {
                        this.setState(prevState => ({
                          customerData: {
                            ...prevState.customerData,
                            countryoforigin: text,
                          },
                        }));
                      }}
                      defaultValue={this.state.customerData?.countryoforigin}
                      onFocus={async () => {}}
                      onBlur={async () => {}}
                      keyboardType="ascii-capable"
                    />
                  </View> */}

									<NationalityPicker
										value={
											this.state.customerData?.nationality
										}
										update={(text) => {
											this.setState((prevState) => ({
												customerData: {
													...prevState.customerData,
													nationality: text,
												},
											}));
										}}
									/>
								</View>

								<View
									style={{
										width: "100%",
										padding: 10,
									}}>
									<View
										style={{
											width: "100%",
											padding: 10,
											paddingHorizontal: 0,
											// borderWidth: 0.3,
											// borderColor: APP_COLOURS.BLACK,
											borderRadius: 10,
										}}>
										<Text
											style={{
												color: APP_COLOURS.BLACK,
												fontSize: 13,
												color: "#FFF",
												opacity: 0.7,
												marginBottom: 8,
											}}>
											Select a preferred method of contact
										</Text>

										<View>
											<View
												style={{
													width: "100%",
													flexDirection: "row",
												}}>
												{["email", "cell", "none"].map(
													(pmoc) => (
														<TouchableOpacity
															style={{
																flex: 1,
															}}
															onPress={async () => {
																this.setState(
																	(
																		prevState,
																	) => ({
																		customerData:
																			{
																				...prevState.customerData,
																				pmoc: pmoc,
																			},
																	}),
																);
															}}>
															<View
																style={{
																	backgroundColor:
																		this
																			.state
																			.customerData
																			?.pmoc ===
																		pmoc
																			? APP_COLOURS.BLUE
																			: APP_COLOURS.BG3,
																	borderWidth: 0.3,
																	borderColor:
																		APP_COLOURS.BLACK,
																	height: "auto",
																	padding: 12,
																	borderRadius: 12,
																	justifyContent:
																		"center",
																	alignItems:
																		"center",
																	// paddingLeft: 25,
																	marginBottom: 10,
																	marginRight: 10,
																}}>
																<Text
																	style={{
																		color: "#FFF",
																		fontSize: 16,
																		fontWeight:
																			this
																				.state
																				.customerData
																				?.pmoc ===
																			pmoc
																				? "700"
																				: "500",
																	}}>
																	{pmoc}
																</Text>
															</View>
														</TouchableOpacity>
													),
												)}
											</View>
										</View>
									</View>
								</View>

								<View
									style={{
										width: "100%",
										padding: 10,
										opacity:
											this.state.customerData?.pmoc ===
											"none"
												? 0
												: 1,
										marginBottom: 20,
									}}>
									<Text
										style={{
											fontWeight: "600",
											fontSize: 18,
											color: "#FFF",
											marginBottom: 10,
										}}>
										Contact
									</Text>
									<TouchableOpacity
										style={{
											flex: 1,
											marginBottom: 10,
										}}
										onPress={async () => {
											this.setState({
												changeCellNumber:
													!this.state
														.changeCellNumber,
											});
										}}>
										<View
											style={{
												width: "100%",
												// padding: 10,
												// borderWidth: 0.3,
												// borderColor: APP_COLOURS.BLACK,
												borderRadius: 10,
												marginVertical: 5,
											}}>
											<View
												style={{
													flexDirection: "row",
													justifyContent:
														"space-between",
													marginBottom: 5,
													padding: 2,
												}}>
												<Text
													style={{
														color: APP_COLOURS.BLACK,
														fontSize: 13,
														color: "#FFF",
														opacity: 0.7,
													}}>
													Cell
												</Text>
												<View
													style={{
														width: 20,
														height: 20,
														borderRadius: 7,
														justifyContent:
															"center",
														alignItems: "center",
														backgroundColor:
															this.state
																.customerData
																?.cell
																?.number !== ""
																? APP_COLOURS.GREEN
																: "transparent",
														opacity:
															this.state
																.customerData
																?.cell
																?.number !== ""
																? 1
																: 0.1,
													}}>
													<Image
														source={require("../assets/images/check.png")}
														style={{
															width: 11,
															height: 11,
															resizeMode:
																"contain",
														}}
													/>
												</View>
											</View>

											<View
												style={{
													backgroundColor:
														APP_COLOURS.BG3,
													width: "100%",
													borderRadius: 13,
													borderRadius: 10,
													padding: 8,
												}}>
												<Text
													style={{
														flex: 1,
														fontWeight:
															this.state
																.customerData
																?.cell
																?.number !== ""
																? "600"
																: "300",
														padding: 10,
														color:
															this.state
																.customerData
																?.cell
																?.number !== ""
																? "#FFF"
																: APP_COLOURS.TEXTCOLOR,
														fontSize: 17,
													}}>
													{
														this.state.customerData
															?.cell?.code
													}
													{this.state.customerData
														?.cell?.number !== ""
														? this.state
																.customerData
																?.cell?.number
														: "Cell number"}
												</Text>
											</View>
										</View>
									</TouchableOpacity>

									<EmailInput
										emailSet={
											this.state.customerData?.email
												?.address
										}
										pmoc={
											this.state.customerData?.pmoc ===
											"email"
										}
										saveEmail={(email) => {
											console.log("email valid", email);
											this.setState((prevState) => ({
												customerData: {
													...prevState.customerData,
													email: {
														address: email,
														validated: false,
														otp: "98765",
													},
												},
											}));
										}}
									/>
								</View>

								<View
									style={{
										width: "100%",
										padding: 10,
									}}>
									<View
										style={{
											backgroundColor: APP_COLOURS.BG3,
											padding: 10,
											width: "100%",
											paddingBottom: 20,
											borderRadius: 15,
											marginTop: 20,
										}}>
										<Text
											style={{
												fontWeight: "600",
												fontSize: 18,
												color: "#FFF",
											}}>
											Demographics
										</Text>
										{this.state.gender !== "" ? (
											<Text
												style={{
													fontWeight: "600",
													// marginTop: 10,
													fontSize: 19,
												}}>
												{this.state.ethnicity}{" "}
												{this.state.gender}{" "}
												{/* {this.state.hair_colour} */}
											</Text>
										) : null}

										<Gender
											value={
												this.state.customerData?.gender
											}
											selected={(g) => {
												this.setState((prevState) => ({
													customerData: {
														...prevState.customerData,
														gender: g,
													},
												}));
											}}
										/>

										<EthnicitySelector
											defaultEthnicity={
												this.state.customerData
													?.ethnicity
											}
											selectedEthnicity={(sth) => {
												// this.setState({
												//   customerData: {
												//     ...this.state.customerData,
												//     ethnicity: sth,
												//   },
												// });

												this.setState((prevState) => ({
													customerData: {
														...prevState.customerData,
														ethnicity: sth,
													},
												}));
											}}
										/>

										<AgeRangePicker
											value={
												this.state.customerData
													?.age_range
											}
											selected={(sth) => {
												this.setState((prevState) => ({
													customerData: {
														...prevState.customerData,
														age_range: sth,
													},
												}));
											}}
										/>
									</View>
								</View>
								<View
									style={{
										width: "100%",
										padding: 10,
									}}>
									<TouchableOpacity
										style={{
											width: "100%",
											marginTop: 50,
										}}
										onPress={() => {
											// this.setState({
											//   showTandCs: true,
											// });
											if (this.props.isnewCustomer) {
												this.createCustomer(
													this.state.customerData,
												);
											} else {
												this.updateCustomerData(
													this.state.customerData,
												);
											}
										}}>
										<View
											style={{
												width: "100%",
												padding: 15,
												borderRadius: 20,
												backgroundColor:
													APP_COLOURS.BLUE,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#FFF",
													fontWeight: "300",
												}}>
												{this.props.isnewCustomer
													? "Save & next"
													: "Update & next"}
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							</View>
							<View
								style={{
									height: 100,
								}}
							/>
							<Text
								style={{
									fontSize: 12,
									fontWeight: "300",
									color: "#FFF",
									opacity: 0.2,
									width: "100%",
									textAlign: "center",
								}}>
								{this.props.user_id}
							</Text>
						</ScrollView>
					</View>
				)}

				{this.state.changeCellNumber ? (
					<Modal
						animationType={"fade"}
						transparent={true}
						visible={this.state.changeCellNumber}
						onRequestClose={() => {
							this.setState({
								changeCellNumber: false,
							});
						}}>
						<SafeAreaView
							style={{
								flex: 1,
								// backgroundColor: "#FFF",
								backgroundColor: APP_COLOURS.BG,
							}}>
							<View
								style={{
									flex: 1,
									// backgroundColor: "#FFF",
									borderRadius: 10,
									padding: 15,
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											changeCellNumber: false,
										})
									}>
									<View
										style={{
											flexDirection: "row",
											marginRight: 15,
											alignItems: "center",
										}}>
										<Text
											style={{
												flex: 1,
												alignItems: "center",
												justifyContent: "center",
												textAlign: "center",
												color: "#FFF",
												fontSize: 17,
												fontWeight: "500",
											}}>
											{this.state.customerData?.cell
												?.number !== ""
												? this.state.customerData?.cell
														?.number
												: "Cell number"}{" "}
										</Text>
									</View>
								</TouchableOpacity>
								<CellNumber
									label='Cell number:'
									value={
										this.state.customerData?.cell?.number
									}
									placeholder='Cell number'
									update={(cell) => {
										this.setState((prevState) => ({
											customerData: {
												...prevState.customerData,
												cell: {
													...cell,
												},
											},
										}));
									}}
								/>
								<TouchableOpacity
									onPress={() => {
										this.setState({
											changeCellNumber: false,
										});
									}}>
									<View
										style={{
											padding: 10,
											backgroundColor: APP_COLOURS.GREEN,
											justifyContent: "center",
											alignItems: "center",
											borderRadius: 9,
											marginTop: 5,
										}}>
										<Text
											style={{
												color: "#FFF",
												fontSize: 16,
											}}>
											Save
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</SafeAreaView>
						<KeyboardResponsiveView />
					</Modal>
				) : null}

				{this.state.loading ? (
					<Modal
						animationType={"fade"}
						transparent={true}
						visible={true}>
						<View
							style={{
								flex: 1,
								backgroundColor: APP_COLOURS.BG,
								opacity: 0.8,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<ActivityIndicator color='#FFF' size={"large"} />
						</View>
					</Modal>
				) : null}
			</View>
		);
	}

	tandcModal = () => {
		return (
			<Modal
				animationType={"fade"}
				transparent={true}
				visible={true}
				onRequestClose={() => {
					console.log("Modal has been closed.");
				}}>
				<View
					style={{
						flex: 1,
						position: "absolute",
						zIndex: 999,
						backgroundColor: "rgba(52, 52, 52, 0.8)",
						width: screenWidth,
						height: screenHeight,
					}}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<View
							style={{
								width: "100%",
								height: screenHeight - 120,
								backgroundColor: "#FFF",
								borderRadius: 20,
								// padding: 10,
								justifyContent: "center",
								alignItems: "center",
								shadowColor: "#999797",
								shadowOffset: {
									width: 0,
									height: 4,
								},
								shadowOpacity: 0.3,
								shadowRadius: 4.65,
								elevation: 8,
							}}>
							<View
								style={{
									width: "100%",
									padding: 20,
									height: "auto",
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 19,
										fontFamily: "Avenir Next",
										textAlign: "center",
										fontWeight: "700",
									}}>
									Terms and conditions
								</Text>
							</View>
							<View
								style={{
									flex: 1,
									width: "100%",
								}}>
								<ScrollView>
									<View
										style={{
											padding: 10,
										}}>
										<POPIAConcent />
										<AppTermsAndConditions />
									</View>
								</ScrollView>
							</View>
							<View
								style={{
									width: "100%",
									padding: 10,
								}}>
								<TouchableOpacity
									onPress={async () => {
										await this.setState({
											applyNow: false,
										});

										// this.setState({
										//   customerProfileModal: !this.state.customerProfileModal,
										// });
										// this.registerInteraction();
										// this.makeRemoteRequest();

										this.props.updateCustomer(this.state);
									}}>
									<View
										style={{
											width: "100%",
											padding: 10,
											borderRadius: 10,
											backgroundColor: APP_COLOURS.BLACK,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												color: "#FFF",
												fontWeight: "300",
											}}>
											Accept
										</Text>
									</View>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={() => {
										this.setState({
											showTandCs: false,
										});
									}}>
									<View
										style={{
											width: "100%",
											padding: 10,
											borderRadius: 10,
											// backgroundColor: APP_COLOURS.BLACK,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												color: APP_COLOURS.BLACK,
												fontWeight: "300",
											}}>
											cancel
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				</View>
			</Modal>
		);
	};
}
