import React from "react";
import "./Pie.css";

const Pie = ({ percentage, color = "black" }) => {
	// percentage should be passed as a decimal (e.g., 0.75 for 75%)
	const piePercentage = percentage * 100; // Convert to a value out of 100

	const pieStyle = {
		"--p": `${piePercentage}`,
		"--c": color,
		"--b": "20px",
		fontSize: "1em",
		width: "220px",
		height: "220px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "50%",
	};

	return (
		<div className='pie animate' style={pieStyle}>
			<div
				style={{
					fontSize: 35,
					color: "#FFF",
				}}>
				{Math.round(piePercentage)}%
			</div>
		</div>
	);
};

const PieChart = ({ percentage, color }) => {
	return <Pie percentage={percentage} color={color} />;
};

export default PieChart;
