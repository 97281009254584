import React, { Component } from "react";
import {
	ScrollView,
	TextInput,
	View,
	Text,
	ActivityIndicator,
	TouchableOpacity,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import { crudRead, getCustomers } from "../../CRUDHELPER";

function removeNonNumericChars(str) {
	return str.replace(/\D/g, "");
}

export default class CustomerSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customers: [],
			filteredCustomersCell: [],
			filteredCustomersEmail: [],
			searchText: "",
			searchTextEmail: "",
			loading: true,
		};
	}

	componentDidMount() {
		this.fetchCustomers();
	}

	fetchCustomers = () => {
		getCustomers("", (status, d) => {
			console.log("get customers", status);
			if (status === 200) {
				this.setState({
					loading: false,
					customers: d,
				});
				console.log(JSON.stringify(d));
			} else {
				this.setState({
					loading: false,
					error: true,
				});
				// alert("Error loading customers");
			}
		});
	};

	renderCustomerCell = (customer) => {
		return (
			<TouchableOpacity
				key={customer.id}
				onPress={() => {
					console.log(customer);
					this.props.selectedCustomer(customer);
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						// paddingBottom: 5,
						// borderBottomWidth: 0.5,
						marginBottom: 1,
						borderColor: APP_COLOURS.DESELECTEDCOLOR,
						backgroundColor: APP_COLOURS.BG3,
						padding: 10,
						borderRadius: 4,
						paddingVertical: 20,
						alignItems: "center",
						// opacity: 0.6,
					}}>
					<Text
						style={{
							width: 200,
							fontSize: 17,
							color: "#FFF",
						}}>
						{customer?.first_name} {customer?.last_name}
					</Text>
					<Text
						style={{
							fontSize: 17,
							color: "#FFF",
						}}>
						{customer?.cell}
					</Text>
				</View>
			</TouchableOpacity>
		);
	};

	renderCustomerEmail = (customer) => {
		return (
			<TouchableOpacity
				key={customer.id}
				onPress={() => {
					console.log(customer);
					this.props.selectedCustomer(customer);
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						// paddingBottom: 5,
						// borderBottomWidth: 0.5,
						marginBottom: 1,
						borderColor: APP_COLOURS.DESELECTEDCOLOR,
						backgroundColor: APP_COLOURS.BG3,
						padding: 10,
						borderRadius: 4,
						paddingVertical: 20,
						alignItems: "center",
						// opacity: 0.6,
					}}>
					<Text
						style={{
							width: 200,
							fontSize: 17,
							color: "#FFF",
						}}>
						{customer?.first_name} {customer?.last_name}
					</Text>
					<Text
						style={{
							fontSize: 17,
							color: "#FFF",
						}}>
						{customer?.cemail}
					</Text>
				</View>
			</TouchableOpacity>
		);
	};

	render() {
		return (
			<View
				style={{
					flex: 1,

					borderRadius: 10,
				}}>
				<View style={{ width: "100%", marginBottom: 4 }}>
					<View
						style={{
							flexDirection: "row",
						}}>
						{/* {this.state.loading ? (
							<ActivityIndicator color='#FFF' />
						) : null} */}

						<Text
							allowFontScaling={false}
							style={{
								fontWeight: "300",
								fontSize: 17,
								margin: 10,
								color: "#FFF",
								width: "100%",
								textAlign: "center",
								marginLeft: 10,
								opacity: 0.7,
							}}>
							Search with cell number
						</Text>
					</View>
					<View
						style={{
							width: "100%",
							borderRadius: 13,
							// borderWidth: 0.5,
							// borderRadius: 10,
							// borderColor: this.props.backgroundColor,
						}}>
						<TextInput
							allowFontScaling={false}
							style={{
								padding: 15,
								fontSize: 18,
								fontWeight: "300",
								color: "#FFF",
								// backgroundColor: APP_COLOURS.BG,
								backgroundColor: "#000",
								borderRadius: 15,
								textAlign: "center",
								borderColor:
									this.state.searchText !== ""
										? APP_COLOURS.BLUE
										: APP_COLOURS.BG3,
								borderWidth: 1,
							}}
							placeholder='Cell number'
							maxLength={200}
							autoFocus
							numberOfLines={1}
							clearButtonMode='always'
							placeholderTextColor='#FFF'
							onFocus={async () => {}}
							onBlur={async () => {}}
							keyboardType='number-pad'
							onChangeText={(text) => {
								// console.log(text);
								// return;
								let filteredCustomersCell =
									this.state.customers.filter((customer) => {
										let exis =
											typeof customer?.cell !==
											"undefined"
												? customer?.cell
												: "";
										if (exis !== null) {
											return removeNonNumericChars(exis)
												.toLowerCase()
												.includes(text.toLowerCase());
										}
									});

								if (text === "") {
									filteredCustomersCell = [];
								}
								// this.fetchCustomers();
								this.setState({
									filteredCustomersCell,
									searchText: text,
								});
							}}
							value={this.state.searchText}
						/>
					</View>
				</View>
				<View style={{ width: "100%", marginBottom: 4 }}>
					<View
						style={{
							flexDirection: "row",
						}}>
						{/* {this.state.loading ? (
							<ActivityIndicator color='#FFF' />
						) : null} */}

						<Text
							allowFontScaling={false}
							style={{
								fontWeight: "300",
								fontSize: 17,
								margin: 10,
								color: "#FFF",
								width: "100%",
								textAlign: "center",
								marginLeft: 10,
								opacity: 0.7,
							}}>
							Search with email address
						</Text>
					</View>
					<View
						style={{
							width: "100%",
							borderRadius: 13,
							// borderWidth: 0.5,
							// borderRadius: 10,
							// borderColor: this.props.backgroundColor,
						}}>
						<TextInput
							allowFontScaling={false}
							style={{
								padding: 15,
								fontSize: 18,
								fontWeight: "300",
								color: "#FFF",
								// backgroundColor: APP_COLOURS.BG,
								backgroundColor: "#000",
								borderRadius: 15,
								textAlign: "center",
								borderColor:
									this.state.searchText !== ""
										? APP_COLOURS.BLUE
										: APP_COLOURS.BG3,
								borderWidth: 1,
							}}
							placeholder='Email address'
							maxLength={200}
							autoFocus
							numberOfLines={1}
							clearButtonMode='always'
							placeholderTextColor='#FFF'
							onFocus={async () => {}}
							onBlur={async () => {}}
							keyboardType='email-address'
							onChangeText={(text) => {
								// console.log(text);
								// return;
								let filteredCustomersEmail =
									this.state.customers.filter((customer) => {
										let exis =
											typeof customer?.email !==
											"undefined"
												? customer?.email
												: "";
										if (exis !== null) {
											return exis
												.toLowerCase()
												.includes(text.toLowerCase());
										}
									});

								if (text === "") {
									filteredCustomersEmail = [];
								}
								// this.fetchCustomers();
								this.setState({
									filteredCustomersEmail,
									searchTextEmail: text,
								});
							}}
							value={this.state.searchTextEmail}
						/>
					</View>
				</View>
				{this.state.loading ? (
					<View
						style={{
							flex: 1,
							padding: 10,
							backgroundColor: APP_COLOURS.BG2,
							borderRadius: 8,
							marginTop: 5,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Text>
							Loading... <ActivityIndicator color='#FFF' />
						</Text>
					</View>
				) : (
					<View
						style={{
							flex: 1,
							padding: 10,
							backgroundColor: APP_COLOURS.BG2,
							borderRadius: 8,
							marginTop: 5,
						}}>
						<ScrollView>
							{this.state.filteredCustomersCell
								.slice(0, 5)
								.map(this.renderCustomerCell)}

							{this.state.filteredCustomersEmail
								.slice(0, 5)
								.map(this.renderCustomerEmail)}
							<View
								style={{
									height: 300,
								}}
							/>
						</ScrollView>
					</View>
				)}
			</View>
		);
	}
}
