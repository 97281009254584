class AsyncStorage {
	static async getItem(key) {
		return new Promise((resolve, reject) => {
			try {
				const value = localStorage.getItem(key);
				resolve(value);
			} catch (error) {
				reject(error);
			}
		});
	}

	static async setItem(key, value) {
		return new Promise((resolve, reject) => {
			try {
				localStorage.setItem(key, value);
				resolve(true);
			} catch (error) {
				reject(error);
			}
		});
	}

	// Add other methods like removeItem, clear, etc., if needed
}

export default AsyncStorage;

// // AsyncStorage.js
// class AsyncStorage {
// 	static async setItem(key, value) {
// 	  try {
// 		 localStorage.setItem(key, JSON.stringify(value));
// 	  } catch (error) {
// 		 console.error("AsyncStorageWeb setItem error: ", error);
// 	  }
// 	}

// 	static async getItem(key) {
// 	  try {
// 		 const value = localStorage.getItem(key);
// 		 return value ? JSON.parse(value) : null;
// 	  } catch (error) {
// 		 console.error("AsyncStorageWeb getItem error: ", error);
// 		 return null;
// 	  }
// 	}

// 	static async removeItem(key) {
// 	  try {
// 		 localStorage.removeItem(key);
// 	  } catch (error) {
// 		 console.error("AsyncStorageWeb removeItem error: ", error);
// 	  }
// 	}

// 	static async clear() {
// 	  try {
// 		 localStorage.clear();
// 	  } catch (error) {
// 		 console.error("AsyncStorageWeb clear error: ", error);
// 	  }
// 	}

// 	static async getAllKeys() {
// 	  try {
// 		 return Object.keys(localStorage);
// 	  } catch (error) {
// 		 console.error("AsyncStorageWeb getAllKeys error: ", error);
// 		 return [];
// 	  }
// 	}

// 	static async multiGet(keys) {
// 	  try {
// 		 return keys.map((key) => [key, JSON.parse(localStorage.getItem(key))]);
// 	  } catch (error) {
// 		 console.error("AsyncStorageWeb multiGet error: ", error);
// 		 return [];
// 	  }
// 	}

// 	static async multiSet(keyValuePairs) {
// 	  try {
// 		 keyValuePairs.forEach(([key, value]) => {
// 			localStorage.setItem(key, JSON.stringify(value));
// 		 });
// 	  } catch (error) {
// 		 console.error("AsyncStorageWeb multiSet error: ", error);
// 	  }
// 	}

// 	static async multiRemove(keys) {
// 	  try {
// 		 keys.forEach((key) => {
// 			localStorage.removeItem(key);
// 		 });
// 	  } catch (error) {
// 		 console.error("AsyncStorageWeb multiRemove error: ", error);
// 	  }
// 	}
//  }

//  export default AsyncStorageWeb;
