import { Dimensions, LayoutAnimation, UIManager, Platform } from "react-native";

if (Platform.OS === "android") {
	if (UIManager.setLayoutAnimationEnabledExperimental) {
		UIManager.setLayoutAnimationEnabledExperimental(true);
	}
}

export const APP_COLOURS = {
	MAIN_BG: "#131119",
	MAIN: "#131119",
	BACKGROUND: "#17151D",
	PRIMARY: "#17151D",
	SECONDARY: "#0676ED",
	//   BACKGROUND: '#F8FAFF',
	TEXTWHITE: "#F8FAFF",

	BG: "#121419",
	BG1: "#1F2229",
	BG2: "#2B2C31",
	BG3: "#35373C",
	BG4: "#4B4E54",
	BG5: "#686868",
	TEXTCOLOR: "#C7C7D2",
	PLACEHOLDER: "#797979",
	TEXTINPUT: "#0720D5",
	PURPLE: "#8F6FFB",
	BUTTON2: "#252B35",
	DESELECTEDCOLOR: "#EEF2FC",
	OFFWHITE: "#F4F6FA",
	WHITE: "#FFF",
	GREEN: "#07D55D",
	GREENCOMPLETE: "rgba(55, 255, 86, 0.4)",
	ORANGE: "#FF8807",
	YELLOW: "#FFD76E",
	RED: "#E35B5B",
	RED2: "#FF5F5F",
	BLUE: "#0676ED",
	BLUE2: "#3F6EFF",
	WHITE_TRANSPARENT: "rgba(212, 212, 212, 0.2)",
	WHITE_TRANSPARENT1: "rgba(212, 212, 212, 0.1)",
	WHITE_TRANSPARENT2: "rgba(25, 29, 36, 0.4)",
	WHITE_TRANSPARENT3: "#323A47",
	WHITE_TRANSPARENT4: "rgba(212, 212, 212, 0.05)",
	WHITE_TRANSPARENT5: "rgba(0, 0, 0, 0.05)",
	WHITE_TRANSPARENT7: "rgba(212, 212, 212, 0.03)",
	WHITE_TRANSPARENT8: "rgba(212, 212, 212, 0.015)",

	WHITE_TRANSPARENT6: "rgba(0, 0, 0, 0.015)",
	WHITE_TRANSPARENT9: "rgba(255, 255, 255, 0.8)",
	WHITE_TRANSPARENT10: "rgba(255, 255, 255, 0.5)",
	BACK_TRANSPARENT: "rgba(0, 0, 0, 0.7)",
	BLACK_TRANS: "rgba(52, 52, 52, 0.2)",
	BLACK: "#252525",

	RED_TRANS: "rgba(255, 95, 95, 0.05)",
};

export var CustomLayoutSpring = {
	duration: 400,
	create: {
		type: LayoutAnimation.Types.spring,
		property: LayoutAnimation.Properties.opacity,
		springDamping: 1,
	},
	update: {
		type: LayoutAnimation.Types.spring,
		property: LayoutAnimation.Properties.opacity,
		springDamping: 0.9,
	},
	// delete: {
	//   type: LayoutAnimation.Types.spring,
	//   property: LayoutAnimation.Properties.opacity,
	//   springDamping: 0.9,
	// },
};

export const screenWidth = Math.round(Dimensions.get("window").width);
export const screenHeight = Math.round(Dimensions.get("window").height);

export function StatusColour(d) {
	let color = "";
	let options = {
		online: APP_COLOURS.GREEN,
		busy: APP_COLOURS.ORANGE,
		"On lunch": APP_COLOURS.PURPLE,
		offline: APP_COLOURS.RED2,
	};
	color = typeof options[d] !== "undefined" ? options[d] : "transparent";
	return color;
}

export const PROJECTSTATUS = [
	{
		type: 1,
		status: "Urgent",
		color: APP_COLOURS.RED2,
		description: "Task or project needs immediate attention or action.",
	},
	{
		type: 2,
		status: "Attention Required",
		color: APP_COLOURS.ORANGE,
		description: "Custom status entered by the user.",
	},

	{
		type: 4,
		status: "In Progress",
		color: APP_COLOURS.YELLOW,
		description: "Task or project is currently underway.",
	},
	{
		type: 5,
		status: "Awaiting",
		color: APP_COLOURS.ORANGE,
		description: "Task or project is temporarily paused.",
	},
	{
		type: 6,
		status: "Paused",
		color: "grey",
		description: "Task or project is behind its intended schedule.",
	},
	{
		type: 7,
		status: "Completed",
		color: APP_COLOURS.GREEN,
		description: "Task or project has been successfully finished.",
	},
	{
		type: 8,
		status: "Published",
		color: APP_COLOURS.GREENCOMPLETE,
		description: "Published",
	},
	{
		type: 9,
		status: "Delayed",
		color: APP_COLOURS.ORANGE,
		description: "Task or project is behind its intended schedule.",
	},

	{
		type: 10,
		status: "Under Review by client",
		color: APP_COLOURS.PURPLE,
		description: "Task or project is being assessed or examined.",
	},

	{
		type: 11,
		status: "Cancelled",
		color: APP_COLOURS.RED,
		description: "Task or project has been terminated before completion.",
	},
	{
		type: 12,
		status: "Not Started",
		color: "white",
		description: "Task or project has not begun.",
	},
	{
		type: 13,
		status: "Scheduled",
		color: APP_COLOURS.WHITE_TRANSPARENT5,
		description: "Task or project has not begun.",
	},
	{
		type: 13,
		status: "Awaiting Approval",
		color: APP_COLOURS.PURPLE,
		description: "Task or project has not begun.",
	},
	{
		type: 14,
		status: "Planning",
		color: "#E8E8E8",
		description: "Task or project has not begun.",
	},
	{
		type: 15,
		status: "Design",
		color: APP_COLOURS.BG2,
		description: "",
	},
	{
		type: 16,
		status: "Copy",
		color: APP_COLOURS.YELLOW,
		description: "",
	},
	{
		type: 17,
		status: "Approved",
		color: APP_COLOURS.BLUE2,
		description: "",
	},
	{
		type: 18,
		status: "Pending",
		color: "#FFF",
		description: "",
	},
	{
		type: 18,
		status: "pending",
		color: "#E8E8E8",
		description: "",
	},
];

export const Avatars = [
	{
		pp: require("../assets/images/Avatars/1.png"),
	},
	{
		pp: require("../assets/images/Avatars/2.png"),
	},
	{
		pp: require("../assets/images/Avatars/3.png"),
	},
	{
		pp: require("../assets/images/Avatars/4.png"),
	},
	{
		pp: require("../assets/images/Avatars/5.png"),
	},
	{
		pp: require("../assets/images/Avatars/6.png"),
	},
	{
		pp: require("../assets/images/Avatars/7.png"),
	},
	{
		pp: require("../assets/images/Avatars/8.png"),
	},
	{
		pp: require("../assets/images/Avatars/9.png"),
	},
	{
		pp: require("../assets/images/Avatars/10.png"),
	},
	{
		pp: require("../assets/images/Avatars/11.png"),
	},
	{
		pp: require("../assets/images/Avatars/12.png"),
	},
	{
		pp: require("../assets/images/Avatars/13.png"),
	},
	{
		pp: require("../assets/images/Avatars/14.png"),
	},
	{
		pp: require("../assets/images/Avatars/15.png"),
	},
	{
		pp: require("../assets/images/Avatars/16.png"),
	},
	{
		pp: require("../assets/images/Avatars/17.png"),
	},
	{
		pp: require("../assets/images/Avatars/18.png"),
	},
	{
		pp: require("../assets/images/Avatars/19.png"),
	},
	{
		pp: require("../assets/images/Avatars/20.png"),
	},
	{
		pp: require("../assets/images/Avatars/21.png"),
	},
	{
		pp: require("../assets/images/Avatars/22.png"),
	},
	{
		pp: require("../assets/images/Avatars/23.png"),
	},
	{
		pp: require("../assets/images/Avatars/24.png"),
	},
	{
		pp: require("../assets/images/Avatars/25.png"),
	},
	{
		pp: require("../assets/images/Avatars/26.png"),
	},
	{
		pp: require("../assets/images/Avatars/27.png"),
	},
	{
		pp: require("../assets/images/Avatars/28.png"),
	},
	{
		pp: require("../assets/images/Avatars/29.png"),
	},
	{
		pp: require("../assets/images/Avatars/30.png"),
	},
	{
		pp: require("../assets/images/Avatars/31.png"),
	},
	{
		pp: require("../assets/images/Avatars/32.png"),
	},
	{
		pp: require("../assets/images/Avatars/33.png"),
	},
	{
		pp: require("../assets/images/Avatars/34.png"),
	},
	{
		pp: require("../assets/images/Avatars/35.png"),
	},
	{
		pp: require("../assets/images/Avatars/36.png"),
	},
	{
		pp: require("../assets/images/Avatars/37.png"),
	},
	{
		pp: require("../assets/images/Avatars/38.png"),
	},
	{
		pp: require("../assets/images/Avatars/39.png"),
	},
	{
		pp: require("../assets/images/Avatars/40.png"),
	},
	{
		pp: require("../assets/images/Avatars/41.png"),
	},
	{
		pp: require("../assets/images/Avatars/42.png"),
	},
	{
		pp: require("../assets/images/Avatars/43.png"),
	},
	{
		pp: require("../assets/images/Avatars/44.png"),
	},
	{
		pp: require("../assets/images/Avatars/45.png"),
	},
	{
		pp: require("../assets/images/Avatars/46.png"),
	},
	{
		pp: require("../assets/images/Avatars/47.png"),
	},
	{
		pp: require("../assets/images/Avatars/48.png"),
	},
	{
		pp: require("../assets/images/Avatars/49.png"),
	},
	{
		pp: require("../assets/images/Avatars/50.png"),
	},
];

export function TaskStatus(type) {
	let color = "";
	// let options = {
	//   Pending: APP_COLOURS.BLUE,
	//   Done: APP_COLOURS.GREEN,
	//   Inprogress: APP_COLOURS.GREEN,
	//   '': 'transparent',
	//   Paused: APP_COLOURS.YELLOW,
	//   'Awaiting client': APP_COLOURS.ORANGE,
	//   Stuck: APP_COLOURS.RED2,
	// };

	let pallet = PROJECTSTATUS.find((m) => m.status === type);

	// console.log(pallet);
	color = typeof pallet !== "undefined" ? pallet.color : "gray";

	// color = typeof options[d] !== 'undefined' ? options[d] : APP_COLOURS.YELLOW;
	return color;
}
