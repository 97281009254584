import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	TouchableOpacity,
	ScrollView,
	LayoutAnimation,
	Image,
} from "react-native";
import AutoHeightImage from "../../AutoHeightImageComp";
import { APP_COLOURS } from "../../../APP_VARS";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function validURL(str) {
	var pattern = new RegExp(
		"^(https?:\\/\\/)?" + // protocol
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
			"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
			"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
			"(\\#[-a-z\\d_]*)?$",
		"i",
	); // fragment locator
	return !!pattern.test(str);
}

class Column extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	addToCart = (d) => {
		this.props.addToCart(d);
	};

	render() {
		let d = this.props.data;
		return (
			<View
				style={{
					width: "100%",
					marginBottom: 2,
					// backgroundColor: "#FFF",
					backgroundColor: APP_COLOURS.TEXTWHITE,
					flexDirection: "row",
					padding: 5,
					borderBottomWidth: 0.5,
					borderBottomColor: "#D4D4D4",
					borderRadius: 10,
				}}>
				<TouchableOpacity
					onPress={() => {
						// touchButtonSound();
						// Actions.ProductPage({
						// 	data: d,
						// 	addToCart_: () => {
						// 		if (!this.props.isSearch) {
						// 			d.qty = 1;
						// 			this.addToCart(d);
						// 		}
						// 	},
						// });
					}}>
					<View
						style={{
							alignItems: "center",
							justifyContent: "center",
							borderWidth: 0.5,
							borderRadius: 15,
							borderColor: "#999797",

							padding: 10,
							overflow: "hidden",
							width: 80,
							height: 80,
							// display: "none",
						}}>
						{/* {typeof d.productUrl !== "undefined" ? (
							<AutoHeightImage
								width={80}
								source={{ uri: d.productUrl }}
								style={{
									alignItems: "center",
									justifyContent: "center",
								}}
							/>
						) : null} */}

						{typeof d.image !== "undefined" ? (
							<AutoHeightImage
								width={80}
								source={{ uri: d.image }}
								style={
									{
										// alignItems: "center",
										// justifyContent: "center",
									}
								}
							/>
						) : null}
					</View>
					<View
						style={{
							borderWidth: 1,
							borderRadius: 15,
							width: 80,
							height: 80,
							position: "absolute",
							borderColor: "#999797",
							top: 0,
							// display: "none",
						}}
					/>
				</TouchableOpacity>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
					}}>
					<View
						style={{
							flex: 1,
							justifyContent: "space-between",
							alignItems: "flex-end",
							// marginBottom: 20,
							marginTop: 10,
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "column",
								width: "100%",
								paddingLeft: 5,
							}}>
							<TouchableOpacity
								onPress={() => {
									// Actions.ProductPage({
									// 	data: d,
									// 	addToCart_: () => {
									// 		if (!this.props.isSearch) {
									// 			d.qty = 1;
									// 			this.addToCart(d);
									// 		}
									// 	},
									// });
								}}>
								<Text
									style={{ fontWeight: "500", fontSize: 13 }}>
									{d.name}
								</Text>
								<Text
									style={{
										fontSize: 8,
										opacity: 0.8,
										marginBottom: 2,
										fontStyle: "italic",
									}}>
									{d.barcode}
								</Text>
								<Text
									style={{ fontWeight: "300", fontSize: 10 }}>
									{d.description}
								</Text>
								<Text
									style={{
										fontSize: 8,
										opacity: 0.8,
										marginBottom: 2,
									}}>
									{d.category}
								</Text>
							</TouchableOpacity>
						</View>
					</View>

					<View
						style={{
							justifyContent: "center",

							width: 120,
							padding: 3,
							display: this.props.purchased ? "none" : "flex",
						}}>
						<TouchableOpacity
							style={{
								// backgroundColor: "#FFF",
								backgroundColor: APP_COLOURS.TEXTWHITE,
								borderRadius: 8,
								// justifyContent: 'center',
								alignItems: "flex-end",
								justifyContent: "space-between",
								height: 70,
							}}
							onPress={() => {
								if (!this.props.isSearch) {
									// touchButtonSound();
									d.qty = 1;
									this.addToCart(d);
								}
							}}>
							<Text
								style={{
									fontSize: this.props.purchased ? 11 : 18,
									marginBottom: 10,
									fontWeight: "700",
									fontStyle: this.props.purchased
										? "italic"
										: "normal",
									color: this.props.purchased
										? APP_COLOURS.GREEN
										: APP_COLOURS.BG,
								}}>
								{this.props.purchased
									? "Purchased"
									: currencyFormat(parseFloat(d.price))}
							</Text>
							{this.props.isSearch ? null : this.props.incart ? (
								<>
									<View
										style={{
											alignItems: "center",
											justifyContent: "center",
											backgroundColor: APP_COLOURS.GREEN,
											borderRadius: 5,
											padding: 5,
											width: "100%",
										}}>
										<AutoHeightImage
											width={17}
											source={require("../../../../assets/images/cart2.png")}
										/>
									</View>

									<View
										style={{
											alignItems: "center",
											justifyContent: "center",
											// backgroundColor: APP_COLOURS.GREEN,
											borderRadius: 5,
											// padding: 5,
											width: "100%",
										}}>
										<Text
											style={{
												fontWeight: "400",
												fontSize: 8,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											- remove from cart
										</Text>
									</View>
								</>
							) : (
								<View
									style={{
										alignItems: "center",
										justifyContent: "center",
										backgroundColor: "#000",
										borderRadius: 5,
										padding: 5,
										width: "100%",
									}}>
									<Text
										adjustsFontSizeToFit
										style={{
											fontWeight: "400",
											fontSize: 9,
											color: "#FFF",
										}}>
										+ Add to cart
									</Text>
								</View>
							)}
						</TouchableOpacity>
					</View>
				</View>
			</View>
		);
	}
}

export default Column;
