import React, {Component} from 'react';
import {View, Text} from 'react-native';

export default class AppTermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let fs = 11;
    return (
      <View>
        <Text
          style={{
            color: '#000',
            fontSize: fs,
          }}>
          Terms and Conditions for Using the TRAQ App
        </Text>
        <Text
          style={{
            color: '#000',
            marginVertical: 4,
            fontSize: fs,
          }}>
          Please read these terms and conditions carefully before using the TRAQ
          app. By using the app, you agree to be bound by these terms and
          conditions.
        </Text>
        <Text
          style={{
            color: '#000',
            marginVertical: 4,
            fontSize: fs,
          }}>
          Acceptance of Terms By using the TRAQ app, you agree to these terms
          and conditions. If you do not agree to these terms and conditions, you
          may not use the app.
        </Text>
        <Text
          style={{
            color: '#000',
            marginVertical: 4,
            fontSize: fs,
          }}>
          Information Collected and Stored The TRAQ app collects sensitive
          information and stores it locally on your device. The information
          includes but is not limited to location data, personal information,
          and health information. The information is uploaded to our servers
          once there is an internet connection. Responsibility for Lost or
          Stolen Information If the TRAQ app is lost or stolen, we do not take
          responsibility for the lost information. We do our best to protect the
          information, but we cannot guarantee its accuracy and safety.
          Ownership and Management of Information By accepting the terms and
          conditions, you agree that the information gathered is not owned or
          managed by TRAQ.
        </Text>
        <Text
          style={{
            color: '#000',
            marginVertical: 4,
            fontSize: fs,
          }}>
          Any and all information gathered must be exported and processed by the
          user and will not be kept after export. Use of Information The
          information collected by the TRAQ app is used solely for the purpose
          of providing you with the services offered by the app. We do not share
          your information with any third parties unless required by law.
          Security of Information We take reasonable steps to protect the
          information collected by the TRAQ app. However, we cannot guarantee
          the security of the information, and we are not responsible for any
          unauthorized access to the information. Modification of Terms We
          reserve the right to modify these terms and conditions at any time
          without prior notice. Your continued use of the TRAQ app after any
          modification constitutes your acceptance of the modified terms and
          conditions. Governing Law These terms and conditions shall be governed
          by and construed in accordance with the laws of the jurisdiction in
          which TRAQ operates.
        </Text>
        <Text
          style={{
            color: '#000',
            marginVertical: 4,
            fontSize: fs,
          }}>
          Termination of Use We reserve the right to terminate your use of the
          TRAQ app at any time without prior notice if we determine that you
          have violated these terms and conditions. Contact Information If you
          have any questions or concerns about these terms and conditions,
          please contact us at info@traq.app / 081 250 2054
        </Text>
      </View>
    );
  }
}
