import React, { Component } from "react";
import { View, Text, TextInput, Image } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

export default class EmailInput extends Component {
	constructor(props) {
		super(props);
		this.state = { emailValid: false, email: this.props.emailSet };
	}

	componentDidMount() {
		this.setState({
			email: this.props.emailSet,
			emailValid: this.props.emailSet !== "",
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.emailSet !== this.props.emailSet) {
			this.setState({
				email: this.props.emailSet,
				emailValid: this.props.emailSet !== "",
			});
		}
	}

	validateEmail = async (text, next) => {
		// await console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// await console.log("Email is Not Correct");
			await this.setState({ emailValid: false });
		} else {
			await this.setState({ emailValid: true });
			// await console.log("Email is Correct");
			return next();
		}
	};

	render() {
		return (
			<View
				style={{
					width: "100%",
					// padding: 10,
					// borderWidth: 0.3,
					// borderColor: APP_COLOURS.BLACK,
					borderRadius: 10,
					marginVertical: 5,
				}}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						marginBottom: 5,
						padding: 2,
					}}>
					<Text
						style={{
							color: APP_COLOURS.BLACK,
							fontSize: 13,
							color: "#FFF",
							opacity: 0.7,
						}}>
						Email
					</Text>
					<View
						style={{
							width: 20,
							height: 20,
							borderRadius: 7,
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: this.state.emailValid
								? APP_COLOURS.GREEN
								: "transparent",
						}}>
						<Image
							source={require("../assets/images/check.png")}
							style={{
								width: 11,
								height: 11,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>

				<View
					style={{
						backgroundColor: APP_COLOURS.BG3,
						// backgroundColor: "#FFF",

						width: "100%",
						borderRadius: 13,
						// borderWidth: 0.3,
						// borderColor: APP_COLOURS.BLACK,
						borderRadius: 10,
						// borderColor: this.props.backgroundColor,
						padding: 8,
					}}>
					<TextInput
						style={{
							flex: 1,
							paddingLeft: 10,
							padding: 12,
							fontSize: 16,
							borderRadius: 12,
							borderWidth: 0,
							// textAlign: "center",
							justifyContent: "center",
							alignItems: "center",
							color: "#FFF",
							fontWeight: this.state.emailValid ? "600" : "300",
							backgroundColor: APP_COLOURS.BG3,
							letterSpacing: 0.26,
						}}
						placeholderTextColor={APP_COLOURS.TEXTCOLOR}
						autoCapitalize='none'
						clearButtonMode='while-editing'
						autoCompleteType='email'
						autoCorrect={true}
						placeholder={`Type email`}
						value={this.state.email}
						onChangeText={async (text) => {
							await this.setState({
								email: text,
							});
							await this.validateEmail(text, () =>
								this.props.saveEmail(text),
							);
						}}
					/>
				</View>
			</View>
		);
	}
}
