import React, { Component } from "react";
import {
	View,
	Text,
	TextInput,
	TouchableOpacity,
	Button,
	ScrollView,
	Image,
} from "react-native";
import moment from "moment";
import { APP_COLOURS, screenWidth } from "../../../APP_VARS";

function generateUuid() {
	let uuid = "";
	const hexChars = "0123456789abcdef";
	for (let i = 0; i < 32; i++) {
		const randomIndex = Math.floor(Math.random() * hexChars.length);
		const char = hexChars.charAt(randomIndex);
		uuid += char;
		if (i === 7 || i === 11 || i === 15 || i === 19) {
			uuid += "-";
		}
	}
	return uuid;
}

function generateUuidWithTimestamp() {
	const uuid = generateUuid();
	const now = Date.now();
	const timestamp = now.toString(16);
	return `${timestamp}-${uuid}`;
}

export default class POPIAConcent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			conductedBy: "",
			addressDetails: "",
			address: "",
			acceptedDisclaimer: false,
			agreeToCollection: false,
			optInCommunication: false,
			dateCreated: moment().toISOString(),
			uuid: generateUuidWithTimestamp(),
			location: {
				latitude: null,
				longitude: null,
			},
		};
	}

	async componentDidMount() {}
	async getAsync() {
		// try {
		//   const id = 'Page2';
		//   const value = await AsyncStorage.getItem(`Survey_${id}`);
		//   if (value !== null) {
		//     let d = JSON.parse(value);
		//     await this.setState({
		//       ...d,
		//     });
		//   }
		// } catch (error) {
		//   console.log(error);
		// }
	}

	componentDidUpdate(prevProps) {}

	async saveData() {
		// try {
		//   const id = 'Page2'; // Generate a unique ID
		//   await AsyncStorage.setItem(`Survey_${id}`, JSON.stringify(this.state)); // Save the data to Async storage
		//   this.props.onNext();
		// } catch (error) {
		//   alert('Error saving data. Please try again.');
		// }
	}

	render() {
		let {
			conductedBy,
			address,
			acceptedDisclaimer,
			location,
			agreeToCollection,
			optInCommunication,
		} = this.state;

		console.log("conductedBy state", conductedBy);
		return (
			<View
				style={{
					flex: 1,
					// backgroundColor: APP_COLOURS.BACKGROUND,
					width: "100%",
					padding: 10,
				}}>
				<ScrollView>
					<Text
						style={{
							width: "100%",
							textAlign: "center",
							color: APP_COLOURS.TEXTCOLOR,
						}}>
						{moment().format("MMM DD, YYYY, HH:mm")}
					</Text>

					<View
						style={{
							flex: 1,
							padding: 20,
						}}>
						<Text
							style={{
								fontSize: 12,
								marginBottom: 10,
								opacity: 0.8,
								fontWeight: "300",
								color: APP_COLOURS.TEXTCOLOR,
								textAlign: "justify",
							}}>
							Privacy Disclaimer: We are committed to protecting
							your privacy. We will only use the information that
							we collect about you lawfully (in accordance with
							the Protection of Personal Information Act 4 of
							2013). The information we collect about you will be
							used to conduct a door-to-door community population
							audit for farm communities close to the mine. This
							will assist the mine to gain a deeper understanding
							of the challenges faced by the communities in order
							to design community empowerment initiatives better.
							The personal data that we will collect from you
							includes: Personal Details (Head of the Household):
							Full Name: ___ Surname: _____ Known as: _____
							Gender: _____ Nationality: ____ Date of Birth: ____
							Age: _____ Place of Birth: _ Contact No: ___ How
							long have you been living in this village? _ Do you
							have any type of disability? ______ if yes, please
							specify Languages spoken inside the household: __
							Combined Household Income Per Month: __ Marital
							Relationship: ___ Personal Details (Spouse): Full
							Name: ___ Surname: _____ Known as: _____ Gender:
							_____ Date of Birth: ____ Age: _____ Nationality:
							____ Place of Birth: _ Contact No: ___ How long have
							you been living in this village? _ Are you employed?
							Yes/ No What is your position: _ Type of contract?
							Full Time/ Fixed Term/ Temporary/ Seasonal/ Casual
							(informal) 1. What is the source of income in the
							household? (multiple mention) -Regular income
							(wages/salary) -Irregular Income (Piece Jobs)
							-Social Transfers: (grant (specify type), benefits
							to children, guardians) -Other: Please specify 2.
							What kind of skills/ Talents event if informal do
							people in the house have (eg. Maybe someone is good
							at sewing clothes, someone is good at electronics?,
							etc) 3. Do you have livestock a. YES b. NO 4. if YES
							IN Q3 what type and approximately how many? 5. What
							do you use the livestock for? (MULTIPLE MENTION)
							-Food Supply -Family Nutrition -Family Income -Asset
							Savings -Transport -Ritual Purposes and social
							status -Other, Please specify 6. Do you plant any
							vegetables/ fruits in the yard? Yes/ No 7. What do
							you plant? ___ 8. How many times a year do you
							‘harvest’ from the garden? ___ 1-2 times/ 3-4 times/
							6-8 times/ 8-12 times? 9. Who in the household takes
							care of the garden? ______ (multiple mention)
							-Mother -Grandmother -Father -Oldest Child -One of
							the children? 10. What do you usually use to heat/
							prepare meals? (multiple mention) -Electricity -Gas
							-Wood -Paraffin Stove -Charcoal -Other 11. Type of
							Dwelling (SINGLE mention) -Formal dwelling -Informal
							dwelling -Traditional Dwelling (mud house, reeds,
							clay, etc) -Other (tents, caravans): please specify
							12. What kind of sanitation system do you use:
							(single mention) -Flush toilets -Ventilated/
							Improved pit latrine -Pit latrine -Bucket System
						</Text>
						<Text
							style={{
								fontSize: 12,
								marginBottom: 10,
								opacity: 0.8,
								fontWeight: "300",
								color: APP_COLOURS.TEXTCOLOR,
								textAlign: "justify",
							}}>
							The above is a privacy disclaimer for a door-to-door
							community population audit for farm communities
							close to the mine. The purpose of the audit is to
							gain a deeper understanding of the challenges faced
							by the communities in order to design community
							empowerment initiatives better. The personal data
							that will be collected includes personal details of
							the head of the household and their spouse, such as
							full name, date of birth, nationality, contact
							information, marital status, and employment status.
							The survey also includes questions about household
							income, livestock, gardening, heating and meal
							preparation, type of dwelling, and sanitation
							system. The information collected will be used only
							for the purpose of the audit and in accordance with
							the Protection of Personal Information Act 4 of
							2013.
						</Text>
						{/* </ScrollView> */}
					</View>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							padding: 10,
							marginTop: 10,
						}}>
						<TouchableOpacity
							style={{
								alignItems: "center",
								width: "100%",
								flexDirection: "row",
							}}
							onPress={() =>
								this.setState({
									acceptedDisclaimer: !acceptedDisclaimer,
								})
							}>
							<View
								style={{
									width: 20,
									height: 20,
									borderRadius: 7,
									marginRight: 5,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: acceptedDisclaimer
										? "#000"
										: "transparent",
									borderWidth: acceptedDisclaimer ? 0 : 0.5,
									borderColor: "#7777",
								}}>
								<Image
									source={require("../../assets/images/check.png")}
									style={{
										width: 11,
										height: 11,
										resizeMode: "contain",
									}}
								/>
							</View>

							<Text
								style={{
									marginLeft: 8,
									color: APP_COLOURS.TEXTCOLOR,
								}}>
								I have read and accept the POPIA disclaimer
							</Text>
						</TouchableOpacity>
					</View>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							padding: 10,
						}}>
						<TouchableOpacity
							style={{
								alignItems: "center",
								width: "100%",
								flexDirection: "row",
							}}
							onPress={() =>
								this.setState({
									agreeToCollection: !agreeToCollection,
								})
							}>
							<View
								style={{
									width: 20,
									height: 20,
									borderRadius: 7,
									marginRight: 5,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: agreeToCollection
										? "#000"
										: "transparent",
									borderWidth: agreeToCollection ? 0 : 0.5,
									borderColor: "#7777",
								}}>
								<Image
									source={require("../../assets/images/check.png")}
									style={{
										width: 11,
										height: 11,
										resizeMode: "contain",
									}}
								/>
							</View>

							<Text
								style={{
									marginLeft: 8,
									color: APP_COLOURS.TEXTCOLOR,
								}}>
								I agree to the collection of my information
							</Text>
						</TouchableOpacity>
					</View>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							padding: 10,
							marginBottom: 50,
						}}>
						<TouchableOpacity
							style={{
								alignItems: "center",
								width: "100%",
								flexDirection: "row",
							}}
							onPress={() =>
								this.setState({
									optInCommunication: !optInCommunication,
								})
							}>
							<View
								style={{
									width: 20,
									height: 20,
									borderRadius: 7,
									marginRight: 5,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: optInCommunication
										? "#000"
										: "transparent",
									borderWidth: optInCommunication ? 0 : 0.5,
									borderColor: "#7777",
								}}>
								<Image
									source={require("../../assets/images/check.png")}
									style={{
										width: 11,
										height: 11,
										resizeMode: "contain",
									}}
								/>
							</View>

							<Text
								style={{
									marginLeft: 8,
									color: APP_COLOURS.TEXTCOLOR,
								}}>
								I agree to any further communication
							</Text>
						</TouchableOpacity>
					</View>

					{/* <Button
            title="Next"
            onPress={async () => {
              if (acceptedDisclaimer) {
                this.saveData();
              } else {
                alert('Please accept the disclaimer to continue');
              }
            }}
          /> */}
				</ScrollView>
			</View>
		);
	}
}
