import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	TouchableOpacity,
	ScrollView,
	LayoutAnimation,
	Image,
} from "react-native";
import AutoHeightImage from "../../AutoHeightImageComp";
// import { Actions } from "react-native-router-flux";
import { APP_COLOURS } from "../../../APP_VARS";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class Column extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	addToCart = (d) => {
		this.props.addToCart(d);
	};

	render() {
		let d = this.props.data;
		return (
			<View
				style={{
					// width: screenWidth / 2,
					width: "50%",

					paddingLeft: 5,
					paddingRight: 5,
					marginBottom: 10,
					shadowColor: "#999797",
					shadowOffset: {
						width: 0,
						height: 4,
					},
					shadowOpacity: 0.13,
					shadowRadius: 4.65,
					elevation: 8,
					borderRadius: 5,
				}}>
				<View
					style={{
						flex: 1,
						backgroundColor: "#FFF",
						borderRadius: 13,
						padding: 10,
					}}>
					<TouchableOpacity
						onPress={() => {
							// touchButtonSound();
							// Actions.BookPreview({
							//   data: d,
							// });
							// Actions.ProductPage({
							// 	data: d,
							// 	addToCart_: () => {
							// 		if (!this.props.isSearch) {
							// 			d.qty = 1;
							// 			this.addToCart(d);
							// 		}
							// 	},
							// 	inCart: this.props.incart,
							// });
						}}>
						<View
							style={{
								alignItems: "center",
								justifyContent: "center",
								borderWidth: 0.5,
								borderRadius: 20,
								borderColor: "#999797",

								padding: 10,
								overflow: "hidden",
								width: "100%",
								height: 220,
							}}>
							{typeof d.productUrl !== "undefined" ? (
								<AutoHeightImage
									width={120}
									source={{ uri: d.productUrl }}
								/>
							) : null}

							{typeof d.image !== "undefined" ? (
								<AutoHeightImage
									width={220}
									source={{ uri: d.image }}
								/>
							) : null}
						</View>
						<View
							style={{
								borderWidth: 1,
								borderRadius: 20,
								width: "100%",
								height: 220,
								position: "absolute",
								borderColor: "#999797",
								top: 0,
							}}
						/>
					</TouchableOpacity>
					<View
						style={{
							flex: 1,
							flexDirection: "column",
						}}>
						<View
							style={{
								flex: 1,
								justifyContent: "space-between",
								alignItems: "flex-end",
								marginBottom: 20,
								marginTop: 10,
							}}>
							<View
								style={{
									flex: 1,
									flexDirection: "column",
									width: "100%",
									paddingLeft: 5,
								}}>
								<TouchableOpacity
									onPress={() => {
										// touchButtonSound();
										// Actions.ProductPage({
										// 	data: d,
										// 	addToCart_: () => {
										// 		if (!this.props.isSearch) {
										// 			d.qty = 1;
										// 			this.addToCart(d);
										// 		}
										// 	},
										// 	inCart: this.props.incart,
										// });
									}}>
									<Text
										style={{
											fontWeight: "500",
											fontSize: 15,
										}}>
										{d.name}
									</Text>
									{/* <Text
                    ellipsizeMode="tail"
                    style={{fontWeight: '300', fontSize: 10}}>
                    {d.description.slice(0, 100)}
                  </Text> */}

									<Text
										style={{
											fontSize: 10,
											opacity: 0.8,
											marginBottom: 2,
											fontStyle: "italic",
											marginTop: 10,
										}}>
										{d.barcode === ""
											? "no barcode"
											: d.barcode}
									</Text>

									<Text
										style={{
											fontSize: 10,
											opacity: 0.8,
											marginBottom: 2,
										}}>
										{d.category}
									</Text>
									<Text
										style={{
											fontSize: this.props.purchased
												? 10
												: 16,
											marginTop: 10,
											fontWeight: "600",
											fontStyle: this.props.purchased
												? "italic"
												: "normal",
											color: this.props.purchased
												? APP_COLOURS.GREEN
												: APP_COLOURS.TEXTCOLOR,
										}}>
										{this.props.purchased
											? "Purchased"
											: currencyFormat(
													parseFloat(d.price),
											  )}
									</Text>
								</TouchableOpacity>
							</View>
						</View>
						{this.props.isSearch ? null : this.props.incart ? (
							<TouchableOpacity
								style={{
									backgroundColor: "#FFF",
									borderRadius: 8,
									padding: 5,
									display: this.props.purchased
										? "none"
										: "flex",
								}}
								onPress={() => {
									// touchButtonSound();
									this.addToCart(d);
								}}>
								<View
									style={{
										alignItems: "center",
										justifyContent: "center",
										backgroundColor: APP_COLOURS.GREEN,
										borderRadius: 5,
										padding: 5,
										width: "100%",
									}}>
									<AutoHeightImage
										width={17}
										source={require("./../../assets/images/cart2.png")}
									/>
								</View>

								<View
									style={{
										alignItems: "center",
										justifyContent: "center",
										// backgroundColor: APP_COLOURS.GREEN,
										borderRadius: 5,
										padding: 5,
										width: "100%",
									}}>
									<Text
										style={{
											fontWeight: "400",
											fontSize: 9,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										- remove from cart
									</Text>
								</View>
							</TouchableOpacity>
						) : (
							<TouchableOpacity
								onPress={() => {
									// touchButtonSound();

									d.qty = 1;
									this.addToCart(d);
								}}>
								<View
									style={{
										alignItems: "center",
										justifyContent: "center",
										backgroundColor: "#000",
										borderRadius: 5,
										padding: 5,
										width: "100%",
										display: this.props.purchased
											? "none"
											: "flex",
									}}>
									<Text
										adjustsFontSizeToFit
										style={{
											fontWeight: "400",
											fontSize: 9,
											color: "#FFF",
										}}>
										+ Add to cart
									</Text>
								</View>
							</TouchableOpacity>
						)}
					</View>
				</View>
			</View>
		);
	}
}

export default Column;
