import React, { Component, useEffect, useState } from "react";
import {
	StyleSheet,
	ActivityIndicator,
	Text,
	TouchableOpacity,
	View,
	ScrollView,
	Image,
	TextInput,
} from "react-native";
import { useParams } from "react-router-dom";
import AutoHeightImage from "../AutoHeightImageComp";
import { APP_COLOURS } from "../../APP_VARS";
import ProductSearchCart from "../NewSale/ProductsCart";

class ProductSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundcolour: APP_COLOURS.BG2,
			fontcolour: APP_COLOURS.DESELECTEDCOLOR,
			data: [],
			loading: true,
			height: window.innerHeight,
			width: window.innerWidth,
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData = async () => {
		this.setState({
			data: [],
			loading: false,
		});
	};

	render() {
		const { data, loading } = this.state;
		let backgroundcolour = this.state.backgroundcolour;

		return (
			<View
				style={{
					flex: 1,
					// backgroundColor: backgroundcolour,
					backgroundColor: APP_COLOURS.BG,
					height: window.innerHeight,
					width: window.innerWidth,
					overflow: "hidden",
				}}>
				<ProductSearchCart isSearch={true} />
			</View>
		);
	}
}

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		const params = useParams();
		const { user_id, token } = params;
		useEffect(() => {
			const fetchUserData = async (token) => {
				try {
					const response = await fetch(
						`https://workflow.tetrice.co.za/webhook/79d646ab-dd9b-4410-a905-62b6694f65c8`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`, // Set Bearer token in the Authorization header
							},
							body: JSON.stringify({
								user_id: user_id,
							}),
						},
					);

					if (!response.ok) {
						throw new Error("Failed to fetch user data");
					}

					const userData = await response.json();

					// Set user data in localStorage
					localStorage.setItem("user_data", JSON.stringify(userData));
				} catch (error) {
					console.error("Error fetching user data:", error);
				}
			};

			if (user_id && token) {
				localStorage.setItem("user_id", user_id);
				localStorage.setItem("accessToken", token);

				// Fetch user data using the Bearer token
				fetchUserData(token);
			}
		}, [params]);

		return <Component {...props} params={params} user_id={user_id} />;
	}

	return ComponentWithRouterProp;
}
export default withRouter(ProductSearch);
