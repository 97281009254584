import React, { Component } from "react";
import {
	StyleSheet,
	Keyboard,
	Text,
	Image,
	TouchableOpacity,
	SafeAreaView,
	ScrollView,
	View,
	Alert,
	ActivityIndicator,
	TextInput,
	Modal,
	Dimensions,
} from "react-native";
import AsyncStorage from "../../AsyncStorage";
// import Webview from './AOffline';
// import AutoHeightImage from '../AutoHeightImage';
// import {RNCamera} from 'react-native-camera';
// import {BarcodeMask} from 'react-native-barcode-mask';
// import BarcodeScanner from './BarcodeScanner/Index';
// import MaskedView from '@react-native-community/masked-view';
// import BluetoothProduct from './BluetoothProduct';
import { APP_COLOURS, Avatars, screenHeight } from "../../APP_VARS";
// import PaymentProcess from "./PaymentProcess/index";
// import MultiSelect from './Buttons/MultiSelect/index';

import Line from "./ProductItem/Line";
import Square from "./ProductItem/Square";
// import {touchButtonSound} from './TouchButtonSound';
import {
	crudCreate,
	crudCreatSale,
	crudGetProducts,
	crudGetSheetProducts,
	crudRead,
	crudReadAll,
	crudReadUnass,
	crudUpdateSaleAttandance,
} from "../../CRUDHELPER";
import moment from "moment";

// import products_ from './Products/products.json';
import AutoHeightImage from "../AutoHeightImageComp";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
function generateUuid() {
	let uuid = "";
	const hexChars = "0123456789abcdef";
	for (let i = 0; i < 32; i++) {
		const randomIndex = Math.floor(Math.random() * hexChars.length);
		const char = hexChars.charAt(randomIndex);
		uuid += char;
		if (i === 7 || i === 11 || i === 15 || i === 19) {
			uuid += "-";
		}
	}
	return uuid;
}

function onlyUnique(value, index, array) {
	return array.indexOf(value) === index;
}

function generateUuidWithTimestamp() {
	const uuid = generateUuid();
	const now = Date.now();
	const timestamp = now.toString(16);
	return `${timestamp}-${uuid}`;
}

const device = {
	width: Dimensions.get("window").width,
	height: Dimensions.get("window").height,
};

export default class ProductSearchCart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			products: [],
			jwt: "",
			sale_id: generateUuidWithTimestamp(),

			jobTypeOptions: ["Elizabeth Arden"],
			jobTypes: ["Elizabeth Arden"],

			loading: true,

			cartItems: [],
			orientation: "column",
			scanBarcodeModal: false,

			showSaleCompleted: false,

			organisationID: "",

			prods: [
				{
					image: "https://assets.superbalistcdn.co.za/200x200/filters:quality(25):format(jpg)/1794122/original.jpg",
					subCategory: "skincare",
					base64String: "",
					date_created: "Apr 04, 2024 13:36:24",
					autoID: "GhjmnV8r8iv3wljg2EIB",
					externalId: 589508,
					source: "superbalist",
					title: "",
					uuid: "f4f2-4b233-f516f",
					price: "299.00",
					name: "Eight Hour® Cream Lip Protectant Stick SPF 15",
					department: "women",
					category: "beauty",
					productUrl:
						"https://superbalist.com/women/beauty/skincare/eight-hour-cream-lip-protectant-stick-spf-15/589508",
					brand: "Elizabeth Arden",
					organisationID: "revlon981723423",
					id: "GhjmnV8r8iv3wljg2EIB",
				},
			],
		};
	}

	componentDidMount() {
		this.getProducts();
		this.getDeviceData();

		this.setState({
			customerData: this.props.customerData,
		});

		console.log(this.props);
	}

	getDeviceData = async () => {
		try {
			const value = await localStorage.getItem("user_data");
			let parse = JSON.parse(value);
			if (value !== null) {
				this.setState({
					user_data: parse,
				});
				console.log("user_data getDeviceData", parse);
			} else {
			}
		} catch (error) {
			return "";
		}
	};

	getProducts = async () => {
		this.setState({
			loading: true,
		});

		// this.setState({
		//   loading: false,
		//   products: products_,
		// });
		// console.log(this.state.user_data);
		// return;
		let obj = {};
		crudGetSheetProducts(obj, (status, d) => {
			if (status === 200) {
				this.setState({
					loading: false,
					products: d,
				});
				this.onValueChange("products", JSON.stringify(d));
				console.log(JSON.stringify(d));
			} else {
				this.setState({
					loading: false,
					error: true,
				});
				alert("Error loading products");
			}
		});
		// crudGetProducts((status, d) => {
		// 	if (status === 200) {
		// 		this.setState({
		// 			loading: false,
		// 			products: d,
		// 		});
		// 		this.onValueChange("products", JSON.stringify(d));
		// 		console.log(JSON.stringify(d));
		// 	} else {
		// 		this.setState({
		// 			loading: false,
		// 			error: true,
		// 		});
		// 		alert("Error loading products");
		// 	}
		// });

		// crudReadUnass(
		// 	"products",
		// 	{
		// 		field: "organisationID",
		// 		action: "==",
		// 		value: this.state.organisationID,
		// 	},
		// 	(status, d) => {
		// 		console.log("getProducts");
		// 		if (status === 200) {
		// 			this.setState({
		// 				loading: false,
		// 				products: d,
		// 			});
		// 			this.onValueChange("products", JSON.stringify(d));
		// 			console.log(JSON.stringify(d));
		// 		} else {
		// 			this.setState({
		// 				loading: false,
		// 				error: true,
		// 			});
		// 			alert("Error loading products");
		// 		}
		// 	},
		// );
	};

	componentDidUpdate(prevProps) {
		if (prevProps.customerData !== this.props.customerData) {
			this.setState({
				customerData: this.props.customerData,
			});
		}
	}

	render() {
		let fontcolour = this.state.fontcolour;
		let screenWidth = device.width > 550 ? 550 : device.width;

		let products = this.state.products
			.sort((a, b) => a.name > b.name)

			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.searchBarValue !== "undefined" &&
					this.state.searchBarValue !== ""
				) {
					let text_ = this.state.searchBarValue;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.name}`;

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			});
		// .slice(0, 100);

		return (
			<View
				style={{
					flex: 1,
					// backgroundColor: 'red',
					width: "100%",
					// padding: 5,
					overflow: "hidden",
				}}>
				<View
					style={{
						width: "100%",
						// flexDirection: 'row',
						justifyContent: "space-between",
						alignItems: "flex-start",

						// backgroundColor: 'red',

						borderBottomWidth: 0.5,
						borderBottomColor: APP_COLOURS.BG4,
						// display: 'none',
					}}>
					<View
						style={{
							marginRight: 5,
							flexDirection: "row",
							alignItems: "center",

							padding: 5,
							width: "100%",
						}}>
						<View
							style={{
								flex: 1,
							}}>
							<Text
								style={{
									flex: 1,
									fontSize: 25,
									fontWeight: "800",
									color: "#FFF",
								}}>
								{this.props.isSearch ? "Products" : "Cart"}
							</Text>

							{/* <Text
								allowFontScaling={false}
								style={{
									fontSize: 10,
									fontWeight: "600",
									color: "#FFF",
									opacity: 0.4,
								}}>
								{products.length}
							</Text> */}
						</View>

						<View
							style={{
								// width: '100%',
								// flex: 1,
								flexDirection: "row",
								alignItems: "center",
								// justifyContent: 'flex-start',

								padding: 2,
							}}>
							<View
								style={{
									// flex: 1,
									// width: 200,
									borderRadius: 13,
									// borderWidth: 0.5,
									borderRadius: 10,
									overflow: "hidden",
								}}>
								<TextInput
									allowFontScaling={false}
									style={{
										padding: 14,
										fontSize: 16,
										fontWeight: "300",
										color: "#FFF",
										width: 200,
										backgroundColor: APP_COLOURS.BG3,
									}}
									placeholder=' Search'
									maxLength={200}
									numberOfLines={1}
									clearButtonMode='always'
									placeholderTextColor='#E8E8E8'
									onChangeText={(text) => {
										this.setState({
											searchBarValue: text,
										});
									}}
									onFocus={async () => {
										// this.scrollref.scrollTo({
										//   y: 160,
										//   animated: true,
										// });
									}}
									value={this.state.searchBarValue}
									onBlur={async () => {}}
									keyboardType='ascii-capable'
								/>
							</View>
						</View>
						<TouchableOpacity
							style={{
								alignItems: "center",
								justifyContent: "center",
								backgroundColor: "white",
								width: 50,
								height: 50,
								borderRadius: 10,
								overflow: "hidden",
								marginRight: 5,
								opacity: 0.2,
							}}
							onPress={() => {
								this.setState({ scanBarcodeModal: true });
							}}>
							<View
								style={{
									alignItems: "center",
									justifyContent: "center",
									backgroundColor: "white",
									width: 50,
									height: 50,
								}}>
								<Image
									source={require("./../assets/images/barcode_scanner.png")}
									style={{
										resizeMode: "contain",
										width: 35,
										height: 35,
									}}
								/>
							</View>
						</TouchableOpacity>
						{/* <TouchableOpacity
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
								padding: 3,
							}}
							onPress={() =>
								this.setState({
									orientation:
										this.state.orientation == "row"
											? "column"
											: "row",
								})
							}>
							<View
								style={{
									justifyContent: "center",
									alignItems: "center",
									padding: 3,
								}}>
								{this.state.orientation == "row" ? (
									<AutoHeightImage
										width={23}
										source={require("./../assets/images/line.png")}
									/>
								) : (
									<AutoHeightImage
										width={23}
										source={require("./../assets/images/square.png")}
									/>
								)}
							</View>
						</TouchableOpacity> */}
					</View>
					{/* <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              width: '100%',
              // flex: 1,
            }}>
            <View
              style={{
                width: screenWidth * 0.45,
                padding: 10,
                borderRadius: 13,
                borderWidth: 0.5,
                borderRadius: 10,
                // borderColor: this.props.backgroundColor,
                marginBottom: 5,
              }}>
              <MultiSelect
                title={'Brands'}
                options={this.state.jobTypeOptions}
                defaultValue={this.state.jobTypes}
                color={APP_COLOURS.BLUE2}
                selected={d => {
                  this.setState({
                    jobTypes: d,
                    changesMade: true,
                    updates: {
                      ...this.state.updates,
                      jobTypes: d,
                    },
                  });
                }}
              />
            </View>
            <View
              style={{
                width: screenWidth * 0.45,
                padding: 10,
                borderRadius: 13,
                borderWidth: 0.5,
                borderRadius: 10,
                // borderColor: this.props.backgroundColor,
                marginBottom: 5,
              }}>
              <MultiSelect
                title={'Categories'}
                options={['Skincare', 'Hair', 'Makeup', 'Fragrance']}
                defaultValue={[]}
                color={APP_COLOURS.BLUE2}
                selected={d => {
                  this.setState({
                    jobTypes: d,
                    changesMade: true,
                    updates: {
                      ...this.state.updates,
                      jobTypes: d,
                    },
                  });
                }}
              />
            </View>
          </View> */}
				</View>

				<View
					style={{
						flex: 1,
						opacity: this.state.showCart ? 0.1 : 1,
					}}>
					<ScrollView onScrollBeginDrag={Keyboard.dismiss}>
						<View
							style={{
								height: 20,
							}}
						/>
						<View
							style={{
								flex: 1,
							}}>
							{this.state.loading ||
							this.state.loadingPurchases ? (
								<View
									style={{
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
									}}>
									<ActivityIndicator />
								</View>
							) : null}

							<View
								style={{
									flex: 1,
									flexDirection: this.state.orientation,
									flexWrap: "wrap",
									display: this.state.loadingPurchases
										? "none"
										: "flex",
									padding: 3,
								}}>
								{products.map((d, i) => {
									let id = d.id;
									let dataa = this.state.cartItems.filter(
										(cart) => cart.id == id,
									);

									let incart = dataa.length > 0;
									if (this.state.orientation == "row") {
										return (
											<Square
												key={i}
												data={d}
												addToCart={(d) =>
													this.addToCart(d)
												}
												incart={incart}
												purchased={false}
												isSearch={this.props.isSearch}
											/>
										);
									} else {
										return (
											<Line
												key={i}
												data={d}
												addToCart={(d) =>
													this.addToCart(d)
												}
												incart={incart}
												purchased={false}
												isSearch={this.props.isSearch}
											/>
										);
									}
								})}
							</View>
							<View
								style={{
									height: 90,
								}}
							/>
						</View>
					</ScrollView>
				</View>
				{/* {this.state.cartItems.length > 0 ? (
					<View
						style={{
							height: 70,
						}}
					/>
				) : null} */}

				{this.state.cartItems.length > 0 ? (
					<View
						style={{
							width: window.innerWidth,
							height: window.innerHeight - 180,
							backgroundColor: "#FFF",
							// backgroundColor: APP_COLOURS.BG4,

							borderRadius: 20,
							marginBottom: 20,
							zIndex: 99,
							position: "absolute",
							bottom: this.state.showCart
								? 0
								: -window.innerHeight + 230,

							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: -3,
							},
							shadowOpacity: 0.3,
							shadowRadius: 4.65,
							elevation: 10,
						}}>
						<TouchableOpacity
							activeOpacity={0.89}
							onPress={() =>
								this.setState({
									showCart: !this.state.showCart,
								})
							}>
							<View
								style={{
									width: "100%",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									padding: 10,
									paddingLeft: 25,
									paddingRight: 25,
									paddingBottom: this.state.showCart
										? 10
										: 40,
									borderRadius: 20,
									backgroundColor: APP_COLOURS.BLUE2,
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<AutoHeightImage
										width={19}
										source={require("./../assets/images/cart2.png")}
									/>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 19,
											fontWeight: "600",
											color: "#FFF",
											marginLeft: 10,
										}}>
										Cart
									</Text>
								</View>

								<View>
									{this.state.showCart ? (
										<AutoHeightImage
											width={22}
											source={require("./../assets/images/arrowUp.png")}
										/>
									) : null}
								</View>
								<View
									style={{
										// padding: 10,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 8,
										backgroundColor: "#FFF",
										width: 40,
										height: 40,
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 19,
											fontWeight: "700",
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										{this.state.cartItems.length}
									</Text>
								</View>
							</View>
							<View
								style={{
									width: "100%",
									backgroundColor: "#FFF",
									padding: 10,
									flexDirection: "row",
									justifyContent: "space-between",
									borderBottomWidth: 0.5,
									borderBottomColor: "#999797",
									marginTop: 10,
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 14,
										fontWeight: "300",
										// color: '#FFF',
									}}>
									Cart total
								</Text>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 16,
										fontWeight: "400",
										// color: '#FFF',
									}}>
									{currencyFormat(
										this.state.cartItems.reduce(
											(a, v) =>
												(a =
													a +
													parseFloat(v.price) *
														parseFloat(v.qty)),
											0,
										),
									)}
								</Text>
							</View>
						</TouchableOpacity>
						<ScrollView
							endFillColor={APP_COLOURS.BACKGROUND_FADE}
							style={{
								backgroundColor: APP_COLOURS.BACKGROUND_FADE,
							}}>
							<View
								style={{
									width: "100%",
									borderBottomWidth: 0.5,
									borderBottomColor: "#E8E8E8",

									backgroundColor:
										APP_COLOURS.BACKGROUND_FADE,
								}}>
								{this.state.cartItems
									.sort(
										(a, b) =>
											parseFloat(b.price) -
											parseFloat(a.price),
									)

									.map((d, i) => {
										return (
											<View
												key={i}
												style={{
													flex: 1,
													// justifyContent: 'space-between',
													backgroundColor: "#FFF",
													alignItems: "flex-start",
													// flexDirection: 'row',
													borderTopWidth: 0.5,
													borderTopColor: "#E8E8E8",
													paddingTop: 5,
													padding: 10,
													marginTop: 5,
													shadowColor: "#999797",
													shadowOffset: {
														width: 0,
														height: 4,
													},
													shadowOpacity: 0.3,
													shadowRadius: 4.65,
													elevation: 8,
												}}>
												<View
													style={{
														flex: 1,
														width: "100%",
														justifyContent:
															"space-between",
														flexDirection: "row",
													}}>
													<TouchableOpacity>
														<View
															style={{
																marginRight: 5,
																borderWidth: 0.5,
																borderColor:
																	"#E8E8E8",
																padding: 5,
																borderRadius: 10,

																alignItems:
																	"center",
																justifyContent:
																	"center",
																overflow:
																	"hidden",
															}}>
															{typeof d.productUrl !==
															"undefined" ? (
																<AutoHeightImage
																	width={40}
																	source={{
																		uri: d.productUrl,
																	}}
																/>
															) : null}

															{typeof d.image !==
															"undefined" ? (
																<AutoHeightImage
																	width={40}
																	source={{
																		uri: d.image,
																	}}
																/>
															) : null}
														</View>
													</TouchableOpacity>

													<View
														style={{
															flexDirection:
																"row",
															justifyContent:
																"center",
															alignItems:
																"center",
															flex: 1,
														}}>
														<TouchableOpacity
															onPress={() => {
																d.qty =
																	d.qty - 1;

																if (
																	d.qty === 0
																) {
																	this.addToCart(
																		d,
																	);
																} else {
																	let rem =
																		this.state.cartItems.filter(
																			(
																				d_,
																			) =>
																				d_.id !==
																				d.id,
																		);
																	rem.push(d);

																	this.setState(
																		{
																			cartItems:
																				rem,
																		},
																	);
																}
															}}>
															<Image
																source={require("./../assets/images/minus.png")}
																style={{
																	height: 30,
																	width: 30,
																	resizeMode:
																		"contain",
																}}
															/>
														</TouchableOpacity>

														<Text
															style={{
																fontSize: 20,
																fontWeight:
																	"500",
																flexWrap:
																	"wrap",
																margin: 16,
															}}>
															{d.qty}
														</Text>

														<TouchableOpacity
															onPress={() => {
																d.qty =
																	d.qty + 1;

																let rem =
																	this.state.cartItems.filter(
																		(d_) =>
																			d_.id !==
																			d.id,
																	);
																rem.push(d);

																this.setState({
																	cartItems:
																		rem,
																});
															}}>
															<Image
																source={require("./../assets/images/plus.png")}
																style={{
																	height: 30,
																	width: 30,
																	resizeMode:
																		"contain",
																}}
															/>
														</TouchableOpacity>
													</View>
													<View
														style={{
															alignItems:
																"flex-end",
															justifyContent:
																"center",
														}}>
														<Text
															style={{
																fontSize: 16,
																marginBottom: 10,
																fontWeight:
																	"600",
															}}>
															{currencyFormat(
																parseFloat(
																	d.price,
																),
															)}
														</Text>
														{/* <TouchableOpacity onPress={() => this.addToCart(d)}>
                              <View
                                style={{
                                  width: 40,
                                  height: 40,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <Text
                                  style={{
                                    fontSize: 8,
                                    opacity: 0.8,
                                  }}>
                                  remove
                                </Text>
                                <AutoHeightImage
                                  width={15}
                                  source={require('./../assets/images/delete.png')}
                                />
                              </View>
                            </TouchableOpacity> */}
													</View>
												</View>

												<View
													style={{
														flex: 1,
														flexDirection: "column",
														width: "100%",
														paddingLeft: 5,
														padding: 5,
													}}>
													<TouchableOpacity
														onPress={() => {
															// Actions.BookPreview({
															//   data: d,
															// });
														}}>
														<Text
															style={{
																fontWeight:
																	"400",
																fontSize: 14,
															}}>
															{d.name}
														</Text>
														<Text
															style={{
																fontSize: 10,
																opacity: 0.8,
																marginBottom: 2,
																fontStyle:
																	"italic",
															}}>
															{d.barcode}
														</Text>
														{/* <Text
                              style={{
                                fontSize: 10,
                                opacity: 0.8,
                                marginBottom: 2,
                              }}>
                              {d.category}
                            </Text> */}
													</TouchableOpacity>
												</View>
											</View>
										);
									})}
							</View>
							{/* <View
                  style={{
                    // bottom: 0,
                    height: 200,
                    width: '100%',
                    backgroundColor: 'red',
                  }}></View> */}
						</ScrollView>
						<View
							style={{
								backgroundColor: "#FFF",
								borderTopWidth: 0.5,
								borderTopColor: "#E8E8E8",
								paddingTop: 10,
								shadowColor: "#797979",
								shadowOffset: {
									width: 0,
									height: -5,
								},
								shadowOpacity: 0.1,
								shadowRadius: 4.65,
								elevation: 10,
								paddingBottom: 20,
							}}>
							<TouchableOpacity
								disabled={this.state.loading}
								style={{
									alignItems: "center",
									justifyContent: "center",
								}}
								onPress={() => {
									this.checkout();
								}}>
								{this.state.loading ? (
									<View
										style={{
											marginLeft: 10,
											height: "auto",
											padding: 10,
											alignItems: "center",
											justifyContent: "center",
											flexDirection: "row",
											marginBottom: 27,
											backgroundColor: APP_COLOURS.GREEN,
											borderRadius: 5,
										}}>
										<ActivityIndicator color='#FFF' />
									</View>
								) : (
									<View
										style={{
											width: screenWidth - 20,
											marginLeft: 10,
											height: "auto",
											padding: 10,
											alignItems: "center",
											justifyContent: "center",
											flexDirection: "row",
											marginBottom: 27,
											// backgroundColor: APP_COLOURS.BLUE,
											backgroundColor: APP_COLOURS.GREEN,
											borderRadius: 5,
										}}>
										<AutoHeightImage
											width={15}
											source={require("./../assets/images/cart2.png")}
										/>
										<Text
											style={{
												color: "#FFF",
												fontSize: 17,
												fontWeight: "500",
												marginLeft: 10,
											}}>
											{" "}
											Checkout
										</Text>
									</View>
								)}
							</TouchableOpacity>
						</View>
					</View>
				) : null}

				{/* {this.state.checkOutModal ? (
					<View
						style={{
							flex: 1,
							position: "absolute",
							zIndex: 100,
							backgroundColor: "rgba(52, 52, 52, 0.8)",
							width: screenWidth,
							height: screenHeight,
						}}>
						<PaymentProcess
							cancelPayment={() => this.closePaymentProcess()}
							purchaseData={this.state.purchaseData}
							grandTotal={this.state.grandTotal}
							accessToken={this.state.accessToken}
						/>
					</View>
				) : (
					<View />
				)} */}
				{this.state.scanBarcodeModal ? (
					<Modal
						animationType={"fade"}
						transparent={true}
						visible={this.state.scanBarcodeModal}
						onRequestClose={() => {
							console.log("samplesModal has been closed.");
							this.setState({
								scanBarcodeModal: false,
							});
						}}>
						<View
							style={{
								width: "100%",
								alignItems: "center",
							}}>
							<View
								style={{
									justifyContent: "center",
									alignItems: "center",
									backgroundColor:
										APP_COLOURS.WHITE_TRANSPARENT2,
									height: screenWidth,
									width: screenWidth,

									maxHeight: 400,
									maxWidth: 400,
									borderRadius: 35,
									// borderWidth: 1,
									// borderColor: '#fff',
									marginTop: screenHeight * 0.25,
									marginLeft: "auto",
									marginRight: "auto",
									maxWidth: 487,
									maxHeight: 385,
									padding: 15,

									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 10,
									},
									shadowOpacity: 0.23,
									shadowRadius: 13.97,
									elevation: 21,
								}}>
								{/* <MaskedView
                  maskElement={
                    <View
                      style={{
                        height: screenWidth - 20,
                        width: screenWidth - 20,
                        borderRadius: 35,
                        backgroundColor: 'white',
                        maxWidth: 420,
                        maxHeight: 420,
                      }}
                    />
                  }>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Text
                      style={{
                        fontWeight: '500',
                        fontSize: 17,
                        // color: APP_COLOURS.RED,
                      }}>
                      Barcode scanner not enabled
                    </Text>
                  </View>
                  <View style={{justifyContent: 'center', alignItems: 'center'}}>
                  <BarcodeScanner
                    width={screenWidth - 40}
                    height={screenWidth - 40}
                    maxWidth={400}
                    maxHeight={400}
                    borderRadius={35}
                    padding={0}
                    qrcode={this.qrScanned} // data from child
                  />
                </View>
                </MaskedView> */}

								<Image
									source={require("./../assets/images/qrscanner.png")}
									style={{
										resizeMode: "contain",
										height: screenWidth - 40,
										width: screenWidth - 40,
										position: "absolute",
										top: 0,
									}}
								/>

								{/* <TouchableOpacity
                  onPress={() => {
                    this.qrScanned('23412341234');
                  }}>
                  <Image
                    source={require('./../assets/images/plus_sample.png')}
                    style={{resizeMode: 'contain', width: 39, height: 39}}
                  />
                </TouchableOpacity> */}

								<TouchableOpacity
									style={{
										width: 25,
										height: 25,
										position: "absolute",
										top: 16,
										right: 30,
									}}
									onPress={() => {
										this.setState({
											scanBarcodeModal: false,
										});
									}}>
									<Image
										source={require("./../assets/images/close.png")}
										style={{
											resizeMode: "contain",
											width: 25,
											height: 25,
										}}
									/>
								</TouchableOpacity>
							</View>
						</View>
					</Modal>
				) : (
					<View />
				)}
				{this.state.showSaleCompleted ? (
					<Modal
						animationType={"fade"}
						transparent={true}
						visible={true}
						onRequestClose={() => {
							this.setState({
								showSaleCompleted: false,
							});
						}}>
						<View
							style={{
								alignItems: "center",
								backgroundColor: APP_COLOURS.BG1,
								height: window.innerHeight,
								width: window.innerWidth,
								padding: 5,
							}}>
							<View
								style={{
									flex: 1,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#fff",
									width: "100%",

									borderRadius: 35,
									// borderWidth: 1,
									// borderColor: '#fff',
									marginTop: screenHeight * 0.25,
									marginLeft: "auto",
									marginRight: "auto",
									maxWidth: 487,
									maxHeight: 385,
									padding: 15,

									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 10,
									},
									shadowOpacity: 0.23,
									shadowRadius: 13.97,
									elevation: 21,
								}}>
								<View
									style={{
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Text
										style={{
											fontWeight: "600",
											color: APP_COLOURS.BG2,
											fontSize: 25,
											marginBottom: 20,
										}}>
										Sale captured
									</Text>

									<AutoHeightImage
										width={50}
										source={require("../assets/images/tickRound.png")}
									/>

									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												fontWeight: "600",
												color: APP_COLOURS.BG2,
												fontSize: 25,
												marginBottom: 20,
											}}>
											IPT: {this.state.salesData?.ipt}
										</Text>
										<Text
											style={{
												fontWeight: "600",
												color: APP_COLOURS.BG2,
												fontSize: 25,
												marginBottom: 20,
											}}>
											ARS:{" "}
											{currencyFormat(
												parseFloat(
													this.state.salesData?.ars,
												),
											)}
										</Text>
										<Text
											style={{
												fontWeight: "600",
												color: APP_COLOURS.BG2,
												fontSize: 25,
												marginBottom: 20,
											}}>
											TRS:{" "}
											{currencyFormat(
												parseFloat(
													this.state.salesData?.trs,
												),
											)}
										</Text>
									</View>
								</View>

								<TouchableOpacity
									style={{
										width: "100%",
									}}
									onPress={async () => {
										this.setState({
											showSaleCompleted: false,
										});
										// Actions.replace("Timeline");
										if (
											typeof window.ReactNativeWebView !==
											"undefined"
										) {
											window.ReactNativeWebView.postMessage(
												"closeWebView",
											);
										} else {
											window.location.href = "";
										}
									}}>
									<View
										style={{
											width: "100%",
											padding: 10,
											borderRadius: 15,
											backgroundColor: APP_COLOURS.BLACK,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												color: "#FFF",
												fontWeight: "300",
												fontSize: 19,
											}}>
											Done
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>
					</Modal>
				) : (
					<View />
				)}
			</View>
		);
	}

	checkout = () => {
		// console.log(this.state.user_data);
		// return;
		this.setState({
			loading: true,
		});
		let items = this.state.cartItems;
		let cus = this.state.customerData;
		// console.log(this.state.customerData);
		// let customerSalesData = {};

		let ipt = this.state.cartItems.reduce(
			(a, v) => (a = a + parseFloat(v.qty)),
			0,
		);

		let ars = this.state.cartItems.reduce(
			(a, v) => (a = a + parseFloat(v.price) * parseFloat(v.qty)),
			0,
		);

		ars = ars / ipt;

		let trs = this.state.cartItems.reduce(
			(a, v) => (a = a + parseFloat(v.price) * parseFloat(v.qty)),
			0,
		);

		let categories = this.state.cartItems
			.map((d) => d.category)
			.filter(onlyUnique);
		let brands = this.state.cartItems
			.map((d) => d.brand)
			.filter(onlyUnique);
		let departments = this.state.cartItems
			.map((d) => d.department)
			.filter(onlyUnique);
		let subCategories = this.state.cartItems
			.map((d) => d.subCategory)
			.filter(onlyUnique);

		let customerData = this.props.customerData;

		delete customerData.sales_history;

		let salesData = {
			sale_id: this.state.sale_id,
			dateCreated: moment().toLocaleString(),
			date: moment().toISOString(),
			dateFilter: `_${moment().format("YYYYMMDD")}`,
			timeFilter: `_${moment().format("HH:mm")}`,
			items: items,
			ipt,
			ars: ars.toFixed(2),
			trs: trs.toFixed(2),
			categories,
			brands,
			departments,
			subCategories,
			newCustomer: this.props.isnewCustomer,
			customer_id: cus.id,
			staff_id: this.props.user_id,
			retailer: this.state.retailer,
			mall: this.state.mall,
			customer_gender: cus.gender,
			customer_nationality: cus.nationality,
			customer_ethnicity: cus.ethnicity,
			customer_skin_type: cus.skin_type,
			customer_age_range: cus.age_range,
			// customerData: customerData,
		};

		// this.logSaleInAttendance(salesData);
		// console.log(salesData);
		// return;

		// crudCreate("sales", salesData, (s, d) => {
		// 	if (s === 200) {
		// crudCreatSale(salesData, () => {

		// });

		// 	} else {
		// 		alert("sale error");
		// 	}
		// });

		if (typeof this.props.user_id !== "undefined") {
			this.logSaleInAttendance(salesData);
		}
	};

	logSaleInAttendance = async (salesData) => {
		this.setState({ loading: true });

		let obj = {
			attendanceId: `${this.props.user_id}${moment().format("DDMMYYYY")}`,
			dateFilter: `_${moment().format("YYYYMMDD")}`,
			last_seen: moment().toISOString(),
			ipt: salesData.ipt,
			trs: salesData.trs,
			ars: salesData.ars,
			newCustomer: salesData.newCustomer ? 1 : 0,
			existCustomer: salesData.newCustomer ? 0 : 1,

			salesData,
		};
		// console.log(obj);
		// return;
		crudUpdateSaleAttandance(obj, (status) => {
			console.log(status);
			this.setState({
				showSaleCompleted: true,
				salesData: salesData,
			});
		});
	};

	qrScanned = (barcode) => {
		this.setState({
			scanBarcodeModal: false,
			searchBarValue: barcode,
		});
	};

	// addToCart = async (d) => {
	// 	let exists = await this.state.cartItems.filter(
	// 		(cart) => cart.id !== d.id,
	// 	);
	// 	let cartItems = "";

	// 	if (exists.length > 0) {
	// 		cartItems = await this.state.cartItems.filter(
	// 			(cart) => cart.id !== d.id,
	// 		);
	// 		await this.setState({
	// 			cartItems: cartItems,
	// 		});
	// 	} else {
	// 		cartItems = await [...this.state.cartItems, d];
	// 		await this.setState({
	// 			cartItems: cartItems,
	// 		});
	// 	}

	// 	if (cartItems.length == 0) {
	// 		await this.setState({
	// 			showCart: false,
	// 		});
	// 	}
	// 	// LayoutAnimation.configureNext(CustomLayoutSpring);
	// };
	addToCart = (d) => {
		if (!d || !d.id) {
			console.error("Invalid item passed to addToCart:", d);
			return;
		}

		let rem = this.state.cartItems.filter((d_) => d_.id !== d.id);
		let existingItem = this.state.cartItems.find((d_) => d_.id === d.id);

		if (existingItem) {
			// Item already exists in cart, remove it
			this.setState({
				cartItems: rem,
			});
		} else {
			// Item doesn't exist, add it
			rem.push(d);
			this.setState({
				cartItems: rem,
			});
		}

		console.log("Cart updated:", this.state.cartItems);
	};

	handleScroll(event) {
		this.setState({
			yPos: event.nativeEvent.contentOffset.y,
		});
		// console.log(event.nativeEvent.contentOffset.y);
		this.onScrollStart(event);
	}

	onScrollStart = (event) => {
		let currentOffset = event.nativeEvent.contentOffset.y;

		if (currentOffset > 106 && this.state.aircraft_type != "") {
			this.setState({
				showShadow: true,
			});
			// console.log(currentOffset);
		} else {
			this.setState({
				showShadow: false,
			});
		}
	};

	async onValueChange(item, selectedValue) {
		try {
			await AsyncStorage.setItem(item, selectedValue);
		} catch (error) {
			console.error(error);
		}
	}
}
