import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";

export default class Gender extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gender: this.props.value,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				gender: this.props.value,
			});
		}
	}

	render() {
		return (
			<View
				style={{
					// backgroundColor: APP_COLOURS.DESELECTEDCOLOR,
					// padding: 5,
					width: "100%",
					// marginTop: 10,
					marginBottom: 30,
				}}>
				<Text
					allowFontScaling={false}
					style={{
						fontWeight: "500",
						fontSize: 13,
						color: APP_COLOURS.TEXTCOLOR,
						opacity: 0.7,
						marginTop: 5,
						marginBottom: 5,
						paddingBottom: 5,
					}}>
					Gender
				</Text>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
					}}>
					{["Male", "Female", "Other"].map((d, i) => (
						<TouchableOpacity
							onPress={() => {
								this.setState({ gender: d });
								this.props.selected(d);
							}}
							style={{
								flex: 1,
								//  padding: 5,
							}}>
							<View
								style={{
									flex: 1,
									marginHorizontal: 3,
									backgroundColor:
										this.state.gender === d
											? APP_COLOURS.BLUE
											: "#FFF",
									justifyContent: "center",
									alignItems: "center",
									padding: 10,
									borderRadius: 13,
									borderWidth: 0.3,
									borderColor: APP_COLOURS.BLACK,
								}}>
								<Text
									style={{
										color:
											this.state.gender === d
												? "#FFF"
												: "#000",
										fontSize: 15,
									}}>
									{d}
								</Text>
							</View>
						</TouchableOpacity>
					))}
				</View>
			</View>
		);
	}
}
