import moment from "moment";
import {
	api_crudCreate,
	api_crudRead,
	api_crudDelete,
	api_crudUpdate,
	api_getUsers,
	api_crudWithImage,
	api_crudDeleteDocument,
	api_sendBasicEmail,
	api_createPostApproval,
	api_crudReadUnass,
	api_creatSurveyFeedback,
	api_sendDiscordMessage,
} from "./Api";

// THIS FILE IS to get and set async data of the device without making too many requests

export const crudCreate = async (collection, where, next) => {
	let url = api_crudCreate;
	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			collection: `traq_app_${collection}`,
			data: where,
			user_id: user_id,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudCreateWithBase64 = async (collection, where, next) => {
	let url = api_crudWithImage;
	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			collection: `traq_app_${collection}`,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	// console.log({
	//   collection: `traq_app_${collection}`,
	//   data: where,
	// });

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDeleteDocument = async (collection, where, next) => {
	let url = api_crudDeleteDocument;
	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			collection: `traq_app_${collection}`,
			data: where,
			user_id: user_id,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudRead = async (collection, where, next) => {
	let url = api_crudRead;
	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			collection: `traq_app_${collection}`,
			where: where,
			user_id: user_id,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`, {
		collection: `traq_app_${collection}`,
		where: where,
		user_id: user_id,
	});
	await console.log(url, requestOptions);
	// return;
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						console.log("result ", result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				// logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudReadUnass = async (collection, where, next) => {
	let url = api_crudReadUnass;
	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			collection: `traq_app_${collection}`,
			where: where,
			user_id: user_id,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudReadAll = async (collection, next) => {
	let url = api_crudRead;
	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			collection: `traq_app_${collection}`,
			where: "",
			user_id: user_id,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudUpdate = async (collection, obj, next) => {
	let url = api_crudUpdate;
	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();

	let requestOptions = {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			collection: `traq_app_${collection}`,
			data: obj,
			user_id: user_id,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, {
		collection: `traq_app_${collection}`,
		data: obj,
	});

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				console.log("result ");
				return next(200);
			} else if (response.status === 401) {
				// logOut();
			} else {
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDelete = async (collection, obj, next) => {
	let url = api_crudDelete;
	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			collection: `traq_app_${collection}`,
			data: obj,
			user_id: user_id,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			if (response.status !== 200) {
				logOut();
				return;
			} else {
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

//

export const crudSendEmail = async (sender, recipient, subject, body) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	let url = api_sendBasicEmail;

	var raw = JSON.stringify({
		jwt: "dnYESWgXMYn5WVgdUjREDqvuzezfDn9WuYQRDNIFNggEVYGNVEu",
		sender: sender,
		recipient: recipient,
		body: `${body}`,
		subject: `${subject}`,
	});

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};
	// console.log(url, requestOptions);
	// return;
	fetch(url, requestOptions)
		.then((response) => {
			console.log(response.status);
			if (response.status !== 200) {
				alert("Error sending email!");
			} else if (response.status === 200) {
				// alert("Sent!");

				return;
			}
		})
		.catch((error) => console.log("error", error));
};

export const sendDiscordMessage = async (username, message, avatar_url, id) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	let url = api_sendDiscordMessage;

	var raw = JSON.stringify({
		jwt: "dnYESWgXMYn5WVgdUjREDqvuzezfDn9WuYQRDNIFNggEVYGNVEu",
		message: message,
		username: username,
		avatar_url: avatar_url,
		id: id,
	});

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};
	// console.log(url, requestOptions);
	// return;
	fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status !== 200) {
				// alert("Error updating email!");
				// logOut();
			} else if (response.status === 200) {
				// alert("Sent!");

				return;
			}
		})
		.catch((error) => console.log("error", error));
};

//
//

async function getUserId() {
	try {
		const value = await localStorage.getItem("user_id");
		let parse = value;
		if (value !== null) {
			return parse;
		} else {
			return "";
		}
	} catch (error) {
		return "";
	}
}

async function getAccessTokenF() {
	try {
		const value = await localStorage.getItem("accessToken");
		let parse = value;
		if (value !== null) {
			return parse;
		} else {
			return;
		}
	} catch (error) {
		return;
	}
}

async function removeAsyncItem(key) {
	try {
		await localStorage.removeItem(key);
		return true;
	} catch (exception) {
		alert("Error removing item");
		return false;
	}
}

// async function onValueChange(item, selectedValue) {
// 	try {
// 		await localStorage.setItem(item, selectedValue).then((x) => {
// 			// console.log('onValueChange', item, selectedValue);
// 		});
// 	} catch (error) {
// 		console.error(error);
// 	}
// }

export const createPostApproval = async (data, next) => {
	await console.log("createPostApproval");

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${data.token}`,
		},

		redirect: "follow",
		body: JSON.stringify(data),
	};

	// console.log(data.token);
	// return;
	fetch(api_createPostApproval, requestOptions)
		.then((response) => {
			if (response.status == 200) {
				response
					.json()
					.then(async (r) => {
						return next(response.status);
					})
					.catch((error) => {
						return next(response.status);
					});
			} else {
				return next(response.status);
			}
		})
		.catch((error) => {
			console.log(error);
			return next(500);
		});
};

export const createSurveyApproval = async (data, next) => {
	// await console.log("createSurveyApproval");
	// alert("No API set up");

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${data.token}`,
		},

		redirect: "follow",
		body: JSON.stringify(data),
	};

	// console.log(data.token);
	// return;
	fetch(api_creatSurveyFeedback, requestOptions)
		.then((response) => {
			if (response.status == 200) {
				response
					.json()
					.then(async (r) => {
						return next(response.status);
					})
					.catch((error) => {
						return next(response.status);
					});
			} else {
				return next(response.status);
			}
		})
		.catch((error) => {
			console.log(error);
			return next(500);
		});
};
//
//
//
//
export const crudGetUsers = async (next) => {
	await console.log("crudGetUsers");
	const responseData = await getUsers((d) => {
		// console.log('response =====================', d);
		return next(d);
	});
};
async function getUsers(next) {
	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();
	let requestOptions = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},

		redirect: "follow",
	};
	fetch(api_getUsers, requestOptions)
		.then((response) => {
			if (response.status == 200) {
				response
					.json()
					.then(async (r) => {
						// const set_ = await onValueChange(
						// 	"users",
						// 	,
						// );
						localStorage.setItem("users", JSON.stringify(r));
						return next(r);
					})
					.catch((error) => {
						return [];
					});
			} else {
				console.log(response);
				return next([]);
			}
		})
		.catch((error) => {
			console.log(error);
			return next([]);
		});
}

async function logOut() {
	localStorage.clear();
	window.location.href = "/";
}

export const getCustomers = async (where, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/72abf0d2-4863-4afb-a5a3-7c10ec52d810";
	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			data: where,
			user_id: user_id,
		}),
		redirect: "follow",
	};
	await console.log(url, requestOptions);
	// return;
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				// logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

//
export const crudCreateCustomer = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/8279c99a-4366-4841-8ae0-a572577d273f";

	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify(obj),
		redirect: "follow",
	};

	await console.log(url, {
		data: obj,
	});

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
					});
			} else if (response.status === 401) {
				// logOut();
			} else {
				// return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudUpdateCustomer = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/e80ba3d0-e3b2-44a1-aa9f-2ece94b7c59e";

	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();

	let requestOptions = {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify(obj),
		redirect: "follow",
	};

	await console.log(url, {
		data: obj,
	});

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				console.log("result ");
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
					});
			} else if (response.status === 401) {
				// logOut();
			} else {
				// return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudUpdateSaleAttandance = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/a1e13484-fe18-47c9-ae5c-337d1be786fd";
	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			data: obj,
			user_id: user_id.id,
		}),
		redirect: "follow",
	};

	await console.log(url, {
		data: obj,
	});

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				console.log("result ");
				return next(200);
			} else if (response.status === 401) {
				// logOut();
			} else {
				// return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudUpdateProfile = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/cbe19390-2e69-4fc6-bca3-4927175b76ef";
	let user_id = await getUserId();
	// console.log(user_id);
	// return;
	let getAccessToken = await getAccessTokenF();

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			data: obj,
			user_id: user_id,
		}),
		redirect: "follow",
	};

	await console.log(url, {
		data: obj,
	});

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				console.log("result ");
				return next(200);
			} else if (response.status === 401) {
				// logOut();
			} else {
				// return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudGetProducts = async (next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/2650c166-1d03-453e-847c-1adc0a6de3f5";
	let user_id = await getUserId();
	// console.log(user_id);
	// return;
	let getAccessToken = await getAccessTokenF();

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({}),
		redirect: "follow",
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				console.log("result ");
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
					});
			} else if (response.status === 401) {
				// logOut();
			} else {
				// return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudGetSheetProducts = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/eba7921a-1ed7-4890-a193-e637455984e1";

	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			data: obj,
			user_id: user_id,
		}),
		redirect: "follow",
	};

	await console.log(url, {
		data: obj,
	});

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else if (response.status === 401) {
				// logOut();
			} else {
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudCreatSale = async (salesData, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/01faa31f-000d-436a-9daf-b419480ab63b";
	let user_id = await getUserId();
	// console.log(user_id);
	// return;
	let getAccessToken = await getAccessTokenF();

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify({
			data: salesData,
		}),
		redirect: "follow",
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				return next();
			} else if (response.status === 401) {
				// logOut();
			} else {
				// return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudGetCommissions = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/1b3c29b0-3444-4924-83fa-86aba48b92ca";

	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify({
			...obj,
		}),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudGetCommissions", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const getSalesRange = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/064eb73b-a873-45b2-9beb-6310fc17d171";

	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const getAllSales = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/f465b013-9e29-4fe5-865f-47db5bc5934b";

	let user_id = await getUserId();
	let getAccessToken = await getAccessTokenF();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudUpdateCustomerNote = async (note, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/dca39314-8513-4227-a0f4-a3ff03655807";
	let user_id = await getUserId();
	// console.log(user_id);
	// return;
	let getAccessToken = await getAccessTokenF();

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify(note),
		redirect: "follow",
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudGetCustomerNote = async (note, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/38852bc4-2213-41a0-a693-984e00de0c63";
	let user_id = await getUserId();
	// console.log(user_id);
	// return;
	let getAccessToken = await getAccessTokenF();

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${getAccessToken}`,
		},
		body: JSON.stringify(note),
		redirect: "follow",
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};
