import React, { Component } from "react";
import {
	StyleSheet,
	Keyboard,
	Text,
	Linking,
	Image,
	Alert,
	TouchableOpacity,
	SafeAreaView,
	ActivityIndicator,
	ScrollView,
	Button,
	Dimensions,
	Modal,
	Platform,
	View,
} from "react-native";

import { api_updatestatus, api_getattendance } from "../../Api";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import {
	crudCreate,
	crudRead,
	crudReadAll,
	crudReadUnass,
	crudUpdate,
} from "../../CRUDHELPER";

const device = {
	width: Dimensions.get("window").width,
	height: Dimensions.get("window").height,
};

export default class Attendance extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: true,
			isConnected: true,
			loading: true,
			// geoRequired: false,
			// dayStarted: false,
			// lunchTaken: false,
			// lunchEnded: false,
			// lunch_started: false,

			attendance: {
				checkin: "",
				lunch_start: "",
				lunch_end: "",
				checkout: "",
			},
			attendanceId: `${this.props.user_data.id}${moment().format(
				"DDMMYYYY",
			)}`,

			crudDocumentName: "attendance",
		};
	}

	componentDidMount() {
		// this.getDeviceData();
		this.getAttendance();
	}

	getAttendance = async () => {
		console.log(this.state.attendanceId, this.props.user_data.id);

		crudRead(
			this.state.crudDocumentName,
			{
				field: "attendanceId",
				action: "==",
				value: this.state.attendanceId,
			},
			async (status, d) => {
				if (status === 200) {
					console.log(d);
					this.setState({
						attendance: {
							...this.state.attendance,
							...d[0],
						},
						loading: false,
					});
				}
			},
		);
	};

	logAttendance = async (status) => {
		this.setState({ loading: true });
		// Actions.replace("Timeline", {
		// 	user_id: this.props.user_id,
		// 	app_properties: this.props.app_properties,
		// });

		let obj = {
			...this.state.attendance,
			attendanceId: this.state.attendanceId,
			[status]: moment().toISOString(),
		};

		if (typeof obj.id !== "undefined") {
			crudUpdate(this.state.crudDocumentName, obj, () => {
				window.location.href = "/Profile";
				return;
			});
		} else {
			crudCreate(this.state.crudDocumentName, obj, () => {
				window.location.href = "/Profile";
				return;
			});
		}
	};

	// checkIn = () => {
	// 	let time = moment().format("DD/MM/YYYY HH:mm:ss");
	// 	this.setState({
	// 		checkedIn: true,
	// 		checkedIn_time: time,
	// 	});
	// 	// this.postCheckin(this.state.checkedIn_time);
	// };

	// goBackHome = () => {
	// 	this.setState({
	// 		geoRequired: false,
	// 		loading: false,
	// 		checkin: false,
	// 		continueday: false,
	// 		lunch_started: false,
	// 	});
	// 	window.location.href = "/";
	// };

	// geoRequired = () => {
	// 	this.setState({
	// 		geoRequired: true,
	// 		loading: false,
	// 		checkin: false,
	// 		continueday: false,
	// 	});
	// };

	async onValueChange(item, selectedValue) {
		try {
			await sessionStorage.setItem(item, selectedValue);
		} catch (error) {
			console.error(error);
		}
	}

	componentWillUnmount() {}

	handleConnectivityChange = (isConnected) => {
		if (isConnected) {
			this.setState({ isConnected });
		} else {
			this.setState({ isConnected });
		}
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					backgroundColor: this.props.app_properties.backgroundcolour,
				}}>
				<View
					style={{
						flex: 1,
						padding: 10,
					}}>
					<Text
						style={{
							fontSize: 30,
							fontWeight: "800",
							padding: 15,
							// color: this.state.fontcolour,
							color: this.props.app_properties.mainFontColor,
							marginBottom: 16,
						}}>
						{" "}
						Attendance
					</Text>

					<ScrollView>
						{this.state.loading ? (
							<ActivityIndicator
								color={this.state.fontcolour}
								size='small'
								style={{ marginVertical: 15 }}
							/>
						) : null}
						<View
							style={{
								flex: 1,
								borderRadius: 15,
								backgroundColor: "#FFF",
								padding: 10,
							}}>
							<Text
								style={{
									fontSize: 22,
									fontWeight: "700",
									textAlign: "center",
									marginTop: 10,
								}}>
								Hey, {this.props.user_data.first_name}{" "}
							</Text>
							<Text
								style={{
									fontSize: 20,
									fontWeight: "300",
									padding: 15,
									textAlign: "center",
								}}>
								Would you like to start your day?
							</Text>

							{/* {this.state.attendance.checkin === ""
								? this.buttonState(
										"Check in",
										"checkin",
										this.state.attendance.checkin,
										APP_COLOURS.GREEN,
								  )
                : null} */}

							{this.buttonState(
								"Check in",
								"checkin",
								this.state.attendance.checkin,
								APP_COLOURS.GREEN,
							)}

							{/* {this.state.attendance.lunch_start === ""
								? this.buttonState(
										"Lunch start",
										"lunch_start",
										this.state.attendance.lunch_start,
										APP_COLOURS.ORANGE,
								  )
								: null} */}
							{this.buttonState(
								"Lunch start",
								"lunch_start",
								this.state.attendance.lunch_start,
								APP_COLOURS.ORANGE,
							)}

							{this.buttonState(
								"Lunch end",
								"lunch_end",
								this.state.attendance.lunch_end,
								APP_COLOURS.ORANGE,
							)}

							{this.buttonState(
								"End your day",
								"checkout",
								this.state.attendance.checkout,
								APP_COLOURS.BLUE,
							)}

							{/* {this.state.geoRequired
							? this.geoLocationRequired()
							: null} */}

							{/* {this.state.lunchTaken && !this.state.lunchEnded
							? this.lunchTaken()
							: null}

						{!this.state.dayStarted &&
						!this.state.lunchTaken &&
						!this.state.lunchEnded &&
						!this.state.loading
							? this.checkInSession()
							: null}

						{(this.state.dayStarted &&
							!this.state.lunchEnded &&
							!this.state.lunchTaken) ||
						(this.state.dayStarted &&
							this.state.lunchEnded &&
							this.state.lunchTaken)
							? this.openSession()
							: null} */}

							{/* {this.openSession()} */}

							<TouchableOpacity
								onPress={() => {
									window.location.href = "/Profile";
									return;
								}}
								style={{
									padding: 15,
									width: "100%",
									// backgroundColor: '#0B80FF',
									borderRadius: 10,
									alignItems: "center",
								}}>
								{this.state.loading ? (
									<ActivityIndicator
										color={this.state.fontcolour}
										size='small'
										style={{ marginTop: 15 }}
									/>
								) : (
									<Text
										style={{
											fontSize: 16,
											fontWeight: "300",
											// letterSpacing: 1.2,
											// color: this.state.fontcolour,
											// color: '#FFF',
											color: "#0B80FF",
										}}>
										Go to Profile
									</Text>
								)}
							</TouchableOpacity>

							{this.state.lunch_started
								? this.backFromLunch()
								: null}
						</View>
					</ScrollView>
				</View>
			</View>
		);
	}

	geoLocationRequired = () => {
		return (
			<View
				style={{
					position: "absolute",

					justifyContent: "center",
					alignItems: "center",
				}}>
				<View style={{ alignItems: "center" }}>
					<View
						style={{
							width: device.width - 20,
							marginTop: 0,
							maxWidth: 490,
							marginLeft: 10,
							backgroundColor: "white",
							borderRadius: 20,
							alignItems: "center",
						}}>
						<Image
							source={require("../../../assets/images/georequired.png")}
							style={{
								width: device.width - 80,
								height: device.width - 80,
								resizeMode: "contain",
								marginTop: 30,
								marginBottom: 20,
							}}
						/>

						<View style={{ flexDirection: "column" }}>
							<TouchableOpacity
								onPress={() => this.openSettings()}
								style={{
									padding: 15,
									width: 216,
									backgroundColor: "#0B80FF",
									borderRadius: 10,
									alignItems: "center",
								}}>
								<Text
									style={{
										fontSize: 16,
										fontWeight: "300",
										color: this.state.fontcolour,
									}}>
									Go to Locations Settings
								</Text>
							</TouchableOpacity>
						</View>

						<View
							style={{
								borderBottomWidth: 1,
								borderColor: this.state.backgroundcolour,
								width: "80%",
								marginTop: 100,
							}}
						/>
						<TouchableOpacity
							onPress={() => this.goBackHome()}
							style={{
								padding: 15,
								width: 216,
								borderColor: this.state.brandcolour,
								borderRadius: 10,
								borderWidth: 1,
								marginTop: 40,
								alignItems: "center",
								marginBottom: 40,
							}}>
							<Text style={{ fontSize: 12 }}>Go back</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		);
	};

	lunchTakenComp = () => {
		return (
			<View
				style={{
					position: "absolute",

					justifyContent: "center",
					alignItems: "center",
				}}>
				<View style={{ alignItems: "center" }}>
					<View
						style={{
							width: device.width - 20,
							marginTop: 0,
							maxWidth: 490,
							marginLeft: 10,
							backgroundColor: "white",
							borderRadius: 20,
							alignItems: "center",
						}}>
						{/* <Image
							source={require("../../assets/images/lunchbreak.png")}
							style={{
								width: device.width - 80,
								height: device.width - 80,
								resizeMode: "contain",
								marginTop: 0,
								marginBottom: 50,
							}}
						/> */}

						<View style={{ flexDirection: "column" }}>
							<TouchableOpacity
								onPress={() =>
									this.logAttendance("Lunch ended")
								}
								style={{
									padding: 15,
									width: 216,
									backgroundColor: "#0B80FF",
									borderRadius: 10,
									alignItems: "center",
									marginTop: 0,
								}}>
								{/* <Image 
                source={require("./../assets/images/lunch_img.png")} 
                style={{height: 40, width: 40, resizeMode: "contain"}}
                /> */}
								<Text
									style={{
										fontSize: 15,
										fontWeight: "500",
										letterSpacing: 0.5,
										color: this.state.fontcolour,
									}}>
									Im back
								</Text>
							</TouchableOpacity>
						</View>

						<View
							style={{
								borderBottomWidth: 1,
								borderColor: this.state.backgroundcolour,
								width: "80%",
								marginTop: 30,
							}}
						/>
						<TouchableOpacity
							onPress={() => this.goBackHome()}
							style={{
								padding: 15,
								width: 216,
								borderColor: this.state.backgroundcolour,
								borderRadius: 10,
								borderWidth: 1,
								marginTop: 40,
								alignItems: "center",
								marginBottom: 40,
							}}>
							<Text style={{ fontSize: 10 }}>Not yet</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		);
	};

	buttonState = (title, setstate, state, color) => {
		return (
			<View style={{ justifyContent: "center", alignItems: "center" }}>
				<TouchableOpacity
					disabled={state !== ""}
					onPress={() => this.logAttendance(setstate)}
					style={{
						padding: 15,
						width: "100%",
						backgroundColor: state !== "" ? "transparent" : color,
						borderRadius: 10,
						alignItems: "center",
					}}>
					{state !== "" ? (
						<Text
							style={{
								fontSize: 16,
								fontWeight: "500",
								letterSpacing: 1.2,
								// color: this.state.fontcolour,
								color: color,
							}}>
							{moment(state).format("HH:mm")}
						</Text>
					) : (
						<Text
							style={{
								fontSize: 16,
								fontWeight: "500",
								letterSpacing: 1.2,
								// color: this.state.fontcolour,
								color: "#FFF",
							}}>
							{title}
						</Text>
					)}
				</TouchableOpacity>

				<View
					style={{
						// borderBottomWidth: 1,
						borderColor: this.state.backgroundcolour,
						width: "80%",
						marginVertical: 10,
					}}
				/>
			</View>
		);
	};

	openSession = () => {
		return (
			<View
				style={{
					height: 500,
					width: device.width - 20,
					// marginTop:0,
					maxWidth: 490,
					marginLeft: 10,
					backgroundColor: "white",
					borderRadius: 20,
					alignItems: "center",
					paddingTop: 10,
					position: "absolute",

					justifyContent: "center",
					alignItems: "center",
					padding: 20,
				}}>
				<Text
					style={{
						fontSize: 22,
						fontWeight: "700",
						padding: 15,
						textAlign: "center",

						display:
							this.state.lunchEnded || this.state.dayStarted
								? "flex"
								: "none",
					}}>
					{" "}
					Welcome back, {this.state.first_name}{" "}
				</Text>
				<Text
					style={{
						fontSize: 18,
						fontWeight: "300",
						padding: 5,
						textAlign: "center",
					}}>
					{" "}
					Select an action below
				</Text>

				{/* {this.state.location != '' ? (
            <Text
              style={{
                fontSize: 8,
                fontWeight: '400',
                letterSpacing: 2,
                color: 'black',
              }}>
              No Location {this.state.location}
            </Text>
          ) : (
            <Text
              style={{
                fontSize: 8,
                fontWeight: '400',
                letterSpacing: 2,
                color: 'black',
              }}>
              No Location {this.state.location}
            </Text>
          )} */}

				{/* <Image
					source={require("../../assets/images/lunch_img.png")}
					style={{ height: 40, width: 40, resizeMode: "contain" }}
				/> */}

				{/* {this.state.lunch_started ? (
            <Image
              source={require('../../assets/images/lunch_img.png')}
              style={{height: 40, width: 40, resizeMode: 'contain'}}
            />
          ) : null}

          {this.state.lunch_started ? (
            <TouchableOpacity
              onPress={() => this.pop()}
              style={{
                padding: 15,
                width: 216,
                backgroundColor: '#0B80FF',
                borderRadius: 10,
                alignItems: 'center',
                marginTop: 20,
              }}>
              <Image
                source={require('../../assets/images/lunch_img.png')}
                style={{height: 40, width: 40, resizeMode: 'contain'}}
              />
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: '300',
                  letterSpacing: 2,
                  // color: this.state.fontcolour,
                  color: '#FFF',
                }}>
                See you later!
              </Text>
            </TouchableOpacity>
          ) : null} */}

				<TouchableOpacity
					onPress={() => this.logAttendance("On counter")}
					style={{
						padding: 15,
						width: "100%",
						backgroundColor: "#F87073",
						borderRadius: 10,
						alignItems: "center",
						marginTop: 0,
					}}>
					{this.state.loading ? (
						<ActivityIndicator
							color={this.state.fontcolour}
							size='small'
						/>
					) : (
						<Text
							style={{
								fontSize: 17,
								fontWeight: "400",
								letterSpacing: 2,
								// color: this.state.fontcolour,
								color: "#FFF",
							}}>
							PROFILE
						</Text>
					)}
				</TouchableOpacity>

				<View
					style={{
						borderBottomWidth: 1,
						borderColor: this.state.backgroundcolour,
						width: "80%",
						marginTop: 30,
					}}
				/>

				{!this.state.lunchTaken ? (
					<TouchableOpacity
						onPress={() => this.logAttendance("On lunch")}
						style={{
							padding: 15,
							width: "100%",
							backgroundColor: "#0B80FF",
							borderRadius: 10,
							alignItems: "center",
							marginTop: 20,
						}}>
						{/* <Image
              source={require('../../assets/images/lunch_img.png')}
              style={{height: 40, width: 40, resizeMode: 'contain'}}
            /> */}
						<Text
							style={{
								fontSize: 17,
								fontWeight: "300",
								letterSpacing: 2,
								// color: this.state.fontcolour,
								color: "#FFF",
							}}>
							START LUNCH
						</Text>
					</TouchableOpacity>
				) : null}

				<TouchableOpacity
					onPress={() => this.logAttendance("Shift ended")}
					style={{
						padding: 15,
						width: "100%",
						backgroundColor: "#0B80FF",
						borderRadius: 10,
						alignItems: "center",
						marginTop: 30,
					}}>
					{/* <Image
            source={require('../../assets/images/checkout_img.png')}
            style={{height: 40, width: 40, resizeMode: 'contain'}}
          /> */}
					<Text
						style={{
							fontSize: 17,
							fontWeight: "300",
							letterSpacing: 2,
							// color: this.state.fontcolour,
							color: "#FFF",
						}}>
						CHECK OUT
					</Text>
				</TouchableOpacity>
			</View>
		);
	};

	backFromLunch = () => {
		return (
			<View
				style={{
					position: "absolute",

					justifyContent: "center",
					alignItems: "center",

					zIndex: 999,
				}}>
				<View style={{ alignItems: "center" }}>
					<View
						style={{
							width: device.width - 20,
							marginTop: 0,
							maxWidth: 490,
							marginLeft: 10,
							backgroundColor: "white",
							borderRadius: 20,
							alignItems: "center",
						}}>
						{/* <Image
							source={require("../../assets/images/lunch_start.png")}
							style={{
								width: device.width - 80,
								height: device.width - 80,
								resizeMode: "contain",
								marginTop: 30,
								marginBottom: 20,
							}}
						/> */}
						<View style={{ flexDirection: "column" }}>
							<TouchableOpacity
								onPress={() => this.goBackHome()}
								style={{
									padding: 15,
									width: 216,
									backgroundColor: "#0B80FF",
									borderRadius: 10,
									alignItems: "center",
								}}>
								<Text
									style={{
										fontSize: 16,
										fontWeight: "300",
										color: this.state.fontcolour,
									}}>
									See you later!
								</Text>
							</TouchableOpacity>
						</View>
						<View
							style={{
								borderBottomWidth: 1,
								borderColor: this.state.backgroundcolour,
								width: "80%",
								marginTop: 100,
							}}
						/>
					</View>
				</View>
			</View>
		);
	};
}
