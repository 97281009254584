import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import tick from "./tick_red.png";
import "./index.css";
import { api_login } from "../Api";
import * as animationData from "./animation_lnu500l9.json";
import { sendDiscordMessage } from "../CRUDHELPER";
import { APP_COLOURS, Avatars } from "../APP_VARS";
import AutoHeightImage from "../AuthedOLD/AutoHeightImageComp";
import axios from "axios";
import AsyncStorage from "../AsyncStorage";
import QrCodeReader from "./QrCodeReader";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

function uuid() {
	return "traq-web-xxxx-xxxx-xxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			password: "",
			username: "",
			// username: "30FEC84",
			// password: "c70480",

			emailValid: false,
			screenWidth: screenWidth,
			screenHeight: screenHeight,
			otpPinSent: false,
			showLoginForm: false,
		};
	}

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	loginButton = async () => {
		this.setState({
			loading: true,
			otpPinSent: true,
			errorLogin: false,
		});
		await sessionStorage.setItem("email", this.state.username);
		// await sessionStorage.setItem('password', this.state.password);
	};

	loginButton2 = async () => {
		this.setState({
			loading: true,
			// otpPinSent: true,
			errorLogin: false,
		});

		await sessionStorage.setItem("email", this.state.username);
		// await sessionStorage.setItem('password', this.state.password);
		// this.props.history.push("/dashboard/");
		// setTimeout(() => this.checkRSVP(), 3000);
		// this.loginFunc();
		this.loginFuncWithOTP();
	};

	loginFuncWithOTP = async () => {
		let url = api_login;

		const data = await this.geoAppify();
		console.log(data);
		let obj = {
			uniqueID: this.state.username,
			passcode: this.state.password,
			deviceDATA: { ...data, id: this.state.deviceToken },
			app: "traq_fzVHQrxaUigKMipryOPy",
		};

		var raw = JSON.stringify(obj);

		var requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,
		};

		// console.log(url, obj);
		// return;

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log("response =====", response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log(responseData);
							// let user_data = await sessionStorage.setItem(
							// 	"user_data",
							// 	JSON.stringify(responseData),
							// );
							let accessToken = await sessionStorage.setItem(
								"accessToken",
								responseData.accessToken,
							);
							let now = moment().toISOString();
							const lastUpdated = await sessionStorage.setItem(
								"lastUpdated",
								now,
							);
							await this.onValueChange(
								"accessToken",
								responseData.accessToken,
							);
							await this.onValueChange(
								"app_properties",

								JSON.stringify(responseData.app_properties),
							);

							await this.onValueChange(
								"users",

								JSON.stringify(responseData.users),
							);

							await this.onValueChange("app", responseData.app);
							await this.onValueChange(
								"mall",
								JSON.stringify(responseData.mall),
							);
							await this.onValueChange(
								"retailer",
								JSON.stringify(responseData.retailer),
							);
							await this.onValueChange(
								"license",
								responseData.license,
							);
							await this.onValueChange("api", responseData.api);
							await this.onValueChange(
								"version",
								responseData.version,
							);
							await this.onValueChange(
								"users",
								JSON.stringify(responseData.users),
							);
							await this.props.navigate("/Home");
						})
						.catch((error) => {
							// alert(error);
							this.setState({
								loading: false,
								password: "",
								username: "",
								emailValid: false,
								otpPinSent: false,
							});
						});
				} else {
					// alert(
					// 	"Authentication failed, please check your credentials and try again.",
					// );
					this.setState({
						errorLogin: true,
						loading: false,
						password: "",
						username: "",
						emailValid: false,
						otpPinSent: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorLogin: true,
					loading: false,
					password: "",
					username: "",
					emailValid: false,
					otpPinSent: false,
				});
			});
	};

	async onValueChange(item, selectedValue) {
		try {
			await sessionStorage.setItem(item, selectedValue);
		} catch (error) {
			console.error(error);
		}
	}

	geoAppify = async () => {
		try {
			const { data } = await axios.get(`https://ipapi.co/json/`);

			const analyticsData = {
				...data,
				userAgent: window.navigator.userAgent,
				screenWidth: window.screen.width,
				screenHeight: window.screen.height,
			};

			console.log("TrackVisitorAndIP analyticsData", analyticsData);

			return analyticsData;
		} catch (error) {
			return error;
		}
	};

	componentDidMount() {
		console.log("componentDidMount");
		let accessToken = sessionStorage.getItem("accessToken");

		console.log("Approved User", accessToken);
		if (accessToken !== null) {
			// this.props.history.push("/dashboard/Home");
		} else {
			sessionStorage.clear();
			this.setState({
				loading: false,
			});
		}

		let deviceToken = localStorage.getItem("deviceToken");
		if (deviceToken !== null) {
			this.setState({
				deviceToken: deviceToken,
			});
		} else {
			let uuid_ = uuid();
			localStorage.setItem("deviceToken", uuid_);

			this.setState({
				deviceToken: uuid_,
			});
		}

		window.addEventListener("resize", this.resize());
		// this.resize();

		setTimeout(
			() =>
				this.setState({
					showLoginForm: true,
				}),
			2000,
		);
	}

	componentDidUpdate() {}

	resize() {
		// alert("screen rotate");

		setTimeout(() => {
			this.setState({
				screenHeight: window.innerHeight,
				screenWidth: window.innerWidth,
			});
		}, 300);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	loginForm = () => {
		return (
			<View
				style={{
					flex: 2,
					width: "100%",
					alignItems: "center",
					// justifyContent: 'center',
				}}>
				<View
					style={{
						width: "100%",
						padding: 10,

						justifyContent: "center",
						alignItems: "center",
						// maxWidth: 350,
						minWidth: 300,
						marginTop: 15,
						borderWidth: 1,
						borderColor:
							this.state.username.length >= 4
								? APP_COLOURS.BLUE2
								: "#797979",
						backgroundColor: "#1F2229",
						borderRadius: 12,
						flexDirection: "row",
					}}>
					<Image
						source={tick}
						style={{
							width: 20,
							height: 20,
							resizeMode: "contain",
							marginRight: 10,
							opacity: this.state.username.length >= 4 ? 1 : 0,
						}}
					/>

					<TextInput
						style={{
							width: "100%",
							fontSize: 16,
							textAlign: "left",
							color: "#FBFBFC",
							fontWeight: "200",
							fontFamily: "Gotham",
							letterSpacing: 0.26,
							padding: 5,
						}}
						placeholderTextColor={"#797979"}
						autoCapitalize='none'
						clearButtonMode='while-editing'
						autoCompleteType='username'
						autoCorrect={false}
						autoFocus={true}
						// keyboardType='email-address'
						// textContentType='emailAddress'
						placeholder={`Username / UUID`}
						value={this.state.username}
						onChangeText={(text) => {
							this.setState({
								username: text,
							});
						}}
					/>
				</View>

				<View
					style={{
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<View
						style={{
							width: "100%",
							padding: 10,

							justifyContent: "center",
							alignItems: "center",
							// maxWidth: 350,
							minWidth: 300,
							marginTop: 15,
							borderWidth: 1,
							borderColor:
								this.state.password.length > 3
									? APP_COLOURS.BLUE2
									: "#797979",
							backgroundColor: "#1F2229",

							borderRadius: 12,
							flexDirection: "row",
						}}>
						<Image
							source={tick}
							style={{
								width: 20,
								height: 20,
								resizeMode: "contain",
								marginRight: 10,
								opacity: this.state.password.length > 3 ? 1 : 0,
							}}
						/>
						<TextInput
							style={{
								width: "100%",
								fontSize: 16,
								textAlign: "left",
								color: "#FBFBFC",
								fontWeight: "200",
								fontFamily: "Gotham",
								letterSpacing: 0.26,
								padding: 5,
							}}
							placeholderTextColor={"#797979"}
							autoCapitalize='none'
							clearButtonMode='while-editing'
							autoCompleteType='password'
							secureTextEntry={true}
							textContentType='password'
							autoCorrect={false}
							placeholder={`Password`}
							value={this.state.password}
							// defaultValue={
							// 	this.state.password
							// }
							onChangeText={(text) => {
								this.setState({
									password: text,
								});
							}}
							onSubmitEditing={() => {
								// this.loginButton();
							}}
						/>
					</View>
				</View>

				<View
					style={{
						height: "100%",
						width: "100%",
						// alignItems: "center",
						// justifyContent: "center",
						marginTop: 15,
					}}>
					<Fade bottom>
						{!this.state.loading ? (
							<TouchableOpacity
								style={{
									width: "100%",
								}}
								disabled={this.state.loading}
								onPress={() => this.loginButton2()}>
								<View
									style={{
										backgroundColor:
											this.state.password.length > 6 &&
											this.state.emailValid
												? "#0068FF"
												: "#0068FF",
										width: "100%",
										padding: 13,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 15,
									}}>
									{this.state.loading ? (
										<ActivityIndicator />
									) : (
										<Text
											style={{
												color: "#FFF",
											}}>
											Login
										</Text>
									)}
								</View>
							</TouchableOpacity>
						) : (
							<View
								style={{
									backgroundColor: "#0068FF",
									padding: 6,
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 15,
									width: 30,
									height: 30,
									marginLeft: "auto",
									marginRight: "auto",
								}}>
								<ActivityIndicator color='#FFF' />
							</View>
						)}
					</Fade>
				</View>
			</View>
		);
	};

	render() {
		const defaultOptions = {
			loop: false,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let contain =
			this.state.screenWidth * 0.5 > this.state.screenHeight * 0.5
				? this.state.screenHeight * 0.5
				: this.state.screenWidth * 0.5;

		let maxContain = contain > 520 ? 520 : contain;

		let ar = 636 / 545;

		return (
			<View
				style={{
					flex: 1,
					backgroundColor: "#1F2229",
					height: "100%",
					overflow: "hidden",
				}}>
				<View
					style={{
						flex: 1,
						// width: 307,
						// height: "auto",
						alignItems: "center",
						justifyContent: "center",
						padding: 10,
						borderRadius: 25,
						maxWidth: 400,
						marginHorizontal: "auto",
						paddingBottom: 100,
					}}>
					<View
						style={{
							// flex: 0.5,
							// flexDirection: "row",
							alignItems: "center",
						}}>
						<AutoHeightImage
							width={120}
							source={require("./traq_app.png")}
						/>
					</View>

					<View
						style={{
							// width: this.props.width,
							width: "100%",
							maxWidth: 500,
							height: 50,
							marginBottom: 5,
							padding: 5,
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								backgroundColor: "#171D25",
								borderRadius: 8,
								alignItems: "center",
								width: "100%",
							}}>
							<View
								style={{
									width: "50%",
									left: this.state.viewqrScannerModal
										? "50%"
										: 0,
									position: "absolute",
									backgroundColor: "#0068FF",
									borderRadius: 8,
									height: 35,
									zIndex: 90,
								}}
							/>
							<View
								style={{
									flex: 1,
									alignItems: "center",
									flexDirection: "column",
									zIndex: 99,
								}}>
								<TouchableOpacity
									onPress={() => {
										this.setState({
											viewqrScannerModal: false,
										});
									}}>
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												fontSize: 11,
												color: "#FFF",
											}}>
											Login form
										</Text>
									</View>
								</TouchableOpacity>
							</View>
							<View
								style={{
									flex: 1,
									alignItems: "center",
									flexDirection: "column",
									zIndex: 99,
								}}>
								<TouchableOpacity
									onPress={() => {
										this.setState({
											viewqrScannerModal: true,
										});
									}}>
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												fontSize: 11,
												color: "#FFF",
											}}>
											QR scanner
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>
					</View>

					{this.state.errorLogin ? (
						<View
							style={{
								width: 300,
								marginTop: 15,
								padding: 10,
							}}>
							<Text
								style={{
									color: "#FF5F5F",
									fontSize: 12,
									textAlign: "center",
								}}>
								We are unable to verify your profile, please
								check your username password and try again.
							</Text>
						</View>
					) : null}
					{this.state.viewqrScannerModal ? (
						<View
							style={{
								flex: 1,
							}}>
							<QrCodeReader
								screenWidth={window.innerWidth}
								processQR={(d) => {
									alert(d);
								}}
								loginWithQrCode={async (a, b) => {
									await this.setState({
										username: a,
										password: b,
									});

									await this.loginButton2();
								}}
							/>
						</View>
					) : (
						this.loginForm()
					)}
					<Text
						style={{
							color: "#FFF",
							opacity: 0.5,
							fontSize: 11,
						}}>
						v2.1.1
					</Text>
					<Text
						style={{
							color: "#FFF",
							opacity: 0.5,
							fontSize: 11,
						}}>
						{this.state.deviceToken}
					</Text>
				</View>

				<View
					style={{
						// width: this.state.screenWidth / 2,
						width: "100%",
						position: "absolute",
						bottom: 30,
						left: 0,
						flexDirection: "row",
						justifyContent: "space-between",
						paddingRight: 30,
						paddingLeft: 30,
						// display: this.state.screenWidth < 700 ? "none" : "flex",
					}}>
					<View
						style={{
							width: "100%",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<Image
							source={require("./app_store.png")}
							style={{
								width: 130,
								height: 32,
								resizeMode: "contain",
							}}
						/>
						<Image
							source={require("./google_play.png")}
							style={{
								width: 130,
								height: 32,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>

				<View
					style={{
						position: "absolute",
						bottom: 0,
						left: 0,
						width: window.innerWidth,
					}}>
					<Text
						style={{
							backgroundColor: "transparent",
							color: "#FFF",
							bottom: 19,
							fontSize: 10,
							fontStyle: "normal",
							fontWeight: "bold",
							textAlign: "center",
						}}>
						© {moment().format("YYYY")} tetrice (pty) ltd
					</Text>
				</View>
			</View>
		);
	}
}

export const withNavigation = (Component: Component) => {
	return (props) => <Component {...props} navigate={useNavigate()} />;
};

export default withNavigation(index);
