import React, { Component } from "react";
import "./sheet.css";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class CommissionSheetPage extends Component {
	render() {
		const {
			logo,
			clientDetails,
			sheetTitle,
			dateRange,
			targetSales,
			actualSales,
			percentageAchieved,
			salesData,
			categoryCommissions,
		} = this.props;

		const renderSalesRow = (dayData, index) => (
			<tr key={index}>
				<td>{dayData.date}</td>
				<td>{dayData.sales}</td>
				{/* Add other relevant fields for daily sales */}
			</tr>
		);

		const totalSales = salesData.reduce(
			(total, day) => total + day.sales,
			0,
		);
		const totalCommission = categoryCommissions.reduce(
			(total, category) => total + category.commission,
			0,
		);

		return (
			<div
				className='commission-sheet'
				style={{ width: "100%", maxWidth: "1000px" }}>
				<table
					className='commission-table'
					style={{ width: "100%", borderCollapse: "collapse" }}>
					{/* Header Row */}
					<thead>
						<tr>
							<td colSpan='3'>
								<img
									src={logo}
									alt='App Logo'
									style={{ height: "50px" }}
								/>
							</td>
							<td colSpan='3'>
								<h3>{sheetTitle}</h3>
								<p>Date: {new Date().toLocaleDateString()}</p>
							</td>
							<td colSpan='3'>
								<p>{clientDetails}</p>
							</td>
						</tr>

						{/* Commission Sheet Title and Key Details */}
						<tr>
							<td colSpan='2'>
								<strong>{sheetTitle}</strong>
							</td>
							<td colSpan='2'>Date Range: {dateRange}</td>
							<td>
								<div className='labelText'>Target:</div>{" "}
								{currencyFormat(targetSales)}
							</td>
							<td>
								<div className='labelText'>Actual Sales:</div>{" "}
								{currencyFormat(actualSales)}
							</td>
							<td>Percentage Achieved: {percentageAchieved}%</td>
						</tr>
					</thead>

					{/* Sales Data Row */}
					<tbody>
						{salesData.map(renderSalesRow)}

						{/* Totals Row */}
						<tr>
							<td colSpan='4'>
								<strong>Total Sales</strong>
							</td>
							<td colSpan='3'>{totalSales}</td>
						</tr>

						{/* Commissions Row */}
						<tr>
							<td colSpan='4'>
								<strong>Commissions Earned per Category</strong>
							</td>
							{categoryCommissions.map((category, index) => (
								<td key={index}>
									{category.name}: {category.commission}
								</td>
							))}
						</tr>
					</tbody>

					{/* Signature Row */}
					<tfoot>
						<tr>
							<td colSpan='2'>
								<strong>Staff Signature:</strong> ______________
							</td>
							<td colSpan='2'>
								<strong>Manager Signature:</strong>{" "}
								______________
							</td>
							<td colSpan='2'>
								Date: {new Date().toLocaleDateString()}
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		);
	}
}

export default CommissionSheetPage;
