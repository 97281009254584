import React from "react";
import {
	Text,
	Alert,
	View,
	Keyboard,
	KeyboardAvoidingView,
	TouchableOpacity,
	TouchableWithoutFeedback,
	TextInput,
	StyleSheet,
	Image,
	ActivityIndicator,
	Platform,
} from "react-native";

import { APP_COLOURS } from "../APP_VARS";
import { api2_user_access } from "../Api";

export default class HomePasscode extends React.Component {
	constructor() {
		super();
		this.state = {
			password: "",
			loading: false,
			disabled: false,
			retailer: "retailer",
			mall: "mall",
			user1: "",
			user2: "",
			user3: "",
			attempts: 0,
		};

		this.myTextInput = React.createRef();
	}

	componentDidMount() {
		// this.getDeviceData();
	}

	// ontechSupport = () => {
	//   Actions.TechSupport1();
	// };

	navigateTo = async () => {
		await this.props.onPasscodeAccept();
	};

	async onValueChange(item, selectedValue) {
		try {
			await sessionStorage.setItem(item, selectedValue);
		} catch (error) {
			console.error(error);
		}
	}

	passcodeData = () => {
		if (this.state.password === this.props.passcode_id) {
			this.props.onPasscodeAccept();
			// this.loginUser();
			// Keyboard.dismiss();
			// this.setState({attempts: 0});
			// this.navigateTo();
		} else {
			if (this.state.attempts > 1) {
				alert(
					"Access denied! More than three passcode attempts submitted.",
				);
				Keyboard.dismiss();
				sessionStorage.clear();
				window.location.href = "./";
			} else {
				alert("Invalid Passcode");
				this.setState({
					attempts: this.state.attempts + 1,
				});
			}
		}
	};

	// loginUser = async () => {
	// 	this.setState({ loading: true, disabled: true });

	// 	let obj = {
	// 		auth_token: this.props.user_access_token,
	// 	};

	// 	let url = api2_user_access;

	// 	let req = {
	// 		method: "POST",
	// 		headers: {
	// 			Accept: "application/json",
	// 			"Content-Type": "application/json",
	// 			Origin: "",
	// 			dataType: "json",
	// 			Authorization: `Bearer ${this.props.jwt}`,
	// 		},
	// 		body: JSON.stringify(obj),
	// 	};
	// 	// console.log(url, req);
	// 	// return;
	// 	await fetch(url, req)
	// 		.then((response) => {
	// 			console.log(response.status);
	// 			if (response.status === 200) {
	// 				response
	// 					.json()
	// 					.then(async (responseData) => {
	// 						await console.log(
	// 							"=RETURN=",
	// 							responseData.user_auth_token,
	// 						);
	// 						// return;
	// 						// console.log(responseData.app_properties);
	// 						this.updateUserToken(responseData);
	// 					})
	// 					.catch((error) => {
	// 						console.error(error);
	// 						Alert.alert(
	// 							"Authentication failed, please check your credentials and try again.",
	// 						);
	// 					});
	// 			} else {
	// 				console.log(response);
	// 				this.setState({ loading: false, disabled: false });
	// 				Alert.alert(
	// 					"Authentication failed, please check your credentials and try again.",
	// 				);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.error(error);
	// 			Alert.alert(
	// 				"Authentication failed, please check your credentials and try again.",
	// 			);
	// 			this.setState({ loading: false, disabled: false });
	// 		});
	// };

	// updateUserToken = async (userData) => {
	// 	console.log("userupdateUserTokenIndex", this.props.user_id);
	// 	try {
	// 		// Retrieve the user data from sessionStorage
	// 		const value = await sessionStorage.getItem("users");
	// 		if (value !== null) {
	// 			let users = JSON.parse(value);

	// 			// Find the index of the user with the given user_id
	// 			const userIndex = users.findIndex(
	// 				(d) => d.id === this.props.user_id,
	// 			);

	// 			// Check if user is found
	// 			if (userIndex >= 0) {
	// 				// Update the auth token for the specified user
	// 				users[userIndex] = userData;
	// 				// console.log('userIndex', userIndex, this.props.user_id);

	// 				// Save the updated data back to sessionStorage
	// 				await sessionStorage.setItem(
	// 					"users",
	// 					JSON.stringify(users),
	// 				);
	// 				await sessionStorage.setItem(
	// 					"current_user",
	// 					JSON.stringify(users[userIndex]),
	// 				);
	// 				await sessionStorage.setItem(
	// 					"user_auth_token",
	// 					userData.user_auth_token,
	// 				);

	// 				// Dismiss the keyboard and navigate to 'main'
	// 				this.props.onPasscodeAccept();
	// 				await Keyboard.dismiss();
	// 			} else {
	// 				console.error("User not found");
	// 				return;
	// 			}
	// 		} else {
	// 			console.error("No users data found in sessionStorage");
	// 		}
	// 	} catch (error) {
	// 		console.error("Error updating user data:", error);
	// 	}
	// };

	render() {
		let logo = this.props.logo;
		// let background = this.props.brandcolour;

		return (
			<View
				style={{
					backgroundColor: "transparent",
					flex: 1,
					alignItems: "center",
					padding: 10,
				}}
				onPress={Keyboard.dismiss}>
				<TouchableWithoutFeedback onPress={Keyboard.dismiss}>
					<Image
						source={{
							uri: `data:image/png;base64,${logo}`,
						}}
						style={{
							resizeMode: "contain",
							marginTop: 30,
							marginBottom: 30,

							width: 150,
							height: 80,
						}}
					/>
				</TouchableWithoutFeedback>
				<View
					style={{
						// flex: 1,
						alignItems: "center",
						// justifyContent: 'center',
						marginBottom: 10,
						backgroundColor: APP_COLOURS.BG2,
						padding: 10,
						borderRadius: 24.5,
						maxWidth: 329,
						width: "100%",
					}}>
					<TextInput
						returnKeyType='done'
						autoCorrect={false}
						autoFocus
						placeholder='Passcode'
						secureTextEntry={true}
						keyboardType='number-pad'
						style={{
							color: this.props.app_properties.cardFont,
							fontSize: 18,
							fontStyle: "normal",
							fontWeight: "normal",
							textAlign: "center",
							backgroundColor: APP_COLOURS.BG3,
							borderRadius: 24.5,
							borderWidth: 1,
							borderColor: this.props.app_properties.cardFont,
							borderStyle: "solid",
							// paddingLeft: 26,
							maxWidth: 329,
							width: "100%",

							padding: 15,
						}}
						ref={this.myTextInput}
						placeholderTextColor={
							this.props.app_properties.cardFont
						}
						autoComplete='off'
						editable={true}
						onChangeText={(password) => this.setState({ password })}
						// ref='password'
						value={this.state.password}
					/>

					<TouchableOpacity
						disabled={this.state.disabled}
						activeOpacity={0.8}
						onPress={() => this.passcodeData()}
						// onPress={this.userLogin.bind(this)}
						style={{
							// backgroundColor: '#171D25',
							borderRadius: 24.5,
							// borderWidth: 1,
							// borderColor: "white",
							// borderStyle: "solid",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							padding: 0,
							maxWidth: 329,
							width: "100%",
							marginTop: 22,
							padding: 15,
							backgroundColor: APP_COLOURS.BLUE2,
							opacity: this.state.password !== "" ? 1 : 0.4,
						}}>
						{this.state.loading ? (
							<ActivityIndicator
								size='large'
								color={this.props.app_properties.cardFont}
							/>
						) : (
							<Text
								style={{
									color: this.props.app_properties.cardFont,
									fontFamily: "ArialMT",
									fontSize: 16,
									fontStyle: "normal",
									fontWeight: "normal",
									textAlign: "center",
								}}>
								Log in
							</Text>
						)}
					</TouchableOpacity>
				</View>
			</View>
		);
	}
}
