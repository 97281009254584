import React, { Component } from "react";
import {
	View,
	Text,
	TextInput,
	FlatList,
	TouchableOpacity,
	ScrollView,
	Image,
} from "react-native";
import { APP_COLOURS, screenHeight } from "../../APP_VARS";

const NATIONALITIES = [
	"South African",
	"Botswana",
	"Zimbabwean",
	"Afghan",
	"Albanian",
	"Algerian",
	"American",
	"Andorran",
	"Angolan",
	"Antiguans",
	"Argentinean",
	"Armenian",
	"Australian",
	"Austrian",
	"Azerbaijani",
	"Bahamian",
	"Bahraini",
	"Bangladeshi",
	"Barbadian",
	"Barbudans",
	"Belarusian",
	"Belgian",
	"Belizean",
	"Beninese",
	"Bhutanese",
	"Bolivian",
	"Bosnian",
	"Brazilian",
	"British",
	"Bruneian",
	"Bulgarian",
	"Burkinabe",
	"Burmese",
	"Burundian",
	"Cambodian",
	"Cameroonian",
	"Canadian",
	"Cape Verdean",
	"Central African",
	"Chadian",
	"Chilean",
	"Chinese",
	"Colombian",
	"Comoran",
	"Congolese",
	"Costa Rican",
	"Croatian",
	"Cuban",
	"Cypriot",
	"Czech",
	"Danish",
	"Djibouti",
	"Dominican",
	"Dutch",
	"East Timorese",
	"Ecuadorean",
	"Egyptian",
	"Emirian",
	"Equatorial Guinean",
	"Eritrean",
	"Estonian",
	"Ethiopian",
	"Fijian",
	"Filipino",
	"Finnish",
	"French",
	"Gabonese",
	"Gambian",
	"Georgian",
	"German",
	"Ghanaian",
	"Greek",
	"Grenadian",
	"Guatemalan",
	"Guinea-Bissauan",
	"Guinean",
	"Guyanese",
	"Haitian",
	"Herzegovinian",
	"Honduran",
	"Hungarian",
	"I-Kiribati",
	"Icelander",
	"Indian",
	"Indonesian",
	"Iranian",
	"Iraqi",
	"Irish",
	"Israeli",
	"Italian",
	"Ivorian",
	"Jamaican",
	"Japanese",
	"Jordanian",
	"Kazakhstani",
	"Kenyan",
	"Kittian and Nevisian",
	"Kuwaiti",
	"Kyrgyz",
	"Laotian",
	"Latvian",
	"Lebanese",
	"Liberian",
	"Libyan",
	"Liechtensteiner",
	"Lithuanian",
	"Luxembourger",
	"Macedonian",
	"Malagasy",
	"Malawian",
	"Malaysian",
	"Maldivan",
	"Malian",
	"Maltese",
	"Marshallese",
	"Mauritanian",
	"Mauritian",
	"Mexican",
	"Micronesian",
	"Moldovan",
	"Monacan",
	"Mongolian",
	"Moroccan",
	"Mosotho",
	"Motswana",
	"Mozambican",
	"Namibian",
	"Nauruan",
	"Nepalese",
	"New Zealander",
	"Nicaraguan",
	"Nigerian",
	"Nigerien",
	"North Korean",
	"Northern Irish",
	"Norwegian",
	"Omani",
	"Pakistani",
	"Palauan",
	"Panamanian",
	"Papua New Guinean",
	"Paraguayan",
	"Peruvian",
	"Polish",
	"Portuguese",
	"Qatari",
	"Romanian",
	"Russian",
	"Rwandan",
	"Saint Lucian",
	"Salvadoran",
	"Samoan",
	"San Marinese",
	"Sao Tomean",
	"Saudi Arabian",
	"Scottish",
	"Senegalese",
	"Serbian",
	"Seychellois",
	"Sierra Leonean",
	"Singaporean",
	"Slovak",
	"Slovene",
	"Solomon Islander",
	"Somali",

	"South Korean",
	"Spanish",
	"Sri Lankan",
	"Sudanese",
	"Surinamese",
	"Swazi",
	"Swedish",
	"Swiss",
	"Syrian",
	"Taiwanese",
	"Tajik",
	"Tanzanian",
	"Thai",
	"Togolese",
	"Tongan",
	"Trinidadian or Tobagonian",
	"Tunisian",
	"Turkish",
	"Tuvaluan",
	"Ugandan",
	"Ukrainian",
	"Uruguayan",
	"Uzbekistani",
	"Venezuelan",
	"Vietnamese",
	"Welsh",
	"Yemenite",
	"Zambian",
];

export default class NationalityPicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: "",
			selectedNationality:
				typeof this.props.value !== "undefined" ? this.props.value : "",

			choose: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				selectedNationality: this.props.value,
			});
		}
	}

	renderItem = ({ item }) => {
		return (
			<TouchableOpacity
				style={{
					padding: 10,
					backgroundColor:
						this.state.selectedNationality === item
							? APP_COLOURS.GREENCOMPLETE
							: "transparent",
					borderRadius: 3,
					marginBottom: 1,
				}}
				onPress={() => {
					this.props.update(item);
					this.setState({ selectedNationality: item, choose: false });
				}}>
				<Text
					style={{
						fontSize: 16,
						fontWeight: "600",
						color: "#FFF",
					}}>
					{item}
				</Text>
			</TouchableOpacity>
		);
	};

	render() {
		const { query, selectedNationality } = this.state;

		// Filter the nationalities based on the user's search query
		const filteredNationalities = NATIONALITIES.filter((nationality) =>
			nationality.toLowerCase().includes(query.toLowerCase()),
		);

		return (
			<View
				style={{
					flex: 1,
					zIndex: 10,

					// padding: 10,
					width: "100%",
					// borderWidth: 0.3,
					// borderColor: APP_COLOURS.BG3,

					padding: 10,
				}}>
				{!this.state.choose ? (
					<TouchableOpacity
						onPress={() => {
							this.setState({
								choose: true,
								selectedNationality: "",
							});
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								marginBottom: 5,
							}}>
							<Text
								style={{
									// fontWeight: "500",
									// fontSize: 15,

									// color: "#000",
									fontSize: 14,
									fontWeight: "400",
									color: "#FFF",
									opacity: 0.6,
								}}>
								{typeof this.props.label !== "undefined"
									? this.props.label
									: ""}{" "}
								Nationality:
							</Text>
							<View
								style={{
									width: 20,
									height: 20,
									borderRadius: 7,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor:
										selectedNationality !== ""
											? APP_COLOURS.GREEN
											: "transparent",
									opacity:
										selectedNationality !== "" ? 1 : 0.1,
								}}>
								<Image
									source={require("../assets/images/check.png")}
									style={{
										width: 11,
										height: 11,
										resizeMode: "contain",
									}}
								/>
							</View>
						</View>
						<View
							style={{
								paddingRight: 10,
								marginBottom: 15,
								paddingLeft: 20,
								borderRadius: 10,
								padding: 20,
								backgroundColor: APP_COLOURS.BG3,
							}}>
							{selectedNationality === "" ? (
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontSize: 16,
											fontWeight: "400",
											color: "#FFF",
											// opacity: 0.8,
										}}>
										Select a nationality
									</Text>
								</View>
							) : (
								<>
									<View
										style={{
											flexDirection: "row",
											justifyContent: "space-between",
										}}>
										<Text
											style={{
												fontWeight:
													this.state
														.selectedNationality !==
													""
														? "600"
														: "300",
												// fontSize: 17,
												// color: "#000",
												fontSize: 16,
												fontWeight: "600",
												color: "#FFF",
												// opacity: 0.8,
											}}>
											{" "}
											{selectedNationality}
										</Text>
									</View>
								</>
							)}
						</View>
					</TouchableOpacity>
				) : (
					<View
						style={{
							flex: 1,
							height: "100%",
							// maxHeight: 150,
							// padding: 10,
							maxHeight: 500,
							// display: this.state.choose ? "flex" : "none",
							backgroundColor: APP_COLOURS.BG4,
							borderRadius: 10,
						}}>
						<TextInput
							style={{
								padding: 8,
								borderRadius: 5,
								borderBottomWidth: 0.5,
								borderColor: "#797979",
								color: APP_COLOURS.TEXTCOLOR,
								padding: 15,
							}}
							autoFocus
							placeholder='Type a nationality'
							value={query}
							onChangeText={(text) =>
								this.setState({ query: text })
							}
						/>
						<ScrollView>
							<View
								style={{
									height: 10,
								}}
							/>
							<FlatList
								data={
									query !== ""
										? filteredNationalities
										: NATIONALITIES
								}
								keyExtractor={(item) => item}
								renderItem={this.renderItem}
							/>
							<View
								style={{
									height: 10,
								}}
							/>
						</ScrollView>
						{/* <Image
            source={require('../../assets/images/fade.png')}
            style={{
              width: '100%',
              height: 25,
              resizeMode: 'stretch',
              position: 'absolute',
              bottom: 0,
            }}
          /> */}
					</View>
				)}
			</View>
		);
	}
}
