import React, { Component } from "react";
import { View, LayoutAnimation } from "react-native";
import { APP_COLOURS, CustomLayoutSpring } from "../../APP_VARS";

export default class ProgressBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			progressPerc: "0%",
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				progressPerc: this.props.progress,
			});
			LayoutAnimation.configureNext(CustomLayoutSpring);
		}, 300);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.progress !== this.props.progress) {
			LayoutAnimation.configureNext(CustomLayoutSpring);
			this.setState({
				progressPerc: this.props.progress,
			});
		}
	}

	render() {
		return (
			<View
				style={{
					// flex: 1,
					backgroundColor: APP_COLOURS.WHITE_TRANSPARENT1,
					height: 8,
					borderRadius: 3,
					overflow: "hidden",
				}}>
				<View
					style={{
						width: this.state.progressPerc,
						backgroundColor: APP_COLOURS.ORANGE,
						height: 8,
						borderRadius: 3,
					}}
				/>
			</View>
		);
	}
}
