import React, { Component, useEffect, useState } from "react";
import {
	StyleSheet,
	ActivityIndicator,
	Text,
	TouchableOpacity,
	View,
	ScrollView,
	Image,
	TextInput,
} from "react-native";
import { useParams } from "react-router-dom";
import AutoHeightImage from "../AutoHeightImageComp";
import { APP_COLOURS } from "../../APP_VARS";
import { crudGetCommissions, getSalesRange } from "../../CRUDHELPER";
import CommissionSheetPage from "./CommissionSheetPage";
import moment from "moment";
import ReactToPrint from "react-to-print";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class ComissionSheets extends Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundcolour: APP_COLOURS.BG2,
			fontcolour: APP_COLOURS.DESELECTEDCOLOR,
			data: [],
			loading: true,
			comissions: [],
			showCommissionSheet: false,
		};
	}

	componentDidMount() {
		this.getCommissions();
	}

	getCommissions = () => {
		let obj = {
			staff_id: this.props.user_id,
		};

		crudGetCommissions(obj, (s, d) => {
			this.setState({ loading: false, comissions: d });
		});
	};

	getSales = (from, till) => {
		this.setState({
			sales: [],
		});
		let id = this.props.user_id;
		let obj = {
			staff_id: id,
			dateFrom: from,
			dateTill: till,
		};

		getSalesRange(obj, (s, result) => {
			console.log(result);
			this.setState({
				sales: result,
				showCommissionSheet: true,
				loading: false,
			});

			// Calculate and display metrics
			const totalTrs = result.reduce(
				(sum, item) => sum + parseFloat(item.trs),
				0,
			);
			const averageItp =
				result.reduce((sum, item) => sum + parseFloat(item.ipt), 0) /
				result.length;
			const totalIpt = result.reduce(
				(sum, item) => sum + parseFloat(item.ipt),
				0,
			);
			const averageArs =
				result.reduce((sum, item) => sum + parseFloat(item.ars), 0) /
				result.length;

			let percToTarget = totalTrs / parseFloat(this.state.targetAmount);
			percToTarget = percToTarget >= 1 ? 1 : percToTarget;

			// Display metrics
			console.log(`Total TRS: R${totalTrs.toFixed(2)}`);
			console.log(`Average IPT: R${averageItp.toFixed(2)}`);
			console.log(`Total IPT: R${totalIpt.toFixed(2)}`);
			console.log(`Average ARS: R${averageArs.toFixed(2)}`);

			// Update state with metrics
			this.setState({
				totalTrs: totalTrs,
				averageItp: averageItp,
				totalIpt: totalIpt,
				averageArs: averageArs,
				actualSales: totalTrs.toFixed(2),
				percToTarget: percToTarget,
				showCommissionSheet: true,
			});
		});
	};

	render() {
		const { data, loading } = this.state;

		return (
			<View
				style={{
					flex: 1,
					// backgroundColor: APP_COLOURS.BG,
					backgroundColor: "#FFF",
					// width: window.innerWidth,
					height: window.innerHeight,
					alignItems: "center",
				}}>
				{this.state.loading ? (
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							paddingBottom: 100,
						}}>
						<View
							style={[
								{
									backgroundColor: APP_COLOURS.BG4,
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 13,
									padding: 50,
									marginRight: 5,
									marginBottom: 5,
									opacity: 0.7,
								},
							]}>
							<Text
								style={{
									color: APP_COLOURS.DESELECTEDCOLOR,
									fontFamily: "Avenir",
									fontWeight: "600",
								}}>
								Loading...
							</Text>
						</View>
					</View>
				) : (
					<View style={{ flex: 1, width: "100%" }}>
						{!this.state.showCommissionSheet ? (
							<View
								style={{
									flex: 1,
									// justifyContent: "center",
									alignItems: "center",
									padding: 10,
								}}>
								<View
									style={{
										width: "100%",
										// backgroundColor: APP_COLOURS.BG2,
										borderRadius: 10,
										padding: 10,
									}}>
									<Text
										numberOfLines={2}
										style={{
											fontFamily: "Avenir",
											fontSize: 18,
											fontWeight: "800",

											flexWrap: "wrap",
											color: APP_COLOURS.WHITE,
											marginRight: 10,
											marginBottom: 10,
											marginTop: 30,
										}}>
										Previous targets
									</Text>
									{/* .filter((a) =>
											moment(moment()).isSameOrAfter(
												a.endDate,
											),
										) */}
									{this.state.comissions.map((d, i) => (
										<View
											key={`comm${i}`}
											style={{
												padding: 15,
												borderRadius: 10,
												backgroundColor:
													APP_COLOURS.BG3,
												marginBottom: 3,
											}}>
											<View
												style={{
													height: 60,
													width: 4,
													borderRadius: 2,
													backgroundColor:
														"rgba(255, 156, 0, 1)",
													position: "absolute",
													left: 5,
													top: 10,
												}}
											/>
											<TouchableOpacity
												onPress={() => {
													this.setState({
														showSales: false,
														showStats: false,
													});
													this.setState({
														loading: true,
														targetAmount: d.target,
													});
													console.log(d);
													this.getSales(
														d.startFilter,
														d.endFilter,
													);
												}}
												style={{
													alignItems: "center",

													borderRadius: 10,
													padding: 4,
												}}>
												<View
													style={{
														flexDirection: "row",
														width: "100%",
														justifyContent:
															"space-between",
													}}>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 16,
															fontWeight: "600",
															color: "#FFF",
														}}>
														{d.title}
													</Text>

													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 16,
															fontWeight: "600",
															color: "#FFF",
														}}>
														{currencyFormat(
															parseFloat(
																d.target,
															),
														)}
													</Text>
												</View>
												<View
													style={{
														flexDirection: "row",
														width: "100%",
														// justifyContent: "space-between",
														opacity: 0.6,
													}}>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 15,
															fontWeight: "400",
															color: "#FFF",
														}}>
														{moment(
															d.startDate,
														).format("DD MMM")}
													</Text>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 15,
															fontWeight: "400",
															color: "#FFF",
															pading: 5,
															paddingHorizontal: 15,
														}}>
														-
													</Text>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 15,
															fontWeight: "400",
															color: "#FFF",
														}}>
														{moment(
															d.endDate,
														).format("DD MMM")}
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									))}

									{!this.state.loading &&
									this.state.comissions === 0 ? (
										<View
											style={{
												flex: 1,
												justifyContent: "center",
												alignItems: "center",
												paddingBottom: 100,
											}}>
											<View
												style={[
													{
														backgroundColor:
															APP_COLOURS.BG4,
														justifyContent:
															"center",
														alignItems: "center",
														borderRadius: 13,
														padding: 50,
														marginRight: 5,
														marginBottom: 5,
														opacity: 0.7,
													},
												]}>
												<Text
													style={{
														color: APP_COLOURS.DESELECTEDCOLOR,
														fontFamily: "Avenir",
														fontWeight: "600",
													}}>
													No results found
												</Text>
											</View>
										</View>
									) : null}
								</View>
							</View>
						) : (
							<ScrollView style={styles.container}>
								<div>
									<ReactToPrint
										trigger={() => (
											<button>Save as PDF</button>
										)}
										content={() => this.componentRef}
										pageStyle={`@page { size: A4 landscape; margin: 20mm; }`}
									/>
									<CommissionSheetPage
										ref={(el) => (this.componentRef = el)}
										logo={require("../assets/images/traq_app.png")}
										clientDetails='Elizabeth Arden SA (Pty) Ltd'
										sheetTitle='Commission Sheet'
										dateRange='01/01/2024 - 31/01/2024'
										targetSales={50000}
										actualSales={45000}
										percentageAchieved={90}
										salesData={this.state.sales}
										categoryCommissions={[
											{
												name: "Category 1",
												commission: 500,
											},
											{
												name: "Category 2",
												commission: 300,
											},
											// Add more commission data as needed
										]}
									/>
								</div>
							</ScrollView>
						)}
					</View>
				)}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: APP_COLOURS.BG2,
		padding: 10,
	},
	loadingContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: APP_COLOURS.BG2,
	},
	button: {
		backgroundColor: APP_COLOURS.PRIMARY,
		padding: 15,
		borderRadius: 5,
		marginVertical: 10,
	},
	buttonText: {
		color: APP_COLOURS.TEXT,
		fontSize: 16,
		textAlign: "center",
	},
});

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		const params = useParams();
		const { user_id, token } = params;
		useEffect(() => {
			const fetchUserData = async (token) => {
				try {
					const response = await fetch(
						`https://workflow.tetrice.co.za/webhook/79d646ab-dd9b-4410-a905-62b6694f65c8`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`, // Set Bearer token in the Authorization header
							},
							body: JSON.stringify({
								user_id: user_id,
							}),
						},
					);

					if (!response.ok) {
						throw new Error("Failed to fetch user data");
					}

					const userData = await response.json();

					// Set user data in localStorage
					localStorage.setItem("user_data", JSON.stringify(userData));
				} catch (error) {
					console.error("Error fetching user data:", error);
				}
			};

			if (user_id && token) {
				localStorage.setItem("user_id", user_id);
				localStorage.setItem("accessToken", token);

				// Fetch user data using the Bearer token
				fetchUserData(token);
			}
		}, [params]);

		return <Component {...props} params={params} user_id={user_id} />;
	}

	return ComponentWithRouterProp;
}
export default withRouter(ComissionSheets);
