import React, { useState } from "react";
import {
	Modal,
	View,
	Text,
	Button,
	StyleSheet,
	ScrollView,
} from "react-native";

const TermsOfCommission = ({ visible, onAccept }) => {
	return (
		<Modal
			animationType='slide'
			transparent={true}
			visible={visible}
			onRequestClose={() => {}}>
			<View style={styles.modalContainer}>
				<View style={styles.modalContent}>
					<ScrollView>
						<Text style={styles.headerText}>
							Elizabeth Arden Commission Policy
						</Text>
						<Text style={styles.titleText}>General rules</Text>
						<Text style={styles.bodyText}>
							1. All targets, recorded sales and payments are made
							on Retail Sales Excluding VAT. {"\n"}
							2. If the completed Personal Details document has
							not been received at Head Office, no commission will
							be paid. It is the Area Manager’s responsibility to
							confirm with the administrator that the document has
							been received. {"\n"}
							3. Targets will be confirmed and communicated to
							BA’s by their Area Managers monthly or quarterly.{" "}
							{"\n"}
							4. This policy may be cancelled or amended at the
							discretion of Management. {"\n"}
						</Text>

						<Text style={styles.titleText}>
							Beauty Advisors Commission Structure
						</Text>
						<Text style={styles.bodyText}>
							Performance to Target Commission Payout {"\n"}
							Less than 95% Zero commission paid out {"\n"}
							95% to less than 100% 2% of sales {"\n"}
							101% to less than 109% 3% of sales {"\n"}
							110 to less than 119% 4% of sales {"\n"}
							120% or more 5% of sales {"\n"}
						</Text>

						<Text style={styles.titleText}>Multiple BA Doors:</Text>
						<Text style={styles.bodyText}>
							Where stores are manned by more than 1 BA, the BA’s
							will be individually targeted based on working
							hours. If the counter achieves the total target (sum
							of the BA individual targets), the above Beauty
							Advisors Commission Structure will apply to the BA’s
							individually. If the Counter does not achieve
							target, but an individual achieves 95% or more of
							their individual target, that BA will earn a maximum
							of 2% commission.
						</Text>

						<Text style={styles.titleText}>Foschini Doors:</Text>
						<Text style={styles.bodyText}>
							When the BA achieves 100% of the counter target,
							Elizabeth Arden pays 1% commission to the BA as
							Foschini pays 2% commission to the BA from rand one.
						</Text>
					</ScrollView>

					<View style={styles.buttonContainer}>
						<Button
							title='Agree and Accept'
							onPress={onAccept}
							color='#000'
						/>
					</View>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	modalContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	modalContent: {
		width: "90%",
		backgroundColor: "#fff",
		padding: 20,
		borderRadius: 10,
		maxHeight: "80%",
	},
	headerText: {
		fontFamily: "Avenir",
		fontSize: 18,
		fontWeight: "600",
		marginBottom: 10,
		color: "black",
	},
	titleText: {
		fontFamily: "Avenir",
		fontSize: 16,
		fontWeight: "500",
		marginTop: 10,
		color: "black",
	},
	bodyText: {
		fontFamily: "Avenir",
		fontSize: 14,
		fontWeight: "200",
		marginVertical: 5,
		color: "black",
	},
	buttonContainer: {
		marginTop: 20,
	},
});

export default TermsOfCommission;
