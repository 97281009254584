import React, { Component, useEffect, useState } from "react";
import {
	StyleSheet,
	ActivityIndicator,
	Text,
	TouchableOpacity,
	View,
	ScrollView,
	Image,
	TextInput,
} from "react-native";
import { useParams } from "react-router-dom";
import AutoHeightImage from "../AutoHeightImageComp";
import { APP_COLOURS } from "../../APP_VARS";
import moment from "moment";
import { getAllSales } from "../../CRUDHELPER";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class SalesHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundcolour: APP_COLOURS.BG2,
			fontcolour: APP_COLOURS.DESELECTEDCOLOR,
			sales: [],
			loading: true,
			height: window.innerHeight,
			width: window.innerWidth,
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData = async () => {
		this.setState({ data: [], loading: false });
		getAllSales(
			{
				user_id: this.props.user_id,
			},
			(s, d) => {
				this.setState({
					sales: d,
					loading: false,
				});
			},
		);
	};

	render() {
		const { sales, loading } = this.state;
		let backgroundcolour = this.state.backgroundcolour;

		// if (loading) {
		// 	return (
		// 		<View style={styles.loadingContainer}>
		// 			<ActivityIndicator
		// 				size='large'
		// 				color={APP_COLOURS.PRIMARY}
		// 			/>
		// 		</View>
		// 	);
		// }

		return (
			<View
				style={{
					flex: 1,
					// backgroundColor: backgroundcolour,
					backgroundColor: APP_COLOURS.BG,
					height: window.innerHeight,
					width: window.innerWidth,
					overflow: "hidden",
				}}>
				{!this.state.loading && sales.length === 0 ? (
					<View
						style={{
							flex: 1,
							backgroundColor: APP_COLOURS.BG,
							justifyContent: "center",
							alignItems: "center",
							paddingBottom: 100,
							height: window.innerHeight,
							width: window.innerWidth,
						}}>
						<View
							style={[
								{
									backgroundColor: APP_COLOURS.BG4,
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 13,
									padding: 50,
									marginRight: 5,
									marginBottom: 5,
									opacity: 0.7,
								},
							]}>
							<Text
								style={{
									color: APP_COLOURS.DESELECTEDCOLOR,
									fontFamily: "Avenir",
									fontWeight: "600",
								}}>
								No results found
							</Text>
						</View>
					</View>
				) : (
					<View
						style={{
							width: this.state.width,
							height: this.state.height,
							backgroundColor: APP_COLOURS.BG,
						}}>
						<ScrollView>
							<View
								style={{
									width: "100%",
									// backgroundColor: APP_COLOURS.BG2,
									borderRadius: 10,
									marginBottom: 100,
									marginTop: 20,
								}}>
								{/* <Text
								numberOfLines={2}
								style={{
									fontFamily: "Avenir",
									fontSize: 18,
									fontWeight: "800",

									flexWrap: "wrap",
									color: APP_COLOURS.WHITE,
									marginRight: 10,
									marginBottom: 10,
									padding: 10,
								}}>
								Sales history
							</Text> */}
								{this.state.sales.map((d, i) => (
									<View
										key={`sale${d.sale_id}`}
										style={{
											// padding: 10,
											// borderRadius: 10,
											backgroundColor: APP_COLOURS.BG3,
											marginBottom: 3,
											padding: 20,
										}}>
										<TouchableOpacity
											onPress={() => {}}
											style={{
												// alignItems: "center",
												width: "100%",

												borderRadius: 10,
												padding: 4,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													fontWeight: "400",
													color: "#FFF",
												}}>
												{moment(d.date).format(
													"DD/MM/YYYY HH:mm",
												)}
											</Text>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													fontWeight: "400",
													color: "#FFF",
													opacity: 0.6,
												}}>
												{d.sale_id}
											</Text>
											{d.items.map((dd) => (
												<View
													style={{
														flexDirection: "row",
														width: "100%",
														justifyContent:
															"space-between",
														opacity: 0.6,
													}}>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 12,
															fontWeight: "400",
															color: "#FFF",
														}}>
														- {dd.title}
													</Text>

													<View
														style={{
															flexDirection:
																"row",
														}}>
														<Text
															style={{
																fontFamily:
																	"Avenir",
																fontSize: 12,
																fontWeight:
																	"400",
																color: "#FFF",
																marginRight: 10,
															}}>
															({dd.qty})
														</Text>

														<Text
															style={{
																fontFamily:
																	"Avenir",
																fontSize: 12,
																fontWeight:
																	"400",
																color: "#FFF",
															}}>
															{currencyFormat(
																parseFloat(
																	dd.price,
																),
															)}
														</Text>
													</View>
												</View>
											))}
											{/* <View
											style={{
												flexDirection: "row",
												width: "100%",
												// justifyContent: "space-between",
												opacity: 0.6,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 15,
													fontWeight: "400",
													color: "#FFF",
												}}>
												{moment(d.startDate).format(
													"DD MMM",
												)}
											</Text>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 15,
													fontWeight: "400",
													color: "#FFF",
													pading: 5,
													paddingHorizontal: 15,
												}}>
												-
											</Text>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 15,
													fontWeight: "400",
													color: "#FFF",
												}}>
												{moment(d.endDate).format(
													"DD MMM",
												)}
											</Text>
										</View> */}

											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 15,
													fontWeight: "500",
													color: "#FFF",
												}}>
												{currencyFormat(
													parseFloat(d.trs),
												)}
											</Text>
										</TouchableOpacity>
									</View>
								))}
							</View>
						</ScrollView>
					</View>
				)}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: APP_COLOURS.BG,
		padding: 10,
	},
	loadingContainer: {
		height: window.innerHeight,
		width: window.innerWidth,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: APP_COLOURS.BG,
	},
	button: {
		backgroundColor: APP_COLOURS.PRIMARY,
		padding: 15,
		borderRadius: 5,
		marginVertical: 10,
	},
	buttonText: {
		color: APP_COLOURS.TEXT,
		fontSize: 16,
		textAlign: "center",
	},
});

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		const params = useParams();
		const { user_id, token } = params;
		useEffect(() => {
			const fetchUserData = async (token) => {
				try {
					const response = await fetch(
						`https://workflow.tetrice.co.za/webhook/79d646ab-dd9b-4410-a905-62b6694f65c8`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`, // Set Bearer token in the Authorization header
							},
							body: JSON.stringify({
								user_id: user_id,
							}),
						},
					);

					if (!response.ok) {
						throw new Error("Failed to fetch user data");
					}

					const userData = await response.json();

					// Set user data in localStorage
					localStorage.setItem("user_data", JSON.stringify(userData));
				} catch (error) {
					console.error("Error fetching user data:", error);
				}
			};

			if (user_id && token) {
				localStorage.setItem("user_id", user_id);
				localStorage.setItem("accessToken", token);

				// Fetch user data using the Bearer token
				fetchUserData(token);
			}
		}, [params]);

		return <Component {...props} params={params} user_id={user_id} />;
	}

	return ComponentWithRouterProp;
}
export default withRouter(SalesHistory);
