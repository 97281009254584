import React, { Component, useEffect, useState } from "react";
import {
	StyleSheet,
	ActivityIndicator,
	Text,
	TouchableOpacity,
	View,
	ScrollView,
	Image,
	TextInput,
} from "react-native";
import { useParams } from "react-router-dom";
import { APP_COLOURS } from "../../APP_VARS";
import { crudGetCommissions, getSalesRange } from "../../CRUDHELPER";
import moment from "moment";
import BASalesComm from "./BASalesComm";
import TermsOfCommission from "./TermsOfComission";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

class Targets extends Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundcolour: APP_COLOURS.BG2,
			fontcolour: APP_COLOURS.DESELECTEDCOLOR,

			termsAccepted: false,
			data: [],
			loading: true,

			sales: [],
			showCommission: false,
			showStats: false,
			comissions: [],
			showSales: false,
			targetAmount: 0,
			actualSales: 0,

			totalTrs: 0,
			averageItp: 0,
			totalIpt: 0,
			averageArs: 0,
			percToTarget: 0,
		};
	}

	componentDidMount() {
		this.getCommissions();
	}

	getCommissions = () => {
		let obj = {
			staff_id: this.props.user_id,
		};

		crudGetCommissions(obj, (s, d) => {
			this.setState({ loading: false, comissions: d });
		});
	};

	getSales = (from, till) => {
		this.setState({
			sales: [],
		});
		let id = this.props.user_id;
		let obj = {
			staff_id: id,
			dateFrom: from,
			dateTill: till,
		};

		getSalesRange(obj, (s, result) => {
			console.log(result);
			this.setState({
				sales: result,
				showSales: true,
				loading: false,
			});

			// Calculate and display metrics
			const totalTrs = result.reduce(
				(sum, item) => sum + parseFloat(item.trs),
				0,
			);
			const averageItp =
				result.reduce((sum, item) => sum + parseFloat(item.ipt), 0) /
				result.length;
			const totalIpt = result.reduce(
				(sum, item) => sum + parseFloat(item.ipt),
				0,
			);
			const averageArs =
				result.reduce((sum, item) => sum + parseFloat(item.ars), 0) /
				result.length;

			let percToTarget = totalTrs / parseFloat(this.state.targetAmount);
			percToTarget = percToTarget >= 1 ? 1 : percToTarget;

			// Display metrics
			console.log(`Total TRS: R${totalTrs.toFixed(2)}`);
			console.log(`Average IPT: R${averageItp.toFixed(2)}`);
			console.log(`Total IPT: R${totalIpt.toFixed(2)}`);
			console.log(`Average ARS: R${averageArs.toFixed(2)}`);

			// Update state with metrics
			this.setState({
				totalTrs: totalTrs,
				averageItp: averageItp,
				totalIpt: totalIpt,
				averageArs: averageArs,
				actualSales: totalTrs.toFixed(2),
				percToTarget: percToTarget,
				showStats: true,
			});
		});
	};

	render() {
		const { data, loading } = this.state;
		let backgroundcolour = this.state.backgroundcolour;

		if (loading) {
			return (
				<View style={styles.loadingContainer}>
					<ActivityIndicator size='large' color={"#FFF"} />
				</View>
			);
		}

		return (
			<View
				style={{
					flex: 1,
					// backgroundColor: backgroundcolour,
					backgroundColor: APP_COLOURS.BG,
					height: window.innerHeight,
					// width: window.innerWidth,
					// overflow: "hidden",
				}}>
				<View style={{ flex: 1, width: "100%" }}>
					{this.state.showSales ? (
						<View
							style={{
								flex: 1,
								borderRadius: 10,
								backgroundColor: APP_COLOURS.BG,
								padding: 10,
								width: "100%",
							}}>
							<ScrollView showsVerticalScrollIndicator={false}>
								{this.state.showStats ? (
									<BASalesComm
										data={this.state.sales}
										targetAmount={this.state.targetAmount}
										actualSales={this.state.actualSales}
										averageArs={this.state.averageArs}
										averageItp={this.state.averageItp}
										totalUnitsSold={this.state.totalIpt}
										percToTarget={this.state.percToTarget}
									/>
								) : null}

								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "flex-end",
										marginBottom: 10,
										paddingBottom: 10,
										borderBottomColor: "#797979",
										borderBottomWidth: 0.3,
										width: "100%",
									}}>
									{/* {this.state.showSearch ? ( */}
									<TextInput
										ref={(ref) => {
											this.filterByName = ref;
										}}
										style={{
											padding: 10,
											fontSize: 16,
											borderRadius: 10,
											width: "100%",
											color: "#FFF",
											fontWeight:
												this.state.filterByName === ""
													? "200"
													: "500",
											fontFamily: "Avenir",

											backgroundColor:
												"rgba(255, 255, 255, 0.05)",
											marginRight: 5,
											paddingLeft: 15,
											marginRight: 10,
											paddingHorizontal: 20,
										}}
										placeholderTextColor={APP_COLOURS.BG3}
										autoCapitalize='words'
										// autoFocus={true}
										clearButtonMode='while-editing'
										editable={true}
										placeholder={`Type something`}
										onChangeText={(text) => {
											this.setState({
												filterByName: text,
											});
										}}
									/>
								</View>

								{this.state.sales.map((d, i) =>
									this.salesItem(d, i),
								)}
								{this.state.loading ? (
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
											minHeight: 50,
										}}>
										<ActivityIndicator color={"#FFF"} />
									</View>
								) : null}
							</ScrollView>
						</View>
					) : (
						<View
							style={{
								flex: 1,
								// justifyContent: "center",
								alignItems: "center",
								padding: 10,
							}}>
							<View
								style={{
									width: "100%",
									// backgroundColor: APP_COLOURS.BG2,
									borderRadius: 10,
									padding: 10,
								}}>
								<Text
									numberOfLines={2}
									style={{
										fontFamily: "Avenir",
										fontSize: 18,
										fontWeight: "800",

										flexWrap: "wrap",
										color: APP_COLOURS.WHITE,
										marginRight: 10,
										marginBottom: 10,
									}}>
									Targets
								</Text>
								{this.state.comissions
									.filter(
										(a) =>
											moment(moment()).isSameOrAfter(
												a.startDate,
											) &&
											moment(moment()).isSameOrBefore(
												a.endDate,
											),
									)
									.map((d, i) => (
										<View
											key={`comm${i}`}
											style={{
												padding: 15,
												borderRadius: 10,
												backgroundColor:
													APP_COLOURS.BG3,
												marginBottom: 3,
											}}>
											<View
												style={{
													height: 60,
													width: 4,
													borderRadius: 2,
													backgroundColor:
														APP_COLOURS.GREEN,
													position: "absolute",
													left: 5,
													top: 10,
												}}
											/>
											<TouchableOpacity
												onPress={() => {
													this.setState({
														showSales: false,
														showStats: false,
													});
													this.setState({
														loading: true,
														targetAmount: d.target,
													});
													console.log(d);
													this.getSales(
														d.startFilter,
														d.endFilter,
													);
												}}
												style={{
													alignItems: "center",

													borderRadius: 10,
													padding: 4,
												}}>
												<View
													style={{
														flexDirection: "row",
														width: "100%",
														justifyContent:
															"space-between",
													}}>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 16,
															fontWeight: "600",
															color: "#FFF",
														}}>
														{d.title}
													</Text>

													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 16,
															fontWeight: "600",
															color: "#FFF",
														}}>
														{currencyFormat(
															parseFloat(
																d.target,
															),
														)}
													</Text>
												</View>
												<View
													style={{
														flexDirection: "row",
														width: "100%",
														// justifyContent: "space-between",
														opacity: 0.6,
													}}>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 15,
															fontWeight: "400",
															color: "#FFF",
														}}>
														{moment(
															d.startDate,
														).format("DD MMM")}
													</Text>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 15,
															fontWeight: "400",
															color: "#FFF",
															pading: 5,
															paddingHorizontal: 15,
														}}>
														-
													</Text>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 15,
															fontWeight: "400",
															color: "#FFF",
														}}>
														{moment(
															d.endDate,
														).format("DD MMM")}
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									))}

								<Text
									numberOfLines={2}
									style={{
										fontFamily: "Avenir",
										fontSize: 18,
										fontWeight: "800",

										flexWrap: "wrap",
										color: APP_COLOURS.WHITE,
										marginRight: 10,
										marginBottom: 10,
										marginTop: 30,
									}}>
									Previous targets
								</Text>
								{this.state.comissions
									.filter((a) =>
										moment(moment()).isSameOrAfter(
											a.endDate,
										),
									)
									.map((d, i) => (
										<View
											key={`comm${i}`}
											style={{
												padding: 15,
												borderRadius: 10,
												backgroundColor:
													APP_COLOURS.BG3,
												marginBottom: 3,
											}}>
											<View
												style={{
													height: 60,
													width: 4,
													borderRadius: 2,
													backgroundColor:
														"rgba(255, 156, 0, 1)",
													position: "absolute",
													left: 5,
													top: 10,
												}}
											/>
											<TouchableOpacity
												onPress={() => {
													this.setState({
														showSales: false,
														showStats: false,
													});
													this.setState({
														loading: true,
														targetAmount: d.target,
													});
													console.log(d);
													this.getSales(
														d.startFilter,
														d.endFilter,
													);
												}}
												style={{
													alignItems: "center",

													borderRadius: 10,
													padding: 4,
												}}>
												<View
													style={{
														flexDirection: "row",
														width: "100%",
														justifyContent:
															"space-between",
													}}>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 16,
															fontWeight: "600",
															color: "#FFF",
														}}>
														{d.title}
													</Text>

													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 16,
															fontWeight: "600",
															color: "#FFF",
														}}>
														{currencyFormat(
															parseFloat(
																d.target,
															),
														)}
													</Text>
												</View>
												<View
													style={{
														flexDirection: "row",
														width: "100%",
														// justifyContent: "space-between",
														opacity: 0.6,
													}}>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 15,
															fontWeight: "400",
															color: "#FFF",
														}}>
														{moment(
															d.startDate,
														).format("DD MMM")}
													</Text>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 15,
															fontWeight: "400",
															color: "#FFF",
															pading: 5,
															paddingHorizontal: 15,
														}}>
														-
													</Text>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 15,
															fontWeight: "400",
															color: "#FFF",
														}}>
														{moment(
															d.endDate,
														).format("DD MMM")}
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									))}

								{!this.state.loading &&
								this.state.comissions === 0 ? (
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
											paddingBottom: 100,
										}}>
										<View
											style={[
												{
													backgroundColor:
														APP_COLOURS.BG4,
													justifyContent: "center",
													alignItems: "center",
													borderRadius: 13,
													padding: 50,
													marginRight: 5,
													marginBottom: 5,
													opacity: 0.7,
												},
											]}>
											<Text
												style={{
													color: APP_COLOURS.DESELECTEDCOLOR,
													fontFamily: "Avenir",
													fontWeight: "600",
												}}>
												No results found
											</Text>
										</View>
									</View>
								) : null}
							</View>
						</View>
					)}
				</View>

				<TermsOfCommission
					visible={!this.state.termsAccepted}
					onAccept={() => {
						this.setState({
							termsAccepted: true,
						});
					}}
				/>
			</View>
		);
	}
	salesItem = (d, i) => {
		return (
			<View key={`sales${i}`} style={{}}>
				<TouchableOpacity
					onLongPress={() => {
						// this.setState({
						// 	deleteModal: d,
						// });
					}}
					onPress={() => {
						// this.setState({
						// 	profileData: d,
						// 	viewmemberProfile: true,
						// });
						// console.log(d.uuid);
					}}>
					<View
						onMouseEnter={() =>
							this.setState({
								hover: i,
							})
						}
						onMouseLeave={() =>
							this.setState({
								hover: -1,
							})
						}
						style={{
							width: "100%",
							backgroundColor: "rgba(255, 255, 255, 0.05)",

							flexDirection: "row",
							alignItems: "center",
							borderRadius: 3,
							marginRight: 10,
							marginBottom: 2,
							paddingRight: 10,

							borderColor: "#C5C5C5",
						}}>
						<View
							style={{
								flex: 1,
								width: "100%",
								padding: 10,
							}}>
							<View
								style={{
									width: "100%",

									// alignItems: "center",
									marginTop: 3,
									justifyContent: "space-between",
								}}>
								<View
									style={{
										flexDirection: "row",
										width: "100%",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontSize: 15,

											color: "#FFF",
										}}>
										{moment(d.createdAt).format("DD/MM/YY")}
									</Text>
									<Text
										style={{
											fontSize: 15,
											color: "#FFF",
											opacity: 0.6,
										}}>
										Qty: {d.items.length}
									</Text>
								</View>
								{/* <Text
									style={{
										fontSize: 15,
										color: "#FFF",
									}}>
									{d.sale_id.slice(0, 10)}
									...
								</Text> */}

								{/* <View>
									{d.subCategories.map((s) => (
										<Text
											style={{
												fontSize: 10,
												color: "#FFF",
											}}>
											{s}
										</Text>
									))}
								</View> */}
								<View
									style={{
										width: "100%",
									}}>
									{d.items.map((item) => (
										<Text
											numberOfLines={1}
											style={{
												fontSize: 12,
												color: "#FFF",
												marginBottom: 1,
												opacity: 0.6,
											}}>
											- {item.title}
										</Text>
									))}
								</View>

								{/* <Text
									style={{
										fontSize: 15,
										color: "#FFF",
									}}>
									{d.mall?.title}
								</Text>
								<Text
									style={{
										fontSize: 15,
										color: "#FFF",
									}}>
									{d.retailer?.title}
								</Text> */}
								<View
									style={{
										fontSize: 15,
										color: "#FFF",
										opacity: 0.6,
									}}>
									<Text
										style={{
											fontSize: 8,
											color: "#FFF",
										}}>
										New Customer:
									</Text>
									<Text
										style={{
											fontSize: 13,
											color: "#FFF",
										}}>
										{d.newCustomer ? "Yes" : "no"}
									</Text>
								</View>
								<View
									style={{
										width: "100%",
										justifyContent: "flex-end",
										alignItems: "flex-end",
									}}>
									<Text
										style={{
											fontSize: 15,
											color: "#FFF",
											fontWeight: "bold",
										}}>
										{currencyFormat(parseFloat(d.trs))}
									</Text>
								</View>
							</View>
						</View>
					</View>
				</TouchableOpacity>
			</View>
		);
	};
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: APP_COLOURS.BG,
		padding: 10,
	},
	loadingContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: APP_COLOURS.BG,
		height: window.innerHeight,
	},
	button: {
		backgroundColor: APP_COLOURS.PRIMARY,
		padding: 15,
		borderRadius: 5,
		marginVertical: 10,
	},
	buttonText: {
		color: APP_COLOURS.TEXT,
		fontSize: 16,
		textAlign: "center",
	},
});

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		const params = useParams();
		const { user_id, token } = params;
		useEffect(() => {
			const fetchUserData = async (token) => {
				try {
					const response = await fetch(
						`https://workflow.tetrice.co.za/webhook/79d646ab-dd9b-4410-a905-62b6694f65c8`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`, // Set Bearer token in the Authorization header
							},
							body: JSON.stringify({
								user_id: user_id,
							}),
						},
					);

					if (!response.ok) {
						throw new Error("Failed to fetch user data");
					}

					const userData = await response.json();

					// Set user data in localStorage
					localStorage.setItem("user_data", JSON.stringify(userData));
				} catch (error) {
					console.error("Error fetching user data:", error);
				}
			};

			if (user_id && token) {
				localStorage.setItem("user_id", user_id);
				localStorage.setItem("accessToken", token);

				// Fetch user data using the Bearer token
				fetchUserData(token);
			}
		}, [params]);

		return <Component {...props} params={params} user_id={user_id} />;
	}

	return ComponentWithRouterProp;
}
export default withRouter(Targets);
