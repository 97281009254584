import React, { Component, useEffect } from "react";
import {
	StyleSheet,
	LayoutAnimation,
	ActivityIndicator,
	Keyboard,
	Text,
	Image,
	TouchableOpacity,
	RefreshControl,
	Dimensions,
	SafeAreaView,
	ScrollView,
	Modal,
	View,
} from "react-native";
import { useParams } from "react-router-dom";
import logo from "../../../assets/images/traq_app_logo.svg";
import AutoHeightImage from "../AutoHeightImageComp";
import AsyncStorage from "../../AsyncStorage";
import { APP_COLOURS, Avatars } from "../../APP_VARS";
import Overview from "./Overview";

const device = {
	width: window.innerWidth,
	height: window.innerHeight,
};

class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			app_properties: "",
			avatar: 0,

			backgroundcolour: APP_COLOURS.BG2,
			fontcolour: APP_COLOURS.DESELECTEDCOLOR,

			tab: "Profile",
		};
	}

	componentDidMount() {
		this.getDeviceData();
	}

	getDeviceData = async () => {
		try {
			const value = await AsyncStorage.getItem("user_data");
			if (value !== null) {
				this.setState({
					user_data: JSON.parse(value),
				});
			}
		} catch (error) {
			// Error retrieving data
		}

		try {
			const value = await AsyncStorage.getItem("accessToken");
			if (value !== null) {
				this.setState({
					avatar: value,
				});
			}
		} catch (error) {
			// Error retrieving data
		}

		try {
			const value = await AsyncStorage.getItem("users");
			if (value !== null) {
				this.setState({
					avatar: value,
				});
			}
		} catch (error) {
			// Error retrieving data
		}
	};

	// getDeviceData = async () => {
	// 	try {
	// 		const value = await sessionStorage.getItem("accessToken");
	// 		if (value !== null) {
	// 			await this.setState({
	// 				accessToken: value,
	// 			});

	// 			console.log(value);
	// 			this.heartBeat(value);
	// 		}
	// 	} catch (error) {
	// 		// this.props.navigate("/");
	// 	}
	// 	try {
	// 		const value = await sessionStorage.getItem("app_properties");
	// 		if (value !== null) {
	// 			var json = JSON.parse(value);
	// 			this.setState({
	// 				app_properties: json,
	// 				backgroundcolour: json.backgroundcolour,
	// 			});
	// 			console.log(json);
	// 		}
	// 	} catch (error) {
	// 		// Error retrieving data
	// 	}

	// 	try {
	// 		const value = await sessionStorage.getItem("users");
	// 		if (value !== null) {
	// 			var json = JSON.parse(value);
	// 			this.setState({
	// 				users: json,
	// 			});
	// 			// console.log(json);
	// 		}
	// 	} catch (error) {
	// 		// Error retrieving data
	// 	}
	// };

	changetab = (d) => {
		this.setState({
			tab: d,
		});
	};

	render() {
		let backgroundcolour = this.state.backgroundcolour;

		return (
			<View
				style={{
					flex: 1,
					backgroundColor: backgroundcolour,
					height: device.height,
				}}>
				{/* <View
					style={{
						width: device.width,
						// marginTop: device.height * 0.1,
						justifyContent: "center",
						alignItems: "center",
						marginBottom: 30,
					}}>
					<AutoHeightImage width={150} source={logo} />
				</View> */}

				<View style={{ padding: 10 }}>
					<ScrollView
						horizontal
						showsHorizontalScrollIndicator={false}>
						<View
							style={{
								width: "100%",
								flexDirection: "row",
							}}>
							{["Profile", "Settings", ""].map((dl) => (
								<TouchableOpacity
									onPress={() => this.changetab(dl)}>
									<View
										style={{
											// width: screenWidth / 3,
											minWidth: device.width / 3.5,
											justifyContent: "center",
											alignItems: "center",
											padding: 10,
											// borderRadius: 5,
											borderBottomColor:
												this.state.tab === dl
													? APP_COLOURS.BLUE
													: "transparent",
											borderBottomWidth: 2,
										}}>
										<Text
											style={{
												fontSize: 14,
												fontWeight:
													this.state.tab === dl
														? "800"
														: "500",
												// color: APP_COLOURS.BLUE2,
												color: this.state.fontcolour,
											}}>
											{dl}
										</Text>
									</View>
								</TouchableOpacity>
							))}
						</View>
					</ScrollView>
				</View>

				<View
					style={{
						// position: "absolute",
						zIndex: 99,
						// display: this.state.visible ? "flex" : "none",
						// shadowColor: "#000",
						// shadowOffset: {
						// 	width: 0,
						// 	height: 10,
						// },
						// shadowOpacity: 0.33,
						// shadowRadius: 13.97,
						// elevation: 21,
					}}>
					{this.state.tab === "Profile" ? (
						<Overview {...this.props} {...this.state} />
					) : (
						<View />
					)}

					{/* {this.state.tab === "Schedule" ? (
						<Schedule {...this.props} {...this.state} />
					) : (
						<View />
					)} */}
				</View>
			</View>
		);
	}
	tabItem = (d) => {
		return (
			<TouchableOpacity
				style={{
					marginRight: 10,
					backgroundColor: APP_COLOURS.BG2,
					justifyContent: "center",
					alignItems: "center",
					borderRadius: 12,
					padding: 10,
				}}
				onPress={d.nav}>
				<Image
					source={d.img}
					style={{
						width: 70,
						height: 70,
						backgroundColor: APP_COLOURS.BG3,
						borderRadius: 35,
						resizeMode: "contain",
					}}
				/>
				<Text
					style={{
						fontSize: 13,
						fontWeight: "700",
						color: "white",
						marginTop: 10,
					}}>
					{d.title}
				</Text>
			</TouchableOpacity>
		);
	};
}

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		const params = useParams();

		useEffect(() => {
			const { user_id, token } = params;

			// Function to fetch user data using a Bearer token
			const fetchUserData = async (token) => {
				try {
					const response = await fetch(
						`https://workflow.tetrice.co.za/webhook/79d646ab-dd9b-4410-a905-62b6694f65c8`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`, // Set Bearer token in the Authorization header
							},
							body: JSON.stringify({
								user_id: user_id,
							}),
						},
					);

					if (!response.ok) {
						throw new Error("Failed to fetch user data");
					}

					const userData = await response.json();

					// Set user data in localStorage
					localStorage.setItem("user_data", JSON.stringify(userData));
				} catch (error) {
					console.error("Error fetching user data:", error);
				}
			};

			// If user_id and token are present, set them in localStorage and fetch user data
			if (user_id && token) {
				localStorage.setItem("user_id", user_id);
				localStorage.setItem("accessToken", token);

				// Fetch user data using the Bearer token
				fetchUserData(token);
			}
		}, [params]);

		return <Component {...props} params={params} />;
	}

	return ComponentWithRouterProp;
}
export default withRouter(Profile);
