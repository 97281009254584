import React, { Component } from "react";
import {
	View,
	Text,
	StyleSheet,
	FlatList,
	ActivityIndicator,
} from "react-native";
import { TouchableOpacity } from "react-native-web";

// Sample NotificationItem component
class NotificationItem extends Component {
	render() {
		const { notification } = this.props;
		return (
			<TouchableOpacity>
				<View style={styles.notificationItem}>
					<Text style={styles.title}>{notification.title}</Text>
					<Text style={styles.body}>{notification.body}</Text>
				</View>
			</TouchableOpacity>
		);
	}
}

// Main NotificationsPage component
class Notifications extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notifications: [],
			loading: true,
		};
	}

	componentDidMount() {
		this.fetchNotifications();
	}

	fetchNotifications = async () => {
		const notificationsData = [
			{
				id: 1,
				title: "New Sales Target Achieved!",
				body: "Congratulations! Your team has achieved 120% of the monthly sales target. Keep up the great work!",
				date: "2024-09-01T10:00:00Z",
			},
			{
				id: 2,
				title: "New Product Launch: Autumn Collection",
				body: "The new Autumn Collection has arrived! Make sure to familiarize yourself with the new products and update the displays accordingly.",
				date: "2024-09-03T14:30:00Z",
			},
			{
				id: 3,
				title: "Inventory Low: Popular Items",
				body: "The inventory for some popular items is running low. Please restock items #3421, #4522, and #6754 from the warehouse.",
				date: "2024-09-05T09:45:00Z",
			},
			{
				id: 4,
				title: "Mandatory Training Session",
				body: "A mandatory training session on customer service excellence will be held on 2024-09-10 at 3 PM. All staff members are required to attend.",
				date: "2024-09-07T08:15:00Z",
			},
			{
				id: 5,
				title: "Flash Sale Alert",
				body: "A flash sale is scheduled for this weekend. All items will be 30% off. Prepare the store and inform customers about the sale!",
				date: "2024-09-08T13:00:00Z",
			},
			{
				id: 6,
				title: "Staff Meeting Reminder",
				body: "Reminder: The weekly staff meeting is tomorrow at 10 AM in the main conference room. Please be on time.",
				date: "2024-09-09T16:00:00Z",
			},
			{
				id: 7,
				title: "Customer Feedback",
				body: "New feedback from a VIP customer has been received. Check the feedback section in the app to review and respond appropriately.",
				date: "2024-09-10T12:30:00Z",
			},
			{
				id: 8,
				title: "System Maintenance Notice",
				body: "The POS system will undergo maintenance tonight from 11 PM to 1 AM. Please ensure all transactions are completed before then.",
				date: "2024-09-10T18:45:00Z",
			},
			{
				id: 9,
				title: "Holiday Schedule Updates",
				body: "The store hours for the upcoming holiday have been updated. Please check the schedule and plan accordingly.",
				date: "2024-09-11T07:00:00Z",
			},
			{
				id: 10,
				title: "Performance Bonus Notification",
				body: "Great news! Performance bonuses have been credited to your accounts. Check your email for the detailed breakdown.",
				date: "2024-09-11T15:30:00Z",
			},
		];

		this.setState({ notifications: notificationsData, loading: false });
		// try {
		// 	// Replace this URL with your actual endpoint
		// 	const response = await fetch(
		// 		"https://jsonplaceholder.typicode.com/posts?_limit=5",
		// 	);
		// 	const data = await response.json();
		// 	this.setState({ notifications: data, loading: false });
		// } catch (error) {
		// 	console.error("Error fetching notifications:", error);
		// 	this.setState({ loading: false });
		// }
	};

	render() {
		const { notifications, loading } = this.state;

		return (
			<View style={styles.container}>
				{loading ? (
					<ActivityIndicator size='large' color='#007AFF' />
				) : (
					<FlatList
						data={notifications}
						keyExtractor={(item) => item.id.toString()}
						renderItem={({ item }) => (
							<NotificationItem notification={item} />
						)}
					/>
				)}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: "#F5F5F5", // Light gray background to mimic iOS style
	},
	header: {
		fontSize: 24,
		fontWeight: "bold",
		padding: 16,
		textAlign: "center",
		backgroundColor: "#FFFFFF",
		color: "#333333",
		borderBottomWidth: 1,
		borderBottomColor: "#E5E5E5",
	},
	notificationItem: {
		padding: 16,
		borderBottomWidth: 1,
		borderBottomColor: "#E5E5E5",
		backgroundColor: "#FFFFFF",
		// opacity: 0.7,
	},
	title: {
		fontSize: 18,
		fontWeight: "600",
		color: "#007AFF",
	},
	body: {
		fontSize: 14,
		color: "#555555",
	},
});

export default Notifications;
