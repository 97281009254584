import React, { Component } from "react";
import {
	StyleSheet,
	ActivityIndicator,
	Text,
	TouchableOpacity,
	View,
	ScrollView,
} from "react-native";
// import AutoHeightImage from "../AutoHeightImageComp";
import { APP_COLOURS } from "../../APP_VARS";

const device = {
	width: window.innerWidth,
	height: window.innerHeight,
};

export default class ELearning extends Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundcolour: APP_COLOURS.BG2,
			fontcolour: APP_COLOURS.DESELECTEDCOLOR,
			data: [],
			loading: true,
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData = async () => {
		this.setState({
			data: [],
			loading: false,
		});
		// try {
		// 	const response = await fetch(
		// 		"https://your-api-endpoint.com/pdf-list",
		// 	);
		// 	const data = await response.json();
		// 	this.setState({ data, loading: false });
		// } catch (error) {
		// 	console.error(error);
		// 	this.setState({ loading: false });
		// }
	};

	handleButtonPress = (pdfUrl) => {
		// Implement navigation or open PDF logic here
		console.log("PDF URL:", pdfUrl);
	};

	changetab = (d) => {
		this.setState({
			tab: d,
		});
	};

	render() {
		const { data, loading } = this.state;
		let backgroundcolour = this.state.backgroundcolour;

		if (loading) {
			return (
				<View style={styles.loadingContainer}>
					<ActivityIndicator
						size='large'
						color={APP_COLOURS.PRIMARY}
					/>
				</View>
			);
		}

		return (
			<View
				style={{
					flex: 1,
					// backgroundColor: backgroundcolour,
				}}>
				<View
					style={{
						// flexDirection: 'row',
						borderBottomWidth: 0.5,
						borderBottomColor: "#E8E8E8",
						paddingBottom: 0,
						backgroundColor: "#FFF",
						// padding: 3,
						shadowColor: "#E8E8E8",
						shadowOffset: {
							width: 0,
							height: 4,
						},
						shadowOpacity: 0.3,
						shadowRadius: 4.65,
						elevation: 8,
						width: "100%",
					}}>
					<ScrollView
						horizontal
						showsHorizontalScrollIndicator={false}>
						{["Overview", "Tests", "Learning Material"].map(
							(dl) => (
								<TouchableOpacity
									onPress={() => this.changetab(dl)}>
									<View
										style={{
											// width: screenWidth / 3,
											minWidth: device.width / 3,
											justifyContent: "center",
											alignItems: "center",
											padding: 10,
											borderRadius: 5,
											borderBottomColor:
												this.state.tab === dl
													? "#264F7D"
													: "#F5F5F5",
											borderBottomWidth: 2,
											// backgroundColor:
											//   this.state.tab ===dl
											//     ? APP_COLOURS.PRIMARY
											//     : '#FFF',
										}}>
										<Text
											style={{
												fontSize: 14,
												fontWeight:
													this.state.tab === dl
														? "700"
														: "400",
											}}>
											{dl}
										</Text>
									</View>
								</TouchableOpacity>
							),
						)}
					</ScrollView>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: APP_COLOURS.BG2,
		padding: 10,
	},
	loadingContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: APP_COLOURS.BG2,
	},
	button: {
		backgroundColor: APP_COLOURS.PRIMARY,
		padding: 15,
		borderRadius: 5,
		marginVertical: 10,
	},
	buttonText: {
		color: APP_COLOURS.DESELECTEDCOLOR,
		fontSize: 16,
		textAlign: "center",
	},
});
