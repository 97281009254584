import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import { Dimensions } from "react-native";
import Login from "./Unauthed/Login";
import Home from "./Authed/index";

import Profile from "./Authed/Profile";

//

import ELearning from "./Authed/ELearning";
import Notifications from "./Authed/Notifications";
//
import NewSale from "./Authed/NewSale";
import NewCustomer from "./Authed/NewSale/NewCustomer";
import ExistingCustomer from "./Authed/NewSale/ExistingCustomer";

import Targets from "./Authed/Targets";
import SalesHistory from "./Authed/SalesHistory";
import ProductSearch from "./Authed/Products/Search";
import ComissionSheets from "./Authed/CommissionSheets/index";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
			console.log("Polly fill for smooth scroll in safari");
		} else {
			// production code
		}

		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	render() {
		return (
			<Router>
				<Routes>
					<Route path='/' element={<Login />} />
					<Route path='/Home' element={<Home />} />
					{/* <Route path='/Attendance' element={<Atte />} /> */}
					<Route
						path='/Profile/:user_id/:token'
						element={<Profile />}
					/>
					{/* <Route
						path='/Sales/:user_id/:token'
						element={<Profile />}
					/> */}

					<Route
						path='/NewSale/:user_id/:token'
						element={<NewSale />}
					/>
					<Route
						path='/NewCustomer/:user_id/:token'
						element={<NewCustomer />}
					/>
					<Route
						path='/ExistingCustomer/:user_id/:customer_id/:token'
						element={<ExistingCustomer />}
					/>

					<Route
						path='/Notifications/:user_id/:token'
						element={<Notifications />}
					/>
					<Route
						path='/ComissionSheets/:user_id/:token'
						element={<ComissionSheets />}
					/>
					<Route
						path='/Targets/:user_id/:token'
						element={<Targets />}
					/>
					<Route
						path='/SalesHistory/:user_id/:token'
						element={<SalesHistory />}
					/>
					<Route
						path='/ProductSearch/:user_id/:token'
						element={<ProductSearch />}
					/>

					<Route
						path='/Elearning/:user_id/:token'
						element={<ELearning />}
					/>

					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</Router>
		);
	}
}

export default index;
