import React, { Component } from "react";
import {
	View,
	Text,
	TouchableNativeFeedback,
	TextInput,
	Image,
	ScrollView,
	TouchableOpacity,
} from "react-native";
import { APP_COLOURS, screenWidth } from "../../../APP_VARS";

import CellRegionCodes from "./CellRegionCodes.json";

export default class CellNumber extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFocused: false,
			text: this.props.value,
			query: "",

			regionCode: "",
			phoneNumber: "",
		};
		this.textInputRef = React.createRef();
		this.updateTimeout = null;
	}

	componentDidMount() {
		// this.handlePhoneNumberChange(this.props.value);
		this.setState({
			...this.props.value,
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				...this.props.value,
			});
		}
	}

	handlePhoneNumberChange(text) {
		const maxLength = 10; // Set the maximum length as needed

		// Remove non-numeric characters and leading zero
		// const formattedPhoneNumber = text.replace(/^0+|[^0-9]/g, "");
		const formattedPhoneNumber = text.replace(/^|[^0-9]/g, "");

		// Limit the length of the input
		const limitedLengthPhoneNumber = formattedPhoneNumber.substring(
			0,
			maxLength,
		);

		// Add spaces after the first two and next three digits
		const spacedPhoneNumber = limitedLengthPhoneNumber.replace(
			/^(.{3})(.{3})?/,
			"$1 $2 ",
		);

		// Update state with the formatted, limited length, and spaced phone number
		this.setState({ phoneNumber: spacedPhoneNumber.trim() });

		this.saveState(this.state.regionCode, spacedPhoneNumber.trim());
	}

	saveState = (regionCode, spacedPhoneNumber) => {
		let cellNumber = {
			number: `${regionCode}${spacedPhoneNumber.replace(
				/^0+|[^0-9]/g,
				"",
			)}`,
			regionCode: regionCode,
			phoneNumber: spacedPhoneNumber,
		};

		this.props.update(cellNumber);
	};

	render() {
		const { label, placeholder } = this.props;
		const { isFocused, text, query, regionCode } = this.state;
		const showTitle = text !== "";

		// Validate the phone number with a regular expression
		let isValidPhoneNumber = /^\+?[1-9]\d{1,14}$/.test(text);

		const filteredCellRegionCodes = CellRegionCodes.filter(
			(item) =>
				item.country.toUpperCase().includes(query.toUpperCase()) ||
				item.code.toUpperCase().includes(query.toUpperCase()),
		);

		return (
			<View
				style={{
					flex: 1,
					width: "100%",
					padding: 10,
					marginTop: 10,
					backgroundColor: APP_COLOURS.BG2,
					borderRadius: 10,
				}}>
				<View
					style={{
						width: "100%",
						flexDirection: "row",
						height: 40,
						alignItems: "center",
						borderBottomWidth: 1,
						borderBottomColor: APP_COLOURS.DESELECTEDCOLOR,
					}}>
					<TouchableOpacity
						onPress={() =>
							this.setState({
								showRegions: !this.state.showRegions,
							})
						}>
						<View
							style={{
								// borderRadius: 20,
								width: this.state.regionCode !== "" ? 80 : 180,
								height: 40,
								// backgroundColor: "#FFF",
								// zIndex: 10,
								// shadowColor: APP_COLOURS.BLUE2,
								// shadowOffset: {
								// 	width: 0,
								// 	height: 4,
								// },
								// shadowOpacity:
								// 	this.state.regionCode !== "" ? 0 : 0.13,
								// shadowRadius: 4.65,
								// elevation: 4,
								padding: 8,
								marginRight: 1,
								justifyContent: "center",
								// alignItems: 'center',
								borderTopLeftRadius: 10,
								borderBottomLeftRadius: 10,
							}}>
							<Text
								style={{
									fontSize: 16,
									fontWeight: "500",
									// color: APP_COLOURS.BLUE,
									color:
										this.state.regionCode !== ""
											? "#FFF"
											: APP_COLOURS.BLUE,
								}}>
								{this.state.regionCode !== ""
									? this.state.regionCode
									: "Select Region Code"}
							</Text>
						</View>
					</TouchableOpacity>
					<View
						style={{
							flex: 1,
							height: 40,
							// borderRadius: 20,
							// borderWidth: 0.5,
							// borderColor: '#797979',
							// backgroundColor: "#FFF",
							zIndex: 10,
							shadowColor: APP_COLOURS.BLUE2,
							shadowOffset: {
								width: 0,
								height: 4,
							},
							shadowOpacity: 0.13,
							shadowRadius: 4.65,
							elevation: 4,
							flexDirection: "row",
						}}>
						{this.state.regionCode !== "" ? (
							<TextInput
								editable={this.state.regionCode !== ""}
								style={{
									flex: 1,
									padding: 8,
									paddingLeft: 15,
									fontSize: 18,
									fontWeight: "500",
									color: "#FFF",
								}}
								autoFocus
								placeholder='Cell number #'
								keyboardType='number-pad' //
								value={this.state.phoneNumber}
								onChangeText={(text) =>
									this.handlePhoneNumberChange(text)
								}
							/>
						) : null}
					</View>
				</View>
				{this.state.showRegions ? (
					<View style={{ flex: 1 }}>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								marginTop: 15,
							}}>
							<Image
								source={require("../../assets/images/send.png")}
								style={{
									height: 40,
									width: 40,
									resizeMode: "contain",
								}}
							/>
							<TextInput
								style={{
									padding: 8,
									paddingLeft: 15,
									width: "100%",
									borderRadius: 5,
									// backgroundColor: '#FFF',
									fontWeight: "400",
									padding: 10,
									fontSize: 16,
									color: APP_COLOURS.TEXTCOLOR,
								}}
								autoFocus
								placeholder='Search code by country'
								placeholderTextColor={APP_COLOURS.TEXTCOLOR}
								value={this.state.query}
								onChangeText={(text) =>
									this.setState({ query: text })
								}
							/>
						</View>
						<ScrollView>
							{filteredCellRegionCodes.map((item, i) => (
								<TouchableOpacity
									key={i}
									onPress={() => {
										this.saveState(
											item.code,
											this.state.phoneNumber,
										);

										this.setState({
											regionCode: item.code,
											showRegions: false,
										});
									}}>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "space-between",
											borderRadius: 5,
											padding: 10,
											backgroundColor:
												this.state.regionCode ===
												item.code
													? "transparent"
													: APP_COLOURS.BG4,
											borderRadius: 3,
											marginBottom: 1,
										}}>
										<Text
											style={{
												fontSize: 17,
												color: "#FFF",
												width: 90,
											}}>
											{item.code}
											{"    "}
										</Text>
										<Text
											style={{
												fontSize: 17,
												color: "#FFF",
												opacity: 0.8,
											}}>
											{item.country}
										</Text>
									</View>
								</TouchableOpacity>
							))}
						</ScrollView>
					</View>
				) : null}

				{this.state.verificationSent ? (
					<View style={{}}>
						<View
							style={{
								width: "100%",
								marginTop: 20,
								flexDirection: "row",
								justifyContent: "space-evenly",
								opacity: this.state.loadingotp ? 0.3 : 1,
							}}>
							<TextInput
								ref={(ref) => {
									this.digit1 = ref;
								}}
								style={{
									width: screenWidth / 7,
									height: screenWidth / 7,
									borderRadius: 9,
									fontSize: 22,
									backgroundColor:
										this.state.digit1 !== ""
											? APP_COLOURS.BLUE2
											: "#FFF",
									textAlign: "center",
									color: "#FFF",
									fontWeight: "900",
									fontFamily: "Avenir",
									letterSpacing: 0.26,
									borderWidth: 0.3,
									borderColor: APP_COLOURS.BLUE2,
								}}
								placeholderTextColor={"#FFF"}
								autoCorrect={false}
								textContentType='oneTimeCode'
								autoComplete='sms-otp'
								autoFocus
								value={this.state.digit1}
								// defaultValue={
								// 	this.state.password
								// }
								onFocus={() => {
									this.digit1.clear();
									this.digit2.clear();
									this.digit3.clear();
									this.digit4.clear();
									this.setState({ otpPin: "" });
								}}
								maxLength={1}
								onChangeText={async (text) => {
									await this.setState({
										digit1: text,
									});
									this.digit2.focus();
								}}
							/>

							<TextInput
								ref={(ref) => {
									this.digit2 = ref;
								}}
								style={{
									width: screenWidth / 7,
									height: screenWidth / 7,
									borderRadius: 9,
									fontSize: 22,
									backgroundColor:
										this.state.digit2 !== ""
											? APP_COLOURS.BLUE2
											: "#FFF",
									textAlign: "center",
									color: "#FFF",
									fontWeight: "900",
									fontFamily: "Avenir",
									letterSpacing: 0.26,
									borderWidth: 0.3,
									borderColor: APP_COLOURS.BLUE2,
								}}
								placeholderTextColor={"#FFF"}
								// clearButtonMode='while-editing'
								autoCorrect={false}
								textContentType='oneTimeCode'
								autoComplete='sms-otp'
								value={this.state.digit2}
								onFocus={() => {
									if (this.state.digit1 == "") {
										this.digit1.focus();
									}
									this.digit2.clear();
									this.digit3.clear();
									this.digit4.clear();
									this.setState({ otpPin: "" });
								}}
								maxLength={1}
								onChangeText={async (text) => {
									await this.setState({
										digit2: text,
									});
									this.digit3.focus();
								}}
							/>
							<TextInput
								ref={(ref) => {
									this.digit3 = ref;
								}}
								style={{
									width: screenWidth / 7,
									height: screenWidth / 7,
									borderRadius: 9,
									fontSize: 22,
									backgroundColor:
										this.state.digit3 !== ""
											? APP_COLOURS.BLUE2
											: "#FFF",
									textAlign: "center",
									color: "#FFF",
									fontWeight: "900",
									fontFamily: "Avenir",
									letterSpacing: 0.26,
									borderWidth: 0.3,
									borderColor: APP_COLOURS.BLUE2,
								}}
								placeholderTextColor={"#FFF"}
								// clearButtonMode='while-editing'
								autoCorrect={false}
								textContentType='oneTimeCode'
								autoComplete='sms-otp'
								value={this.state.digit3}
								onFocus={() => {
									if (this.state.digit1 == "") {
										this.digit1.focus();
									}
									this.digit3.clear();
									this.digit4.clear();
									this.setState({ otpPin: "" });
								}}
								maxLength={1}
								onChangeText={async (text) => {
									await this.setState({
										digit3: text,
									});
									this.digit4.focus();
								}}
							/>
							<TextInput
								ref={(ref) => {
									this.digit4 = ref;
								}}
								style={{
									width: screenWidth / 7,
									height: screenWidth / 7,
									borderRadius: 9,
									fontSize: 22,
									backgroundColor:
										this.state.digit4 !== ""
											? APP_COLOURS.BLUE2
											: "#FFF",
									textAlign: "center",
									color: "#FFF",
									fontWeight: "900",
									fontFamily: "Avenir",
									letterSpacing: 0.26,
									borderWidth: 0.3,
									borderColor: APP_COLOURS.BLUE2,
								}}
								placeholderTextColor={"#FFF"}
								// clearButtonMode='while-editing'
								autoCorrect={false}
								textContentType='oneTimeCode'
								autoComplete='sms-otp'
								// placeholder={``}
								value={this.state.digit4}
								onFocus={() => {
									this.digit4.clear();
									if (this.state.digit1 == "") {
										this.digit1.focus();
									}
									this.setState({ otpPin: "" });
								}}
								maxLength={1}
								onChangeText={async (text) => {
									// alert(otpPin);

									await this.setState({
										digit4: text,
									});

									let otpPin = `${this.state.digit1}${this.state.digit2}${this.state.digit3}${text}`;

									this.verifyOTP(otpPin);
								}}
							/>
						</View>
					</View>
				) : null}
			</View>
		);
	}
}
