// let ee = "https://us-central1-tetricepm-5f972.cloudfunctions.net/app";
// //
// // export const api_login = ee + "/pm/login";

// //

// export const api = ee;

//
//
//
//
//
//

let e = `https://us-central1-traq-e3acf.cloudfunctions.net/app`;

export const api_login = e + "/staffapp/login";
export const api_heartBeat = e + "/staffapp/heartBeat";

//
// Attendance
// export const api_updatestatus = e + "/staffapp/attendance/updatestatus";
// export const api_getattendance = e + "/staffapp/attendance/getattendance";

// Products
// export const api_getproducts = e + "/staffapp/products/";

//
export const api_crudCreate = e + "/staffapp/c";
export const api_crudRead = e + "/staffapp/r";
export const api_crudUpdate = e + "/staffapp/u";
export const api_crudReadUnass = e + "/staffapp/runass";

export const api_crudDelete = e + "/staffapp/d";
export const api_crudWithImage = e + "/staffapp/cwimg";
export const api_crudDeleteDocument = e + "/staffapp/cdelimg";
export const api_sendBasicEmail = e + "/staffapp/sendBasicEmail";
export const api_sendDiscordMessage = e + "/staffapp/sendDiscordMessage";

export const api_getUsers = e + "/staffapp/users";
export const api_createPostApproval = e + "/staffapp/createPostApproval";
export const api_creatSurveyFeedback = e + "/staffapp/creatSurveyFeedback";
