import React, { Component } from "react";
import { View, Text } from "react-native";
import { ActivityIndicator } from "react-native-web";
import moment from "moment";
import QRCodeScanner from "./QRCodeScanner";

export default class QrCodeReader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showQrModal: true,
		};
	}

	componentDidMount() {}

	render() {
		return (
			<View>
				<View
					style={{
						backgroundColor: "#FFF",
						borderRadius: 13,
						overflow: "hidden",
						minHeight: 200,
					}}>
					{this.state.showQrModal ? (
						<QRCodeScanner
							screenWidth={this.props.screenWidth}
							screenHeight={this.props.screenHeight}
							processQR={(d) => {
								this.setState({
									showQrModal: false,
									memberQRCode: d,
								});
								console.log(d);
								this.processQrCode(d);
							}}
						/>
					) : (
						<View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								height: 300,
							}}>
							<Text>Loading...</Text>
							<ActivityIndicator color='#000' />
						</View>
					)}
				</View>
			</View>
		);
	}

	processQrCode = async (d) => {
		try {
			let encStr = d;
			// let baseEnc = await window.atob(encStr);
			// console.log(encStr);
			// return;
			let mn = encStr.split(",");
			console.log(mn[0], mn[1]);

			this.props.loginWithQrCode(mn[0], mn[1]);
		} catch (err) {
			await console.log(err);
		}
	};
}
