import React, { Component } from "react";
import {
	View,
	Text,
	TextInput,
	FlatList,
	TouchableOpacity,
	ScrollView,
	Image,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

const AGES = [
	"Below 18",
	"18 to 25",
	"25 to 35",
	"35 to 45",
	"45 to 65",
	"above 65",
];

export default class AgeRangePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: "",
			selected:
				typeof this.props.value !== "undefined" ? this.props.value : "",

			choose: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				selected: this.props.value,
			});
		}
	}

	renderItem = ({ item }) => {
		return (
			<TouchableOpacity
				style={{
					padding: 10,
					backgroundColor:
						this.state.selected === item
							? APP_COLOURS.GREEN
							: "#E8E8E8",
					borderRadius: 3,
					marginBottom: 1,
				}}
				onPress={() => {
					this.props.selected(item);
					this.setState({ selected: item, choose: false });
				}}>
				<Text
					style={{
						color: "#000",
						fontSize: 17,
					}}>
					{item}
				</Text>
			</TouchableOpacity>
		);
	};

	render() {
		const { query, selected } = this.state;

		// Filter the AGES based on the user's search query
		const filteredAGES = AGES.filter((nationality) =>
			nationality.toLowerCase().includes(query.toLowerCase()),
		);

		return (
			<View
				style={{
					flex: 1,
					zIndex: 10,

					// padding: 10,
					width: "100%",
					borderWidth: 0.3,
					borderColor: APP_COLOURS.BLACK,
					borderRadius: 10,
					// borderColor: this.props.backgroundColor,
					padding: 8,

					backgroundColor: "#FFF",
				}}>
				<TouchableOpacity
					onPress={() => {
						this.setState({
							choose: true,
							selected: "",
						});
					}}>
					<Text
						style={{
							fontWeight: "500",
							fontSize: 15,
							marginBottom: 5,
							color: "#000",
						}}>
						{typeof this.props.label !== "undefined"
							? this.props.label
							: ""}{" "}
						Ages:
					</Text>
					<View
						style={{
							paddingRight: 10,
							marginBottom: 15,
						}}>
						{selected === "" ? (
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
								}}>
								<Text
									style={{
										fontWeight: "300",
										fontSize: 17,
										color: "#000",
										opacity: 0.8,
									}}>
									Select an age range
								</Text>

								<View
									style={{
										width: 20,
										height: 20,
										borderRadius: 7,
										justifyContent: "center",
										alignItems: "center",
										backgroundColor: APP_COLOURS.GREEN,
										opacity: 0.3,
									}}>
									<Image
										source={require("../assets/images/check.png")}
										style={{
											width: 11,
											height: 11,
											resizeMode: "contain",
										}}
									/>
								</View>
							</View>
						) : (
							<>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
									}}>
									<Text
										style={{
											fontWeight:
												this.state.selected !== ""
													? "600"
													: "300",
											fontSize: 17,
											color: "#000",
										}}>
										{" "}
										{selected}
									</Text>

									<View
										style={{
											width: 20,
											height: 20,
											borderRadius: 7,
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: APP_COLOURS.GREEN,
										}}>
										<Image
											source={require("../assets/images/check.png")}
											style={{
												width: 11,
												height: 11,
												resizeMode: "contain",
											}}
										/>
									</View>
								</View>
							</>
						)}
					</View>
				</TouchableOpacity>

				<View
					style={{
						flex: 1,
						// maxHeight: 150,
						// padding: 10,
						maxHeight: "100%",
						display: this.state.choose ? "flex" : "none",
					}}>
					<TextInput
						style={{
							padding: 8,
							borderRadius: 5,
							borderWidth: 0.5,
							borderColor: "#797979",
							color: APP_COLOURS.TEXTCOLOR,
						}}
						placeholder='Search age range'
						value={query}
						onChangeText={(text) => this.setState({ query: text })}
					/>
					<ScrollView>
						<View
							style={{
								height: 10,
							}}
						/>
						<FlatList
							data={query !== "" ? filteredAGES : AGES}
							keyExtractor={(item) => item}
							renderItem={this.renderItem}
						/>
						<View
							style={{
								height: 10,
							}}
						/>
					</ScrollView>
					{/* <Image
            source={require('../../assets/images/fade.png')}
            style={{
              width: '100%',
              height: 25,
              resizeMode: 'stretch',
              position: 'absolute',
              bottom: 0,
            }}
          /> */}
				</View>
			</View>
		);
	}
}
