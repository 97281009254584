import React, {useState, useEffect} from 'react';
import {View, Keyboard, StyleSheet} from 'react-native';

const KeyboardResponsiveView = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    // Listener for keyboard show event
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      e => {
        setKeyboardHeight(e.endCoordinates.height); // Use the keyboard height from the event
      },
    );

    // Listener for keyboard hide event
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardHeight(0); // Reset keyboard height
      },
    );

    // Cleanup function
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  return (
    <View style={[styles.container, {height: keyboardHeight}]}>
      {/* Your content here */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // Styles for your container view
    backgroundColor: 'lightgrey', // Example background color
  },
});

export default KeyboardResponsiveView;
