import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";

const ethnicities = [
	{ name: "Asian", color: "#FEE2C8" },
	{ name: "Cacasian", color: "#FFDBAC" },
	{ name: "Mixed", color: "#B1845D" },
	{ name: "Indian", color: "#8D5524" },
	{ name: "Ethnic", color: "#663409" },
];

export default class EthnicitySelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedEthnicity: "",
		};
	}

	componentDidMount() {
		this.setState({
			selectedEthnicity: this.props.defaultEthnicity,
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({
				selectedEthnicity: this.props.defaultEthnicity,
			});
		}
	}

	render() {
		return (
			<View>
				<Text
					allowFontScaling={false}
					style={{
						fontWeight: "500",
						fontSize: 13,
						color: APP_COLOURS.TEXTCOLOR,
						opacity: 0.7,
						marginTop: 5,
						marginBottom: 5,
						paddingBottom: 5,
					}}>
					Ethnicity
				</Text>
				<View
					style={{
						width: "100%",
						// backgroundColor: APP_COLOURS.DESELECTEDCOLOR,
						padding: 5,
						paddingHorizontal: 0,
						flexDirection: "row",
						marginBottom: 20,
						flexWrap: "wrap", // Ensure the items wrap if they don't fit in a single row
					}}>
					{ethnicities.map((ethnicity) => (
						<TouchableOpacity
							key={ethnicity.name} // Use the name as a key for each item
							style={{
								flex: 1,
								margin: 2,
								justifyContent: "center",
								alignItems: "center",
								//   borderBottomWidth: 0.5, // Adjusted for a border
								//   borderBottomColor:
								//     this.state.selectedEthnicity === ethnicity.name
								//       ? APP_COLOURS.BLACK
								//       : 'transparent',
							}}
							onPress={() => {
								this.setState({
									selectedEthnicity: ethnicity.name, // Set the selected ethnicity
								});
								this.props.selectedEthnicity(ethnicity.name);
							}}>
							<View
								style={{
									padding: 10,
									width: "100%",
									borderRadius: 10,
									paddingVertical: 30,
									marginHorizontal: 2,
									borderWidth: 0.3,
									borderColor: APP_COLOURS.PLACEHOLDER,
									backgroundColor:
										this.state.selectedEthnicity ===
										ethnicity.name
											? ethnicity.color
											: "#FFF", // Use the ethnicity's color if selected
									justifyContent: "center",
									alignItems: "center",
									// Show border if selected
								}}></View>
							<Text
								adjustsFontSizeToFit
								numberOfLines={1}
								style={{
									color: "#FFF",
									fontWeight:
										this.state.selectedEthnicity ===
										ethnicity.name
											? "600"
											: "300",
									fontSize: 15,
								}}>
								{ethnicity.name}
							</Text>
						</TouchableOpacity>
					))}
				</View>
			</View>
		);
	}
}
