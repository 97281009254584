import React, { Component, useEffect } from "react";
import {
	StyleSheet,
	ActivityIndicator,
	Text,
	TouchableOpacity,
	View,
	ScrollView,
} from "react-native";
import { useParams } from "react-router-dom";
// import AutoHeightImage from "../AutoHeightImageComp";
import { APP_COLOURS } from "../../APP_VARS";
import CustomerSearch from "./CustomerSearch";

const device = {
	width: window.innerWidth,
	height: window.innerHeight,
};

class NewSale extends Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundcolour: APP_COLOURS.BG2,
			fontcolour: APP_COLOURS.DESELECTEDCOLOR,
			data: [],
			loading: true,
			brandcolour: APP_COLOURS.BG2,
			app_properties: "",
		};
	}

	componentDidMount() {}

	render() {
		return (
			<View
				style={{
					flex: 1,
					backgroundColor: APP_COLOURS.BG,
					width: window.innerWidth,
					height: window.innerHeight,
					alignItems: "center",
				}}>
				<View
					style={{
						// flex: 1,
						padding: 10,
						paddingTop: 0,
						maxWidth: 550,
						width: "100%",
						backgroundColor: APP_COLOURS.BG,
						height: window.innerHeight,
					}}>
					<TouchableOpacity
						onPress={() => {
							this.setState({
								newExistingCustomer: false,
							});
							// Actions.NewInteraction({
							// 	...this.props,

							// 	customerData: this.state.customerData,

							// 	isnewCustomer: true,
							// });
							window.location.href = `/NewCustomer/${this.props.user_id}/${this.props.token}`;
						}}>
						<View
							style={{
								// justifyContent: "space-between",
								justifyContent: "center",
								padding: 15,
								flexDirection: "row",
								alignItems: "center",

								// backgroundColor: "#000",
								backgroundColor: APP_COLOURS.BG3,
								// backgroundColor:
								// 	this.state.app_properties.cardColor,
								// backgroundColor: APP_COLOURS.GREEN,
								borderRadius: 15,
								paddingLeft: 20,
								marginTop: 20,
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontWeight: "600",
									fontSize: 17,
									// color: APP_COLOURS.TEXTCOLOR,
									// color: this.state.app_properties
									// 	.cardFont,
									color: "#FFF",
									fontWeight: "700",
								}}>
								New Customer
							</Text>
						</View>
					</TouchableOpacity>

					<Text
						allowFontScaling={false}
						style={{
							fontWeight: "300",
							fontSize: 19,
							width: "100%",
							textAlign: "center",
							color: APP_COLOURS.TEXTCOLOR,
							marginTop: 40,
							marginBottom: 40,
						}}>
						or
					</Text>
					<View style={{}}>
						<CustomerSearch
							{...this.props}
							{...this.state}
							selectedCustomer={async (d) => {
								console.log(d);

								await this.setState({
									newExistingCustomer: false,
								});

								window.location.href = `/ExistingCustomer/${this.props.params.user_id}/${d.id}/${this.props.params.token}`;

								// await Actions.NewInteraction({
								// 	...this.props,
								// 	customerData: d,

								// 	isnewCustomer: false,
								// });
								// this.props.navigation("NewCustomer");
							}}
						/>
					</View>
				</View>
			</View>
		);
	}
}

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		const params = useParams();
		const { user_id, token } = params;
		useEffect(() => {
			// Function to fetch user data using a Bearer token
			const fetchUserData = async (token) => {
				try {
					const response = await fetch(
						`https://workflow.tetrice.co.za/webhook/79d646ab-dd9b-4410-a905-62b6694f65c8`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`, // Set Bearer token in the Authorization header
							},
							body: JSON.stringify({
								user_id: user_id,
							}),
						},
					);

					if (!response.ok) {
						throw new Error("Failed to fetch user data");
					}

					const userData = await response.json();

					// Set user data in localStorage
					localStorage.setItem("user_data", JSON.stringify(userData));
				} catch (error) {
					console.error("Error fetching user data:", error);
				}
			};

			// If user_id and token are present, set them in localStorage and fetch user data
			if (user_id && token) {
				localStorage.setItem("user_id", user_id);
				localStorage.setItem("accessToken", token);

				// Fetch user data using the Bearer token
				fetchUserData(token);
			}
		}, [params]);

		return (
			<Component
				{...props}
				params={params}
				user_id={user_id}
				token={token}
			/>
		);
	}

	return ComponentWithRouterProp;
}
export default withRouter(NewSale);
