import React, { Component } from "react";
import {
	StyleSheet,
	Keyboard,
	Text,
	Image,
	TouchableOpacity,
	Dimensions,
	SafeAreaView,
	ScrollView,
	Modal,
	View,
	ActivityIndicator,
} from "react-native";
import { useNavigate } from "react-router-dom";
import AutoHeightImage from "./AutoHeightImageComp";
import logo from "../../assets/images/traq_app_logo.svg";
import { APP_COLOURS, Avatars } from "../APP_VARS";
import { api_heartBeat } from "../Api";
import moment from "moment";
import HomePasscode from "./HomePasscode";
import Attendance from "./Attandance/Attendance";

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			heartbeatDuration: 3600000,
			accessToken: "",
			passcodeModal: false,
			showAttendance: false,
			app_properties: "",
			user_data: "",
		};

		this._timeout = 3600000;
	}

	componentDidMount() {
		this.getDeviceData();
	}

	getDeviceData = async () => {
		try {
			const value = await sessionStorage.getItem("accessToken");
			if (value !== null) {
				await this.setState({
					accessToken: value,
				});

				console.log(value);
				this.heartBeat(value);
			}
		} catch (error) {
			this.props.navigate("/");
		}
		try {
			const value = await sessionStorage.getItem("app_properties");
			if (value !== null) {
				var json = JSON.parse(value);
				this.setState({
					app_properties: json,
					backgroundcolour: json.backgroundcolour,
				});
				console.log(json);
			}
		} catch (error) {
			// Error retrieving data
		}

		try {
			const value = await sessionStorage.getItem("users");
			if (value !== null) {
				var json = JSON.parse(value);
				this.setState({
					users: json,
				});
				// console.log(json);
			}
		} catch (error) {
			// Error retrieving data
		}
	};

	async deregisterApp() {
		try {
			// await AsyncStorage.clear().then(() => {Actions.replace("auth"),console.log('Cleared')})
			const keys = await sessionStorage.clear();
			this.props.navigate("/login");
			await clearTimeout(this._timeout);
		} catch (error) {
			console.error(error);
		}
	}

	heartBeat = async (value) => {
		this.setState({
			loading: true,
		});
		console.log("Sending heartbeat...");
		clearTimeout(this._timeout);
		let url = api_heartBeat;
		let now = moment().toISOString();

		let obj = {
			now: now,
			connectionDetails: "",
			device_data: "",
			last_seen: now,
		};
		//JSON.stringify(DeviceInfo.getCarrier())
		// JSON.stringify(this.state.deviceDATA)

		// console.log(obj);
		// return;
		var requestOptions = {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${value}`,
			},
			redirect: "follow",
			body: JSON.stringify(obj),
		};
		// console.log("heartbeat response =====", value);
		// return;
		await fetch(url, requestOptions)
			.then(async (response) => {
				// console.log("wesley is undatable=", response.status);
				// return;
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log("=RETURN=", responseData);

							this.setState({
								users: responseData.users,
								isInternet: true,
								loading: false,
							});

							await this.onValueChange(
								"users",
								JSON.stringify(responseData.users),
							);
						})
						.catch((error) => {
							console.error(error);
							this.setState({
								isInternet: false,
								loading: false,
							});
							// Alert.alert('Error updating');
						});
				} else {
					// this.deregisterApp();
				}
			})
			.catch((error) => {
				// alert('Error updating');
				this.setState({
					isInternet: false,
					loading: false,
				});
			});

		await this.clearandStartInactivityTimer();
	};

	async onValueChange(item, selectedValue) {
		try {
			await sessionStorage.setItem(item, selectedValue);
		} catch (error) {
			console.error(error);
		}
	}

	clearandStartInactivityTimer = () => {
		clearTimeout(this._timeout);
		this._timeout = setTimeout(() => {
			console.log("Send heartbeat");

			console.log(
				"clearandStartInactivityTimer => IsConnected => heartBeat",
			);
			this.heartBeat();
			this.setState({
				isInternet: true,
			});
		}, this.state.heartbeatDuration);
	};

	passcodeAccept = () => {
		// alert("passcode accepted");

		// this.props.navigate("/Attendance");

		this.setState({
			showAttendance: true,
			passcodeModal: false,
		});
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					backgroundColor: this.state.app_properties.backgroundcolour,
					height: window.innerHeight,
				}}>
				<View
					style={{
						width: window.innerWidth,
						marginTop: 10,
						justifyContent: "center",
						alignItems: "center",
						marginBottom: 30,
					}}>
					<AutoHeightImage
						width={150}
						source={
							require("../../assets/images/traq_app_logo.svg")
								.default
						}
					/>
				</View>

				<View style={{ flex: 1 }}>
					{this.state.users.length === 0 ? (
						<View
							style={{
								opacity: 0.8,

								justifyContent: "center",
								alignItems: "center",
								height: window.innerHeight - 350,
								width: window.innerWidth,
								zIndex: 99,
							}}>
							<AutoHeightImage
								source={require("../../assets/images/BlackMan1.png")}
								width={
									window.innerWidth < 700
										? window.innerWidth * 0.6
										: 600
								}
							/>
							<View
								style={{
									width: window.innerWidth * 0.7,
									justifyContent: "center",
									alignItems: "center",
									maxWidth: 400,
									marginTop: 20,
								}}>
								<Text
									style={{
										fontFamily: "Avenir",

										fontSize: 19,
										fontWeight: "300",
										color: "#797979",
									}}>
									there is no{" "}
									<Text
										style={{
											fontWeight: "700",
											color: "#528DFF",
										}}>
										staff members
									</Text>{" "}
									allocated to this device
								</Text>
							</View>
							{this.state.loading ? (
								<View
									style={{
										marginTop: 40,
										borderRadius: 20,
										padding: 7,
										backgroundColor: "#528DFF",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<ActivityIndicator
										color={"#FFF"}
										size='small'
									/>
								</View>
							) : (
								<TouchableOpacity
									onPress={() => this.getDeviceData()}>
									<View
										style={{
											// width: '100%',
											marginTop: 40,
											borderRadius: 10,
											padding: 4,
											backgroundColor: "#528DFF",
											paddingLeft: 25,
											paddingRight: 25,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												fontWeight: "400",
												color: "#fff",
												fontSize: 15,
											}}>
											reload
										</Text>
									</View>
								</TouchableOpacity>
							)}
						</View>
					) : (
						this.state.users.map((item, i) => (
							<View
								key={i}
								style={{
									width: "100%",
									marginTop: 10,

									padding: 10,
								}}>
								<TouchableOpacity
									onPress={() => {
										console.log(this.state.app_properties);

										this.onValueChange(
											"user_data",
											JSON.stringify(item),
										);
										this.setState({
											passcodeModal: true,
											passcode_id: item.passcode,
											user_id: item.id,
											user_data: item,
										});
									}}
									style={{
										flexDirection: "row",
										alignItems: "center",
										backgroundColor:
											this.state.app_properties.cardColor,
										borderRadius: 12,
										padding: 10,
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 7,
										},
										shadowOpacity: 0.13,
										shadowRadius: 2.62,

										elevation: 4,
									}}>
									<View
										style={{
											justifyContent: "center",
											alignItems: "center",
										}}>
										<View
											style={{
												width: 60,
												height: 60,
												borderWidth: 2,
												borderColor:
													item.status === "Active"
														? APP_COLOURS.GREEN
														: APP_COLOURS.ORANGE,
												borderRadius: 30,
												backgroundColor:
													this.state.app_properties
														.backgroundcolour,
												opacity: 0.3,
											}}
										/>

										<Image
											source={Avatars[item.avatar].pp}
											style={{
												resizeMode: "cover",
												width: 60,
												height: 60,
												borderRadius: 30,
												position: "absolute",
												top: 0,
												zIndex: 99,
												borderWidth: 3,
												borderColor: APP_COLOURS.ORANGE,
											}}
										/>
									</View>
									<View
										style={{
											// width: 250,
											// flex: 1,
											flexDirection: "column",
											maxWidth: 375,
											// height: 70,
											borderRadius: 15,
											justifyContent: "center",
											paddingLeft: 10,
										}}>
										<Text
											style={{
												fontSize: 22,
												fontWeight: "700",
												// color: this.state.fontcolour,
												// color: this.state.app_properties.brandcolour,
												// color: '#171D25',
												color: this.state.app_properties
													.cardFont,
											}}>
											{item.first_name} {item.last_name}{" "}
										</Text>
										<Text
											style={{
												fontSize: 13,
												fontWeight: "300",
												color: this.state.app_properties
													.cardFont,
												opacity: 0.6,
											}}>
											{item.position} ({item.passcode})
										</Text>
										<Text
											style={{
												fontSize: 13,
												fontWeight: "300",
												color: this.state.app_properties
													.cardFont,
												opacity: 0.6,
											}}>
											{item.retailer.title} -{" "}
											{item.mall.title}
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						))
					)}
				</View>

				<View
					style={{
						width: "100%",
						alignItems: "center",
					}}>
					<Text
						style={{
							color: "white",
							position: "absolute",
							bottom: 18,
							fontSize: 13,
							fontStyle: "normal",
							fontWeight: "bold",
							textAlign: "center",
						}}>
						© {moment().format("YYYY")} tetrice (pty) ltd
					</Text>
					<TouchableOpacity
						onPress={() => this.deregisterApp()}
						style={{
							bottom: 40,
							position: "absolute",
						}}>
						<Text
							style={{
								color: "white",
								fontSize: 10,
								marginBottom: 5,
							}}>
							Deregister Device
						</Text>
					</TouchableOpacity>
				</View>
				{this.state.passcodeModal ? (
					<Modal
						animationType={"fade"}
						transparent={true}
						visible={this.state.passcodeModal}
						onRequestClose={() => {
							this.setState({ passcodeModal: false });
						}}>
						<View
							style={{
								// position: 'absolute',
								width: "100%",
								height: "100%",
								// justifyContent: 'center',
								// alignItems: 'center',
								backgroundColor:
									this.state.app_properties.brandcolour,

								paddingTop: 50,
							}}>
							<TouchableOpacity
								onPress={() => {
									this.setState({ passcodeModal: false });
								}}>
								<View
									style={{
										width: "100%",
										alignItems: "flex-start",
										flexDirection: "row",
										paddingLeft: 15,
									}}>
									<View
										style={{
											width: 38,
											height: 38,
											justifyContent: "center",
											alignItems: "center",
											borderRadius: 17,
											paddingTop: 8,
											backgroundColor: APP_COLOURS.WHITE,
											// shadowColor:
											// 	APP_COLOURS.TRANSPARENT_BLACK,
											// shadowOffset: {
											// 	width: 0,
											// 	height: 2,
											// },
											// shadowOpacity: 0.3,
											// shadowRadius: 4.65,
											// elevation: 8,
										}}>
										<Image
											source={require("../../assets/images/left_btn.png")}
											style={{
												width: 40,
												height: 30,
												resizeMode: "contain",
											}}
										/>
									</View>
								</View>
							</TouchableOpacity>

							<HomePasscode
								passcode_id={this.state.passcode_id}
								user_access_token={this.state.user_access_token}
								user_id={this.state.user_id}
								app_properties={this.state.app_properties}
								onPasscodeAccept={() => this.passcodeAccept()}
								logo={this.state.app_properties.logo}
								jwt={sessionStorage.getItem("accessToken")}
							/>
						</View>
					</Modal>
				) : null}

				{this.state.showAttendance ? (
					<Modal
						animationType={"fade"}
						transparent={true}
						visible={this.state.showAttendance}
						onRequestClose={() => {
							this.setState({ showAttendance: false });
						}}>
						<View
							style={{
								width: "100%",
								height: window.innerHeight,
								bottom: 0,
								position: "absolute",
								backgroundColor:
									this.state.app_properties.backgroundcolour,

								// paddingTop: 20,
							}}>
							<TouchableOpacity
								onPress={() => {
									this.setState({ showAttendance: false });
								}}>
								<View
									style={{
										width: "100%",
										alignItems: "flex-start",
										flexDirection: "row",
										paddingLeft: 15,
										position: "absolute",
										top: -70,
									}}>
									<View
										style={{
											width: 38,
											height: 38,
											justifyContent: "center",
											alignItems: "center",
											borderRadius: 17,
											paddingTop: 8,
											// backgroundColor: APP_COLOURS.WHITE,
										}}>
										<Image
											source={require("../../assets/images/left_btn.png")}
											style={{
												width: 40,
												height: 30,
												resizeMode: "contain",
											}}
										/>
									</View>
								</View>
							</TouchableOpacity>

							<View style={{ flex: 1 }}>
								<Attendance {...this.state} {...this.props} />
							</View>
						</View>
					</Modal>
				) : null}
			</View>
		);
	}
}

export const withNavigation = (Component: Component) => {
	return (props) => <Component {...props} navigate={useNavigate()} />;
};

export default withNavigation(Home);
