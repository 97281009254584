import React, { Component } from "react";
import { View, Text } from "react-native";
import { QrReader } from "react-qr-reader";
import moment from "moment";
// import approve_sound from "./audio/approve_sound.mp3";

function play() {
	var audio = new Audio("https://tetrice.co.za/audio/approve_sound.mp3");
	audio.play();
}

function stopStreamedVideo() {
	let tracks = window.localstream.getTracks();
	let video = document.querySelector("#your-video-tag-id");
	tracks.forEach(function (track) {
		if (track.kind === "video") {
			if (track.enabled) {
				track.stop();
				track.enabled = false;
			}
		}
	});
	video.srcObject = null;
}

class QRCodeScanner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openScanner: false,
		};
		this.videoTag = React.createRef();
		this.myStream = null;
	}

	componentDidMount() {
		setTimeout(
			() =>
				this.setState({
					openScanner: true,
				}),
			500,
		);
	}

	processQR = async (result) => {
		// await stopStreamedVideo();
		console.log(result);
		await play();
		await this.setState({
			openScanner: false,
		});

		setTimeout(() => {
			this.props.processQR(result);
		}, 500);
	};

	render() {
		try {
			return (
				<View
					style={{
						backgroundColor: "#FFF",
						width: this.props.screenWidth - 20,
						maxWidth: 350,
						minHeight: 300,
					}}>
					{this.state.openScanner ? (
						<QrReader
							className='camera'
							onResult={async (result, error) => {
								this.processQrScan(result, error);
							}}
							onError={(e) => {}}
							// onResult={(result, e) => {
							// 	if (!!result) {
							// 		console.log(result?.text);
							// 	}

							// 	if (!!e) {
							// 		console.info(e);
							// 	}
							// }}
							delay={300}
							// constraints={{
							// 	facingMode: "environment",
							// }}

							style={{ width: 200, height: 200 }}
						/>
					) : (
						<View />
					)}

					<View
						style={{
							width: "100%",
							height: 1,
							backgroundColor: "red",
							position: "absolute",
							zIndex: 999,
							top: "50%",
						}}
					/>
				</View>
			);
		} catch (error) {
			return <View />;
		}
	}

	processQrScan = async (result, error) => {
		if (typeof result !== "undefined" && result !== null) {
			console.log("result", result);
			// this.processQR(`${result}`);
			console.log(
				result?.text?.includes("https://traq.app?deviceregistration="),
			);
			if (
				result?.text?.includes("https://traq.app?deviceregistration=")
			) {
				var str = result.text;
				var res = str.replace(
					"https://traq.app?deviceregistration=",
					"",
				);

				// stopStreamedVideo();

				if (this.state.openScanner) {
					await this.processQR(`${res}`);
				}
			}
		}

		if (error) {
			console.log(error);
		}
	};
}

export default QRCodeScanner;
