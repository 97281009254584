import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	TouchableOpacity,
	ScrollView,
	Image,
} from "react-native";
import AsyncStorage from "../../AsyncStorage";
import AutoHeightImage from "../AutoHeightImageComp";
// import { Actions } from "react-native-router-flux";
// import logo from "../../../assets/images/traq_app.png";
import {
	APP_COLOURS,
	Avatars,
	screenWidth,
	screenHeight,
} from "../../APP_VARS";
import moment from "moment";
import ProgressBar from "./ProgressBar";
import { crudUpdateProfile } from "../../CRUDHELPER";

export default class Overview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_data: "",

			avatar: 0,
		};
	}

	componentDidMount() {
		this.getDeviceData();
	}

	getDeviceData = async () => {
		try {
			const value = await AsyncStorage.getItem("user_data");
			if (value !== null) {
				let parse = JSON.parse(value);
				this.setState({
					user_data: parse,
					avatar: parse.avatar,
				});
			}
		} catch (error) {
			// Error retrieving data
		}
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					padding: 10,
					backgroundColor: this.props.brandcolour,
				}}>
				{this.profileCard()}
				<ScrollView showsVerticalScrollIndicator={false}>
					<View
						style={{
							width: "100%",
							marginTop: 20,
						}}>
						<Text
							style={{
								fontSize: 16,
								fontWeight: "700",
								color: "#FFF",
								// color: this.props.app_properties?.cardFont,
								opacity: 0.7,
							}}>
							Skills
						</Text>
						{[
							{
								title: "Brand knowledge",
								category: "BK",
								percentageAchieved: "70%",
							},
							{
								title: "Product knowledge",
								category: "PK",
								percentageAchieved: "62%",
							},
							{
								title: "Sales knowledge",
								category: "SK",
								percentageAchieved: "62%",
							},
							{
								title: "Customer & Service knowledge",
								category: "CK",
								percentageAchieved: "62%",
							},
						].map((d, i) => (
							<View
								key={`k_${i}`}
								style={{
									flexDirection: "row",
									padding: 5,
									alignItems: "center",
									marginBottom: 10,
								}}>
								<View
									style={{
										width: 40,
										height: 40,
										borderRadius: 15,
										borderWidth: 2,
										borderColor:
											APP_COLOURS.WHITE_TRANSPARENT,
										backgroundColor:
											APP_COLOURS.PURPLE_TRANS,
										marginRight: 17,
										alignItems: "center",
										justifyContent: "center",
									}}>
									<Text
										style={{
											fontSize: 16,
											fontWeight: "700",
											color: APP_COLOURS.PURPLE,

											color: this.props.fontcolour,
											position: "absolute",
											left: 20,
										}}>
										{d.category}
									</Text>
								</View>
								<View
									style={{
										flex: 1,
										paddingRight: 10,
									}}>
									<Text
										style={{
											fontSize: 12,
											fontWeight: "400",
											color: APP_COLOURS.BLUE2,
											color: this.props.fontcolour,
											marginBottom: 10,
										}}>
										<Text
											style={{
												fontWeight: "600",
											}}>
											{d.percentageAchieved}
										</Text>{" "}
										in {d.title} achieved
									</Text>
									<ProgressBar
										progress={d.percentageAchieved}
									/>
								</View>
							</View>
						))}
					</View>
					<View
						style={{
							marginTop: 20,
						}}>
						<Text
							style={{
								fontSize: 16,
								fontWeight: "700",
								color: APP_COLOURS.BLUE2,
								color: this.props.fontcolour,
								opacity: 0.7,
							}}>
							Acheivements
						</Text>

						<View
							style={{
								marginTop: 20,
							}}>
							<View
								style={{
									flexDirection: "row",
									padding: 5,
									alignItems: "center",
									marginBottom: 10,
								}}>
								<View
									style={{
										width: 40,
										height: 40,
										borderRadius: 15,
										// borderWidth: 2,
										// borderColor: '#FFF',
										// backgroundColor: APP_COLOURS.BACKGROUND_FADE,
										marginRight: 17,
										// alignItems: 'center',
										// justifyContent: 'center',
									}}>
									<AutoHeightImage
										width={35}
										source={require("../../../assets/images/sales.png")}
									/>
								</View>
								<View
									style={{
										// flex: 1,
										width: "100%",
										padding: 5,
										paddingRight: 10,
										borderRadius: 5,
										backgroundColor:
											APP_COLOURS.WHITE_TRANSPARENT5,
									}}>
									<Text
										style={{
											fontSize: 16,
											fontWeight: "300",
											color: this.props.fontcolour,
											opacity: 0.9,
										}}>
										<Text
											style={{
												fontWeight: "600",
											}}>
											110%
										</Text>{" "}
										Sales target 1 - Jan 2024
									</Text>
								</View>
							</View>
						</View>

						{/* {[
							{
								title: "Lessons completed",
								count: "25",
								icon: require("../../../assets/images/certificate.png"),
							},
							{
								title: "achievements earned",
								count: "8",
								icon: require("../../../assets/images/medal.png"),
							},
							{
								title: "questionaires & surveys completed",
								count: "12",
								icon: require("../../../assets/images/qanda.png"),
							},
						].map((d, i) => (
							<View
								key={`b_${i}`}
								style={{
									flexDirection: "row",
									padding: 5,
									alignItems: "center",
									marginBottom: 10,
								}}>
								<View
									style={{
										width: 40,
										height: 40,
										borderRadius: 15,
										borderWidth: 2,
										borderColor: "#FFF",
										backgroundColor:
											APP_COLOURS.BACKGROUND_FADE,
										marginRight: 17,
										alignItems: "center",
										justifyContent: "center",
									}}>
									<AutoHeightImage
										width={25}
										source={d.icon}
									/>
								</View>
								<View
									style={{
										flex: 1,
										paddingRight: 10,
									}}>
									<Text
										style={{
											fontSize: 13,
											fontWeight: "400",
											color: this.props.fontcolour,
										}}>
										<Text
											style={{
												fontWeight: "600",
											}}>
											{d.count}
										</Text>{" "}
										{d.title}
									</Text>
								</View>
							</View>
						))} */}
					</View>
					<View
						style={{
							height: 50,
						}}
					/>
				</ScrollView>

				<View
					style={{
						flex: 1,
						position: "absolute",
						zIndex: 999,
						backgroundColor: "rgba(52, 52, 52, 0.8)",
						// backgroundColor: this.props.app_properties?.backgroundcolour,
						width: screenWidth,
						height: screenHeight - -150,
						display: this.state.chooseProfilePic ? "flex" : "none",
					}}>
					<View
						style={{
							width: screenWidth,
							height: screenHeight - 150,
							position: "absolute",
							justifyContent: "center",
							alignItems: "center",
							zIndex: 99,
						}}>
						<View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
								// backgroundColor: 'rgba(52, 52, 52, 0.8)',
							}}>
							<View
								style={{
									width: "90%",
									height: "auto",
									// backgroundColor: 'white',
									backgroundColor:
										this.props.app_properties
											?.backgroundcolour,
									borderRadius: 20,
									justifyContent: "center",

									alignItems: "center",
									shadowColor: "#999797",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.3,
									shadowRadius: 4.65,
									elevation: 8,
									paddingBottom: 20,
								}}>
								<View
									style={{
										width: "100%",
										padding: 20,
										height: "auto",
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 19,
											fontFamily: "Avenir Next",
											textAlign: "center",
											fontWeight: "700",
											color: this.props.fontcolour,
										}}>
										Choose an Avatar
									</Text>
								</View>
								<ScrollView>
									<View
										style={{
											padding: 10,
											width: "100%",
											flexDirection: "row",
											flexWrap: "wrap",
										}}>
										{Avatars.map((d, i) => (
											<View key={`wk_${i}`}>
												<TouchableOpacity
													onPress={() => {
														let obj = {
															avatar: i,
														};
														// console.log(obj);
														// return;
														crudUpdateProfile(
															obj,
															() => {
																this.setState({
																	chooseProfilePic: false,
																	avatar: i,
																	changesMade: true,
																	updates: {
																		...this
																			.state
																			.updates,
																		avatar: d.avatar,
																	},
																});
															},
														);
													}}>
													<View
														style={{
															width: 55,
															height: 55,
															borderRadius: 27,
															borderWidth: 5,
															justifyContent:
																"center",
															alignItems:
																"center",
															borderColor: "#FFF",
															overflow: "hidden",
														}}>
														<AutoHeightImage
															width={45}
															source={d.pp}
															style={{
																resizeMode:
																	"cover",
															}}
															loadingIndicatorSource={
																<ActivityIndicator
																	color={
																		APP_COLOURS.PRIMARY
																	}
																/>
															}
														/>
													</View>
													<View
														style={{
															width: 55,
															height: 55,
															borderRadius: 27,
															borderWidth: 5,
															borderColor: "#FFF",
															backgroundColor:
																"transparent",
															position:
																"absolute",
															top: 0,
															left: 0,
															shadowColor:
																"#999797",
															shadowOffset: {
																width: 0,
																height: 4,
															},
															shadowOpacity: 0.3,
															shadowRadius: 4.65,
															elevation: 8,
														}}
													/>
												</TouchableOpacity>
											</View>
										))}
									</View>
								</ScrollView>
							</View>
						</View>
					</View>
				</View>
			</View>
		);
	}

	profileCard = () => {
		let item = this.state.user_data;
		console.log("item", item);
		return (
			<View
				style={{
					width: "100%",
					marginTop: 10,

					padding: 10,
				}}>
				<TouchableOpacity
					onPress={() => {
						this.setState({
							chooseProfilePic: true,
						});
					}}
					style={{
						flexDirection: "row",
						alignItems: "center",
						// backgroundColor: this.props.app_properties.cardColor,
						borderRadius: 12,
						padding: 10,
						// shadowColor: "#000",
						// shadowOffset: {
						// 	width: 0,
						// 	height: 7,
						// },
						// shadowOpacity: 0.13,
						// shadowRadius: 2.62,

						// elevation: 4,
					}}>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								width: 60,
								height: 60,
								borderWidth: 2,
								borderColor:
									item.status === "Active"
										? APP_COLOURS.GREEN
										: APP_COLOURS.ORANGE,
								borderRadius: 30,
								backgroundColor:
									this.props.app_properties?.backgroundcolour,
								opacity: 0.3,
							}}
						/>

						<Image
							source={Avatars[this.state.avatar].pp}
							style={{
								resizeMode: "cover",
								width: 60,
								height: 60,
								borderRadius: 30,
								position: "absolute",
								top: 0,
								zIndex: 99,
								borderWidth: 3,
								borderColor: APP_COLOURS.ORANGE,
							}}
						/>
					</View>
					<View
						style={{
							// width: 250,
							// flex: 1,
							flexDirection: "column",
							maxWidth: 375,
							// height: 70,
							borderRadius: 15,
							justifyContent: "center",
							paddingLeft: 10,
						}}>
						<Text
							style={{
								fontSize: 22,
								fontWeight: "700",
								color: this.props.fontcolour,
							}}>
							{item.first_name} {item.last_name}{" "}
						</Text>
						<Text
							style={{
								fontSize: 13,
								fontWeight: "300",
								color: this.props.fontcolour,
								opacity: 0.6,
							}}>
							{item.position} ({item.passcode})
						</Text>
						<Text
							style={{
								fontSize: 13,
								fontWeight: "300",
								color: this.props.fontcolour,
								opacity: 0.6,
							}}>
							{/* {item.retailer} - {item.mall} */}
						</Text>
					</View>
				</TouchableOpacity>
			</View>
		);
	};
}
