import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	StyleSheet,
	TouchableOpacity,
	TextInput,
} from "react-native";
import {
	crudGetCustomerNote,
	crudUpdate,
	crudUpdateCustomerNote,
} from "../../../CRUDHELPER";
import { APP_COLOURS } from "../../../APP_VARS";
import moment from "moment";

export default class Notes extends Component {
	constructor(props) {
		super(props);
		// Dummy data for the notes
		this.state = {
			defNotes: [
				{
					id: 1,
					content: "Followed up on previous purchase.",
					date: "2024-02-10",
					author: "John Doe",
				},
				{
					id: 2,
					content: "Customer reported issue resolved.",
					date: "2024-02-11",
					author: "Jane Smith",
				},
				{
					id: 3,
					content: "Scheduled a call for feedback.",
					date: "2024-02-12",
					author: "Emily Johnson",
				},
			],
			notes: [],
			newNote: "",
		};
	}

	componentDidMount() {
		this.getCustomerNotes();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.customerData !== this.props.customerData) {
			this.getCustomerNotes();
		}
	}

	getCustomerNotes = () => {
		let customerData = this.props.customerData;
		if (typeof customerData !== "undefined") {
			let obj = {
				customer_id: this.props.customerData._id,
			};
			crudGetCustomerNote(obj, (s, d) => {
				this.setState({
					notes: d.sort(
						(a, b) => new Date(b.date) - new Date(a.date),
					),
					loading: false,
				});
			});
		}
	};

	updateCustomerData = () => {
		this.setState({
			loading: false,
		});
		// let obj = this.props.customerData;
		// obj.notes = this.state.notes;

		let note = {
			text: this.state.newNote,
			date: moment().toISOString(),
			customer_id: this.props.customerData._id,
		};
		crudUpdateCustomerNote(note, (notes) => {
			this.getCustomerNotes();
		});
	};

	renderNotes() {
		return this.state.notes.map((note) => (
			<View
				key={note.id}
				style={{
					padding: 10,
					backgroundColor: APP_COLOURS.DESELECTEDCOLOR,
					borderRadius: 10,
					marginBottom: 4,
				}}>
				<Text
					style={{
						fontSize: 16,
						color: APP_COLOURS.BG,
						marginVertical: 10,
					}}>
					{note.text}
				</Text>
				<Text
					style={{
						fontSize: 9,
						color: APP_COLOURS.BG4,
					}}>
					{moment(note.date).fromNow()} - {note.author}
				</Text>
			</View>
		));
	}

	render() {
		return (
			<View style={styles.container}>
				<TouchableOpacity onPress={() => this.getCustomerNotes()}>
					<Text style={styles.title}>Notes</Text>
				</TouchableOpacity>
				<View
					style={{
						paddingLeft: 10,
						flex: 1,
					}}>
					<ScrollView>{this.renderNotes()}</ScrollView>
				</View>
				<View
					style={{
						width: "100%",
						borderRadius: 13,
					}}>
					<TextInput
						allowFontScaling={false}
						style={{
							padding: 15,
							fontSize: 18,
							fontWeight: "300",
							color: "#FFF",
							// backgroundColor: APP_COLOURS.BG,
							backgroundColor: APP_COLOURS.BG3,
							borderRadius: 15,
							textAlign: "left",
							borderColor:
								this.state.newNote !== ""
									? APP_COLOURS.BLUE
									: APP_COLOURS.BG3,
							borderWidth: 1,
						}}
						placeholder='Type note'
						maxLength={200}
						numberOfLines={5}
						multiline
						clearButtonMode='always'
						placeholderTextColor='#FFF'
						onFocus={async () => {}}
						onBlur={async () => {}}
						onChangeText={(text) => {
							this.setState({
								newNote: text,
							});
						}}
					/>
				</View>
				<TouchableOpacity
					disabled={this.state.newNote === ""}
					style={{
						width: "100%",
						marginTop: 10,
						opacity: this.state.newNote !== "" ? 1 : 0.3,
					}}
					onPress={() => {
						if (this.state.newNote !== "") {
							this.setState({
								showTandCs: true,
							});
							this.updateCustomerData();
						}
					}}>
					<View
						style={{
							width: "100%",
							padding: 15,
							borderRadius: 10,
							backgroundColor: APP_COLOURS.BLUE,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Text style={{ color: "#FFF", fontWeight: "300" }}>
							Add note
						</Text>
					</View>
				</TouchableOpacity>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 10,
		paddingBottom: 30,
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
		marginBottom: 10,
		color: "#FFF",
	},
	noteContainer: {
		backgroundColor: "#f0f0f0",
		padding: 10,
		borderRadius: 5,
		marginBottom: 10,
	},
	noteContent: {
		fontSize: 16,
	},
	noteMeta: {
		fontSize: 14,
		color: "#666",
		marginTop: 5,
	},
});
