import React, { Component } from "react";
import { Image } from "react-native";

export default class AutoHeightImage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		if (this.props.source !== "") {
			return (
				<Image
					style={{
						width: this.props.width,
						height:
							typeof this.props.height !== "undefined"
								? this.props.height
								: this.props.width,
						resizeMode: "contain",
						...this.props.style,
					}}
					source={this.props.source}
				/>
			);
		} else {
			return (
				<img
					style={{
						width: this.props.width,
						resizeMode: "contain",
						...this.props.style,
					}}
					src={this.props.src}
					alt='Image'
					className='responsive'
				/>
			);
		}
	}
}
